import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { addData, addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast";

import img1 from "../assets/images/icons/choice.png";
import img2 from "../assets/images/icons/customer-services.png";
import img3 from "../assets/images/icons/piggy-bank.png";
import img4 from "../assets/images/icons/credit-card.png";
import backgroundimg from "../assets/images/agents/backgroundimg.jpg";
import img5 from "../assets/images/common/loan.jpg";
import addimg from "../assets/images/banners/9.png";
import { Button, Modal, ModalBody } from 'reactstrap';
import successimg from '../assets/images/reviews/success.png'
import errorimg from '../assets/images/reviews/error.png'
import crossimg from '../assets/images/reviews/crossimg.jpg'
import ReactApexChart from 'react-apexcharts';
function Banks() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const [open1, setOpen1] = useState(false);
    const toggle1 = () => setOpen1(!open1);
    const [open2, setOpen2] = useState(false);
    const toggle2 = () => setOpen2(!open2);

    const [form, setform] = useState({});
    const [form1, setform1] = useState([]);

    const getOnedata = async () => {
        const bodydata = {
            id: sessionStorage.getItem("loanid"),
        };
        const resonse = await addData("allproduct/subnextbyid", bodydata);
        var _data = resonse;
        setform(_data.data.subProduct);
    };

    const getAllBanks = async () => {
        const bodydata = {
            productId: "64720173d0f054719b9b2880", //Loan
        };
        const resonse = await addDataadmin(
            "loanpartner/getloanpartnerbyproductid",
            bodydata
        );
        var _data = resonse;
        setform1(_data.data.loanPartner);
    };

    const [banner, setbanner] = useState([]);
    const [banner2, setbanner2] = useState([]);
    const [states, setstates] = useState([]);

    const getAllbanners = async () => {
        const resonse = await addData("banner/getallloan");
        var _data = resonse;
        setbanner(_data.data.horizontal[0]);
        setbanner2(_data.data.vertical[0]);
    };
    const [banner1, setbanner1] = useState([]);
    const getAllbanners1 = async () => {
        const resonse = await addDataadmin("pagebanners/getactive");
        var _data = resonse;
        setbanner1(_data.data.pageBanners[1]);
    };

    const getAllstates = async () => {
        const resonse = await addDataadmin("state/getall");
        var _data = resonse;
        setstates(_data.data.statesResult);
    };

    useEffect(() => {
        getOnedata();
        getAllstates();
        getAllBanks();
        getAllbanners();
        getAllbanners1();
        scrollToTop();
    }, []);

    const [principal, setPrincipal] = useState(0);
    console.log(principal)
    const [interestRate, setInterestRate] = useState(0);
    console.log(interestRate)
    const [tenure, setTenure] = useState(0);
    console.log(tenure)
    const [emi, setEmi] = useState(0);
    const [totalInterest, setTotalInterest] = useState(0);
    console.log(totalInterest)
    const [totalPayment, setTotalPayment] = useState(0);

    const principalChange = (e) => {
        setPrincipal(e.target.value);
        // calculateEmi();
        const amount = parseFloat(e.target.value);
        const yeass = parseFloat(tenure * 12);
        console.log(yeass)
        const terestRate = (parseFloat(interestRate) / 100) / yeass;
        const emivalue = amount * terestRate * (Math.pow(1 + terestRate, yeass) / (Math.pow(1 + terestRate, yeass) - 1))
        setEmi(emivalue)
        setTotalPayment(emivalue * yeass)
        setTotalInterest(parseFloat(emivalue * yeass) - parseFloat(e.target.value))
    };

    const interestRateChange = (e) => {
        setInterestRate(e.target.value);
        // calculateEmi();
        const amount = parseFloat(principal);
        const yeass = parseFloat(tenure * 12);
        console.log(yeass)
        const terestRate = (parseFloat(e.target.value) / 100) / yeass;

        const emivalue = amount * terestRate * (Math.pow(1 + terestRate, yeass) / (Math.pow(1 + terestRate, yeass) - 1))
        setEmi(emivalue)
        setTotalPayment(emivalue * yeass)
        setTotalInterest(parseFloat(emivalue * yeass) - parseFloat(principal))
    };

    const tenureChange = (e) => {
        setTenure(e.target.value);
        // calculateEmi();
        const amount = parseFloat(principal);
        const yeass = parseFloat(e.target.value * 12);
        console.log(yeass)
        const terestRate = (parseFloat(interestRate) / 100) / yeass;

        const emivalue = amount * terestRate * (Math.pow(1 + terestRate, yeass) / (Math.pow(1 + terestRate, yeass) - 1))
        setEmi(emivalue)
        setTotalPayment(emivalue * yeass)
        setTotalInterest(parseFloat(emivalue * yeass) - amount)
    };

    const [forms, setforms] = useState({
        emplyementStatus: "",
        name: "",
        mobile: "",
        email: "",
        loanPurpose: "",
        revenue: "",
        salaryReceivedAs: "",
        propertyLocation: "",
        city: "",
        pincode: "",
        dateOfBirth: "",
        companyName: "",
        dateOfBirth: "",
        salary: "",
        otp: "",
        avgMontlyIncome: "",
    });

    const [time, setTime] = useState(30);
    useEffect(() => {
        const updateTimer = setInterval(() => {
            setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => clearInterval(updateTimer);
    }, []);

    const [timeshow, settimeshow] = useState(false);
    const [resends, setresends] = useState(false);

    const handlechangephone = async (e) => {
        const inputMobile = e.target.value;
        const myForm = { ...forms };
        myForm[e.target.name] = inputMobile;
        setforms(myForm);
        const bodydata = {
            mobile: inputMobile,
        };
        if (inputMobile.length === 10) {
            try {
                const response = await addData("expressintrest/sentotp", bodydata);
                const data = response.data;
                toast.success(data.message);
                setresends(true);
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Somting Went Wrong Please Try Again");
                }
            }
        }
    };

    const resendotps = async () => {
        const bodydata = {
            mobile: forms.mobile,
        };

        try {
            const response = await addData("expressintrest/sentotp", bodydata);
            const data = response.data;
            toast.success(data.message);
            settimeshow(true);
            setTime(30);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Somting Went Wrong Please Try Again");
            }
        }
    };

    const handlechangeotp = async (e) => {
        const inputOtp = e.target.value;
        const myForm = { ...forms };
        myForm[e.target.name] = inputOtp;
        setforms(myForm);

        const bodydata = {
            mobile: forms.mobile,
            otp: inputOtp,
        };

        if (inputOtp.length === 6) {
            try {
                const response = await addData("expressintrest/compareotp", bodydata);
                const data = response.data;
                toast.success(data.message);
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Somting Went Wrong Please Try Again");
                }
            }
        }
    };

    const [timeshow1, settimeshow1] = useState(false);
    const [resends1, setresends1] = useState(false);

    const handlechangephone1 = async (e) => {
        const inputMobile = e.target.value;
        const myForm = { ...forms1 };
        myForm[e.target.name] = inputMobile;
        setforms1(myForm);
        const bodydata = {
            mobile: inputMobile,
        };
        if (inputMobile.length === 10) {
            try {
                const response = await addData("expressintrest/sentotp", bodydata);
                const data = response.data;
                toast.success(data.message);
                setresends1(true);
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Somting Went Wrong Please Try Again");
                }
            }
        }
    };

    const resendotps1 = async () => {
        const bodydata = {
            mobile: forms1.mobile,
        };

        try {
            const response = await addData("expressintrest/sentotp", bodydata);
            const data = response.data;
            toast.success(data.message);
            settimeshow1(true);
            setTime(30);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Somting Went Wrong Please Try Again");
            }
        }
    };

    const handlechangeotp1 = async (e) => {
        const inputOtp = e.target.value;
        const myForm = { ...forms1 };
        myForm[e.target.name] = inputOtp;
        setforms1(myForm);

        const bodydata = {
            mobile: forms1.mobile,
            otp: inputOtp,
        };

        if (inputOtp.length === 6) {
            try {
                const response = await addData("expressintrest/compareotp", bodydata);
                const data = response.data;
                toast.success(data.message);
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Somting Went Wrong Please Try Again");
                }
            }
        }
    };

    const [forms1, setforms1] = useState([]);

    const handlechange1 = (e) => {
        const myForm = { ...forms1 };
        myForm[e.target.name] = e.target.value;
        setforms1(myForm);
    };

    const getEligibility = async (e) => {
        e.preventDefault();
        const bodydata = {
            emplyementStatus: forms.emplyementStatus || "Salaried",
            name: forms.name,
            mobile: forms.mobile,
            email: forms.email,
            loanPurpose: forms.loanPurpose,
            revenue: forms.revenue || "Less than 20 Lakhs",
            salaryReceivedAs: forms.salaryReceivedAs,
            propertyLocation: forms.propertyLocation,
            city: forms.city,
            pincode: forms.pincode,
            dateOfBirth: forms.dateOfBirth,
            companyName: forms.companyName,
            dateOfBirth: forms.dateOfBirth,
            salary: forms.salary,
            otp: forms.otp,
            avgMontlyIncome: forms.avgMontlyIncome,
        };
        try {
            const resonse = await addData("eligibility/add", bodydata);
            var _data = resonse;
            console.log(_data);
            // toast.success(_data.data.message);
            setOpen(true)
            setforms({
                emplyementStatus: "",
                name: "",
                mobile: "",
                email: "",
                loanPurpose: "",
                revenue: "",
                salaryReceivedAs: "",
                propertyLocation: "",
                city: "",
                pincode: "",
                dateOfBirth: "",
                companyName: "",
                dateOfBirth: "",
                salary: "",
                otp: "",
                avgMontlyIncome: "",
            });
            setresends(false);
            settimeshow(false);

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                setOpen1(true)
                // toast.error(error.response.data.message);
            } else {
                // toast.error("An error occurred. Please try again.")
            }
        }
    };

    const [Files1, setFiles1] = useState({ itReturn: "", })
    console.log(Files1)

    const filechange1 = e => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
            setFiles1(e.target.files)
        } else {
            e.target.value = null
            toast.error("File format is not accepted.Please pick the right one")
        }

    }

    const [Files10, setFiles10] = useState({ bankStatement: "" })
    console.log(Files10)

    const filechange10 = e => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
            setFiles10(e.target.files)
        } else {
            e.target.value = null
            toast.error("File format is not accepted.Please pick the right one")
        }

    }
    const [Files11, setFiles11] = useState({ aadharCard: "", })
    console.log(Files11)

    const filechange11 = (e) => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png") {
            setFiles11(e.target.files)
        } else {
            e.target.value = null
            toast.error("File format is not accepted.Please pick the right one")
        }

    }
    const [Files12, setFiles12] = useState({ panCard: "" })
    console.log(Files12)

    const filechange12 = (e) => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png") {
            setFiles12(e.target.files)
        } else {
            e.target.value = null
            toast.error("File format is not accepted.Please pick the right one")
        }

    }
    const [Files13, setFiles13] = useState({ salarySlip: "" })
    console.log(Files13)
    const filechange13 = e => {
        const file = e.target.files
        var ext = file[0].name.split(".").pop()
        var type = ext
        if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
            setFiles13(e.target.files)
        } else {
            e.target.value = null
            toast.error("File format is not accepted.Please pick the right one")
        }
    }

    const Instatenloan = async (e) => {
        e.preventDefault();
        const dataArray = new FormData()
        dataArray.append("name", forms1.name)
        dataArray.append("mobile", forms1.mobile)
        dataArray.append("email", forms1.email)
        dataArray.append("otp", forms1.otp)
        dataArray.append("amount", forms1.amount)
        dataArray.append("purposeOfLoan", forms1.purposeOfLoan)

        for (let i = 0; i < Files1.length; i++) {
            dataArray.append("itReturn", Files1[i])
        }
        for (let i = 0; i < Files10.length; i++) {
            dataArray.append("bankStatement", Files10[i])
        }
        for (let i = 0; i < Files11.length; i++) {
            dataArray.append("aadharCard", Files11[i])
        }
        for (let i = 0; i < Files12.length; i++) {
            dataArray.append("panCard", Files12[i])
        }
        for (let i = 0; i < Files13.length; i++) {
            dataArray.append("salarySlip", Files13[i])
        }

        try {
            const resonse = await addData("instantloan/add", dataArray);
            var _data = resonse;
            console.log(_data);
            // toast.success(_data.data.message);
            setOpen(true)
            closeModal1()
            setforms1({
                purposeOfLoan: "",
                name: "",
                mobile: "",
                email: "",
                otp: "",
                amount: "",
            });
            setFiles1({ itReturn: "" })
            setFiles10({ bankStatement: "" })
            setFiles11({ aadharCard: "" })
            setFiles12({ panCard: "" })
            setFiles13({ salarySlip: "" })
            setresends1(false);
            settimeshow1(false);

        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                // toast.error(error.response.data.message);
                setOpen1(true)
            } else {
                // toast.error("An error occurred. Please try again.")
            }
        }
    };

    const closeModal1 = () => {
        const modalElement = document.getElementById("modal_aside111");
        const closeButton = modalElement.querySelector(".close");
        modalElement.classList.remove("show");
        modalElement.setAttribute("aria-modal", "false");
        modalElement.setAttribute("style", "display: none");
        document.body.classList.remove("modal-open");
        closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
    };

    return (
        <div>

            <div>
                <div className="conteststy1">
                    <button
                        href="#modal"
                        data-toggle="modal"
                        data-target="#modal_aside111"
                        style={{ background: "#ffb840", color: "#fff" }} className="btn btndirection "> <i class="fa fa-angle-double-left" aria-hidden="true"></i> Get Instant Loan </button>
                </div>
            </div>
            <div>
                <div className="conteststy">
                    <a href="https://api.whatsapp.com/send/?phone=918333993223&text=Hi&type=phone_number&app_absent=0" target="_blank" ><button className="btn btn-success rounded-pill"> <i class="fa fa-whatsapp" aria-hidden="true"></i>Need A Loan ? </button></a>
                </div>
            </div>

            <Toaster />

            <div
                id="modal_aside111"
                className="modal fixed-center fade"
                tabIndex={-1}
            // role="dialog"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Get Instant Loan</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <form onSubmit={(e) => { Instatenloan(e) }}>
                                <div className="row mb-4 mt-1">

                                    <div className="col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor> Name  <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                pattern="^[a-zA-Z ]*$"
                                                name="name"
                                                value={forms1.name}
                                                onChange={(e) => { handlechange1(e) }}
                                                placeholder="Name"
                                                className="form-control"
                                                required
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3 ">
                                        <div className="">
                                            <label htmlFor> Email  <span className="text-danger">*</span></label>
                                            <input
                                                name="email"
                                                value={forms1.email}
                                                onChange={(e) => { handlechange1(e) }}
                                                type="email" placeholder="Email" className="form-control" required />

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6 mb-3 ">
                                                <div className="">
                                                    <label htmlFor> Mobile Number  <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text" pattern="[0-9]{10}"
                                                        maxLength={10}
                                                        placeholder="Mobile Number"
                                                        name="mobile"
                                                        value={forms1.mobile}
                                                        required
                                                        onChange={(e) => {
                                                            handlechangephone1(e);
                                                        }} className="form-control"
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3 ">
                                                <div className="">
                                                    <label htmlFor> OTP  <span className="text-danger">*</span></label>
                                                    <input
                                                        name="otp"
                                                        value={forms1.otp}
                                                        onChange={(e) => {
                                                            handlechangeotp1(e);
                                                        }}
                                                        type="text"
                                                        placeholder="OTP"
                                                        required className="form-control"
                                                    />

                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-6"></div>
                                            <div className="col-6">
                                                <div className="row">
                                                    <div className="col-6">
                                                        {timeshow1 == true ? (
                                                            <span>{time} seconds  <span className="text-danger">*</span></span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <div className="col-6">
                                                        {resends1 == true ? (
                                                            <div
                                                                type="button"
                                                                onClick={() => {
                                                                    resendotps1();
                                                                }}
                                                                style={{ float: "right" }}
                                                                className="mb-2"
                                                            >
                                                                Resend Otp
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor> Purpose of loan   <span className="text-danger">*</span></label>
                                            <select
                                                name="purposeOfLoan"
                                                onChange={(e) => {
                                                    handlechange1(e);
                                                }}
                                                value={forms1.purposeOfLoan}
                                                required className="form-control"
                                            >
                                                <option value="">Purpose of loan  </option>
                                                <option value="Business Loan">
                                                    Business Loan
                                                </option>
                                                <option value="Personal Loan">
                                                    Personal Loan
                                                </option>
                                            </select>
                                            {" "}
                                        </div>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <div className="">
                                            <label htmlFor>Required Loan Amount  <span className="text-danger">*</span></label>
                                            <input
                                                type="number"
                                                name="amount"
                                                value={forms1.amount}
                                                onChange={(e) => { handlechange1(e) }}
                                                placeholder="Required Loan Amount "
                                                required className="form-control"
                                            />

                                        </div>
                                    </div>

                                    <div className="row p-3">
                                        {forms1.purposeOfLoan == "Business Loan" ? (
                                            <>
                                                <div className="col-md-6 mb-4 ">
                                                    <label htmlFor> It Returns  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, pdf)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="itReturn"
                                                            onChange={(e) => {
                                                                filechange1(e);
                                                            }}
                                                            value={Files1.itReturn} className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4 ">
                                                    <label htmlFor>Bank Statements  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, pdf)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="bankStatement"
                                                            onChange={(e) => {
                                                                filechange10(e);
                                                            }}
                                                            value={Files10.bankStatement} className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4 ">
                                                    <label htmlFor>Aadhar Card  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="aadharCard"
                                                            onChange={(e) => {
                                                                filechange11(e);
                                                            }}
                                                            value={Files11.aadharCard} className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-4 ">
                                                    <label htmlFor>Pan Card  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="panCard"
                                                            onChange={(e) => {
                                                                filechange12(e);
                                                            }}
                                                            value={Files12.panCard}
                                                            className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {forms1.purposeOfLoan == "Personal Loan" ? (
                                            <>
                                                <div className="col-md-6 mb-3 ">
                                                    <label htmlFor>Bank Statements  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, pdf)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="bankStatement"
                                                            onChange={(e) => {
                                                                filechange10(e);
                                                            }}
                                                            value={Files10.bankStatement} className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3 ">
                                                    <label htmlFor>Aadhar Card  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="aadharCard"
                                                            onChange={(e) => {
                                                                filechange11(e);
                                                            }}
                                                            value={Files11.aadharCard} className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3 ">
                                                    <label htmlFor>Pan Card  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="panCard"
                                                            onChange={(e) => {
                                                                filechange12(e);
                                                            }}
                                                            value={Files12.panCard} className="form-control"

                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3 ">
                                                    <label htmlFor>Salary Slips  <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, pdf)</span>
                                                    <div className="">
                                                        <input
                                                            type="file"
                                                            placeholder=""
                                                            required
                                                            name="salarySlip"
                                                            onChange={(e) => {
                                                                filechange13(e);
                                                            }}
                                                            value={Files13.salarySlip} className="form-control"
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <button
                                        className="btn-rd123 m-3"
                                        style={{ width: "100%" }}
                                        type="submit"
                                    >
                                        Submit{" "}
                                        <i
                                            class="fa fa-check-circle-o"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal-dialog-centered" size="sm" isOpen={open}>
                <ModalBody className="text-center">
                    <img src={successimg} style={{ width: "70%" }} />
                    <h5>Thank You!</h5>
                    <a className="text-secondary">  Our Team Get Back To You</a>
                </ModalBody>
                <div className="text-center">
                    <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
                        Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
                    </Button>
                </div>
            </Modal>
            <Modal className="modal-dialog-centered" size="sm" isOpen={open1}>
                <ModalBody className="text-center">
                    <img src={errorimg} style={{ width: "70%" }} />
                    <h5 className="text-danger">Someting Went Wrong.</h5>
                    <a className="text-secondary"> Please Check & Try Again</a>
                </ModalBody>
                <div className="text-center">
                    <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle1}>
                        Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
                    </Button>
                </div>
            </Modal>
            <Modal className="modal-dialog-centered" size="sm" isOpen={open2}>
                <ModalBody className="text-center">
                    <img src={crossimg} style={{ width: "70%" }} />
                    <h5 className="text-danger">Access Is 18+ Only</h5>
                </ModalBody>
                <div className="text-center">
                    <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle2}>
                        Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default Banks;
