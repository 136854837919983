import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Contents from './Contents'

function Privacypolicies() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div>
            <body
                data-spy="scroll"
                data-target=".navbar"
                data-offset="100"
                className="ct1280"
            >
                <Header />
                <div>

                    <section
                        style={{ paddingTop: "80px" }}
                        className="hero-section-1"
                        id="home"
                    >
                        <div
                            style={{ opacity: 0.8, backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundRepeat: "no-repeat" }}
                            className="text-center"
                        >
                            <div className="headst">
                                <div className="text-white ">
                                    <h1>Privacy Policies</h1>
                                </div>
                            </div>

                        </div>
                    </section>
                    <div className='container pt-5 pb-5'>
                        <div style={{ fontSize: "18px" }}>
                            <li className='mb-3'>At Right Agent Group, we understand the importance of maintaining the privacy and security of our customer&#39;s personal information.</li>
                            <li className='mb-3'>We are committed to protecting the privacy and confidentiality of all information
                                provided to us.</li>
                            <li className='mb-3'>We adhere to the principles outlined in the General Data Protection Regulation, which
                                sets out guidelines for fair and lawful data processing, purpose limitation, data
                                minimization, accuracy, storage limitation, integrity and confidentiality.</li>
                            <li className='mb-3'>Additionally, we ensure that data protection by design and default is ingrained in our
                                practices, supported by transparency and accountability.</li>
                            <li className='mb-3'>All personal data collected by Right Agent Group is done so with the explicit consent
                                of the individual and only for necessary processing purposes.</li>
                            <li className='mb-3'>We collect and process data in a manner that is transparent, accurate and respects the
                                individual&#39;s rights. Furthermore, we follow the principle of data minimization,
                                ensuring that we only collect and store the minimum amount of personal information
                                necessary to carry out our services.</li>
                            <li className='mb-3'>To safeguard the data we collect and process, Right Agent Group implements strict
                                security measures, including encryption, access controls, and regular audits.</li>
                            <li className='mb-3'>Additionally, we have implemented retention policies to ensure that personal
                                information is not stored for longer than necessary.</li>
                            <li className='mb-3'>Overall, at Right Agent Group, we prioritize the privacy and security of our
                                customer&#39;s personal information.</li>
                            <li className='mb-3'>We understand that privacy is a fundamental human right and have implemented
                                comprehensive policies to protect the personal information of our clients.</li>
                            <li className='mb-3'>At Right Agent Group, we prioritize the privacy and security of our customer&#39;s
                                personal information.</li>
                            <li className='mb-3'>We adhere to the principles outlined in the General Data Protection Regulation, which
                                is a comprehensive set of guidelines for data protection.</li>
                            <li className='mb-3'>These principles include fairness and lawfulness in processing personal data, purpose
                                limitation to ensure that data is only used for legitimate purposes, data minimization
                                to collect and store only the necessary information, accuracy to ensure that personal
                                data is up-to-date and reliable, storage limitation to retain data only for as long as
                                necessary, and integrity and confidentiality to safeguard the data from unauthorized
                                access or use.</li>
                            <li className='mb-3'>Additionally, we incorporate the principles of data protection by design and default
                                into our practices, ensuring that privacy considerations are integrated into all stages of
                                our processes.</li>
                            <li className='mb-3'>For example, we implement privacy-by-design principles when developing new
                                systems or services, meaning that data protection is built into the design and
                                functionality of our offerings.</li>
                            <li className='mb-3'>As part of our commitment to transparency, we provide individuals with clear and
                                easily understandable information about how their data is collected, processed, and
                                protected.</li>
                            <li className='mb-3'>We also respect the rights of individuals to access, correct, and delete their personal
                                information. Furthermore, at Right Agent Group, we understand the importance of
                                obtaining consent from individuals before collecting and processing their personal
                                information.</li>
                            <li className='mb-3'>We strive to obtain explicit and informed consent from our clients, ensuring that they
                                have full control over their personal information.</li>
                            <li className='mb-3'>We also have policies in place to ensure that personal information is not stored for
                                longer than necessary.</li>
                            <li className='mb-3'>Our accountability measures include regular audits and assessments to monitor our
                                compliance with privacy laws and regulations. In summary, our Privacy Policy at
                                Right Agent Group is designed to protect the personal information of our clients by
                                adhering to the principles outlined in the General Data Protection Regulation.</li>
                            <li className='mb-3'>Our Privacy Policy at Right Agent Group is designed to protect the personal
                                information of our clients by adhering to the principles outlined in the General Data
                                Protection Regulation and other relevant data protection laws.</li>
                            <li className='mb-3'>Our Privacy Policy at Right Agent Group is designed to protect the personal
                                information of our clients by adhering to the principles outlined in the General Data
                                Protection Regulation and other relevant data protection laws. In summary, our
                                Privacy Policy at Right Agent Group is designed to protect the personal information
                                of our clients by adhering to the principles outlined in the General Data Protection
                                Regulation and other relevant data protection laws.</li>
                            <li className='mb-3'>The completion of the sentence could be: &quot;Our Privacy Policy at Right Agent Group
                                is designed to protect the personal information of our clients by adhering to the
                                principles outlined in the General Data Protection Regulation and other relevant data
                                protection laws.</li>

                        </div>
                    </div>
                </div>
            </body>
            <Footer />
            <Contents />
        </div>
    )
}

export default Privacypolicies