import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Contents from './Contents'
import { Link } from "react-router-dom";
import { addData, addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment'

import img1 from "../assets/images/icons/choice.png";
import img2 from "../assets/images/icons/customer-services.png";
import img3 from "../assets/images/icons/piggy-bank.png";
import img4 from "../assets/images/icons/credit-card.png";
import backgroundimg from "../assets/images/agents/backgroundimg.jpg";
import img5 from "../assets/images/common/loan.jpg";
import addimg from "../assets/images/banners/9.png";
import { Button, Modal, ModalBody } from 'reactstrap';
import successimg from '../assets/images/reviews/success.png'
import errorimg from '../assets/images/reviews/error.png'
import crossimg from '../assets/images/reviews/crossimg.jpg'
import ReactApexChart from 'react-apexcharts';
import DocumentMeta from 'react-document-meta';
function Banks() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => setOpen1(!open1);
  const [open2, setOpen2] = useState(false);
  const toggle2 = () => setOpen2(!open2);

  const [form, setform] = useState({});
  const [form1, setform1] = useState([]);

  const getOnedata = async () => {
    const bodydata = {
      id: sessionStorage.getItem("loanid"),
    };
    const resonse = await addData("allproduct/subnextbyid", bodydata);
    var _data = resonse;
    setform(_data.data.subProduct);
  };

  const getAllBanks = async () => {
    const bodydata = {
      productId: "64720173d0f054719b9b2880", //Loan
    };
    const resonse = await addDataadmin(
      "loanpartner/getloanpartnerbyproductid",
      bodydata
    );
    var _data = resonse;
    setform1(_data.data.loanPartner);
  };

  const [banner, setbanner] = useState([]);
  const [banner2, setbanner2] = useState([]);
  const [banner3, setbanner3] = useState([]);
  const [banner4, setbanner4] = useState([]);
  const [states, setstates] = useState([]);

  const getAllbanners = async () => {
    const resonse = await addData("banner/getallloan");
    var _data = resonse;
    setbanner(_data.data.horizontal[0]);
    setbanner2(_data.data.horizontal[1]);
    setbanner3(_data.data.vertical[0]);
    setbanner4(_data.data.vertical[1]);
  };
  const [banner1, setbanner1] = useState([]);
  const getAllbanners1 = async () => {
    const resonse = await addDataadmin("pagebanners/getactive");
    var _data = resonse;
    setbanner1(_data.data.pageBanners[1]);
  };

  const getAllstates = async () => {
    const resonse = await addDataadmin("state/getall");
    var _data = resonse;
    setstates(_data.data.statesResult);
  };

  useEffect(() => {
    getOnedata();
    getAllstates();
    getAllBanks();
    getAllbanners();
    getAllbanners1();
    scrollToTop();
  }, []);

  const [principal, setPrincipal] = useState(0);
  console.log(principal)
  const [interestRate, setInterestRate] = useState(0);
  console.log(interestRate)
  const [tenure, setTenure] = useState(0);
  console.log(tenure)
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  console.log(totalInterest)
  const [totalPayment, setTotalPayment] = useState(0);

  const principalChange = (e) => {
    setPrincipal(e.target.value);
    // calculateEmi();
    const amount = parseFloat(e.target.value);
    const yeass = parseFloat(tenure * 12);
    console.log(yeass)
    const terestRate = (parseFloat(interestRate) / 100) / 12;
    const emivalue = amount * terestRate * (Math.pow(1 + terestRate, yeass) / (Math.pow(1 + terestRate, yeass) - 1))
    setEmi(emivalue)
    setTotalPayment(emivalue * yeass)
    setTotalInterest(parseFloat(emivalue * yeass) - parseFloat(e.target.value))
  };

  const interestRateChange = (e) => {
    setInterestRate(e.target.value);
    // calculateEmi();
    const amount = parseFloat(principal);
    const yeass = parseFloat(tenure * 12);
    console.log(yeass)
    const terestRate = (parseFloat(e.target.value) / 100) / 12;

    const emivalue = amount * terestRate * (Math.pow(1 + terestRate, yeass) / (Math.pow(1 + terestRate, yeass) - 1))
    setEmi(emivalue)
    setTotalPayment(emivalue * yeass)
    setTotalInterest(parseFloat(emivalue * yeass) - parseFloat(principal))
  };

  const tenureChange = (e) => {
    setTenure(e.target.value);
    // calculateEmi();
    const amount = parseFloat(principal);
    const yeass = parseFloat(e.target.value * 12);
    console.log(yeass)
    const terestRate = (parseFloat(interestRate) / 100) / 12;

    const emivalue = amount * terestRate * (Math.pow(1 + terestRate, yeass) / (Math.pow(1 + terestRate, yeass) - 1))
    setEmi(emivalue)
    setTotalPayment(emivalue * yeass)
    setTotalInterest(parseFloat(emivalue * yeass) - amount)
  };


  // const calculateEmi = () => {



  // const principalFloat = parseFloat(principal);
  // const interestRateFloat = parseFloat(interestRate) / 100 / 12;
  // const tenureFloat = parseFloat(tenure) * 12;

  // if (principalFloat && interestRateFloat && tenureFloat) {
  //   const emiValue =
  //     (principalFloat *
  //       interestRateFloat *
  //       Math.pow(1 + interestRateFloat, tenureFloat)) /
  //     (Math.pow(1 + interestRateFloat, tenureFloat) - 1);
  //   setEmi(emiValue.toFixed(2));

  //   const totalPaymentValue = emiValue * tenureFloat;
  //   setTotalPayment(totalPaymentValue.toFixed(2));

  //   const totalInterestValue = totalPaymentValue - principalFloat;
  //   setTotalInterest(totalInterestValue.toFixed(2));
  // } else {
  //   setEmi(0);
  //   setTotalPayment(0);
  //   setTotalInterest(0);
  // }
  // };

  const [forms, setforms] = useState({
    emplyementStatus: "",
    name: "",
    mobile: "",
    email: "",
    loanPurpose: "",
    revenue: "",
    salaryReceivedAs: "",
    propertyLocation: "",
    city: "",
    pincode: "",
    dateOfBirth: "",
    companyName: "",
    dateOfBirth: "",
    salary: "",
    otp: "",
    avgMontlyIncome: "",
  });

  const handlechange = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);
  };

  const [yeardiffer, setyeardiffer] = useState('')
  console.log(yeardiffer)

  const handlechangedate = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);

    const givenDate = new Date(e.target.value);
    const today = new Date();
    // const timeDifference = today.getTime() - givenDate.getTime();
    // const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const yearsDifference = today.getFullYear() - givenDate.getFullYear();
    setyeardiffer(yearsDifference)
    setdateofbirths(false)
  };

  const [time, setTime] = useState(30);
  useEffect(() => {
    const updateTimer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(updateTimer);
  }, []);

  const [timeshow, settimeshow] = useState(false);
  const [resends, setresends] = useState(false);

  const handlechangephone = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputMobile;
    setforms(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps = async () => {
    const bodydata = {
      mobile: forms.mobile,
    };

    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow(true);
      setTime(30);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }
  };

  const handlechangeotp = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputOtp;
    setforms(myForm);

    const bodydata = {
      mobile: forms.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const [timeshow1, settimeshow1] = useState(false);
  const [resends1, setresends1] = useState(false);

  const handlechangephone1 = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputMobile;
    setforms1(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends1(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps1 = async () => {
    const bodydata = {
      mobile: forms1.mobile,
    };

    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow1(true);
      setTime(30);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }
  };

  const handlechangeotp1 = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputOtp;
    setforms1(myForm);

    const bodydata = {
      mobile: forms1.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const [forms1, setforms1] = useState([]);

  const handlechange1 = (e) => {
    const myForm = { ...forms1 };
    myForm[e.target.name] = e.target.value;
    setforms1(myForm);
  };

  const getEligibility = async (e) => {
    e.preventDefault();
    const bodydata = {
      emplyementStatus: forms.emplyementStatus || "Salaried",
      name: forms.name,
      mobile: forms.mobile,
      email: forms.email,
      loanPurpose: form.name,
      revenue: forms.revenue || "Less than 20 Lakhs",
      salaryReceivedAs: forms.salaryReceivedAs,
      propertyLocation: forms.propertyLocation,
      city: forms.city,
      pincode: forms.pincode,
      dateOfBirth: forms.dateOfBirth,
      companyName: forms.companyName,
      dateOfBirth: forms.dateOfBirth,
      salary: forms.salary,
      otp: forms.otp,
      avgMontlyIncome: forms.avgMontlyIncome,
    };
    try {
      const resonse = await addData("eligibility/add", bodydata);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setOpen(true)
      setforms({
        emplyementStatus: "",
        name: "",
        mobile: "",
        email: "",
        loanPurpose: "",
        revenue: "",
        salaryReceivedAs: false,
        propertyLocation: "",
        city: "",
        pincode: "",
        dateOfBirth: "",
        companyName: "",
        salary: "",
        otp: "",
        avgMontlyIncome: "",

      });
      setresends(false);
      settimeshow(false);

    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setOpen1(true)
        // toast.error(error.response.data.message);
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const [Files1, setFiles1] = useState({ itReturn: "", })
  console.log(Files1)

  const filechange1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }

  const [Files10, setFiles10] = useState({ bankStatement: "" })
  console.log(Files10)

  const filechange10 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles10(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }
  const [Files11, setFiles11] = useState({ aadharCard: "", })
  console.log(Files11)

  const filechange11 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles11(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }
  const [Files12, setFiles12] = useState({ panCard: "" })
  console.log(Files12)

  const filechange12 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles12(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }
  const [Files13, setFiles13] = useState({ salarySlip: "" })
  console.log(Files13)
  const filechange13 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles13(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }
  }

  const Instatenloan = async (e) => {
    e.preventDefault();
    const dataArray = new FormData()
    dataArray.append("name", forms1.name)
    dataArray.append("mobile", forms1.mobile)
    dataArray.append("email", forms1.email)
    dataArray.append("otp", forms1.otp)
    dataArray.append("amount", forms1.amount)
    dataArray.append("purposeOfLoan", forms1.purposeOfLoan)

    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("itReturn", Files1[i])
    }
    for (let i = 0; i < Files10.length; i++) {
      dataArray.append("bankStatement", Files10[i])
    }
    for (let i = 0; i < Files11.length; i++) {
      dataArray.append("aadharCard", Files11[i])
    }
    for (let i = 0; i < Files12.length; i++) {
      dataArray.append("panCard", Files12[i])
    }
    for (let i = 0; i < Files13.length; i++) {
      dataArray.append("salarySlip", Files13[i])
    }

    try {
      const resonse = await addData("instantloan/add", dataArray);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setOpen(true)
      closeModal1()
      setforms1({
        purposeOfLoan: "",
        name: "",
        mobile: "",
        email: "",
        otp: "",
      });
      setFiles1({ itReturn: "" })
      setFiles10({ bankStatement: "" })
      setFiles11({ aadharCard: "" })
      setFiles12({ panCard: "" })
      setFiles13({ salarySlip: "" })
      setresends1(false);
      settimeshow1(false);

    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const closeModal1 = () => {
    const modalElement = document.getElementById("modal_aside1");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
  };

  const [dateofbirths, setdateofbirths] = useState(false)



  // const series = [44, 15]

  // const a1 = Number(principal.toFixed(0))
  // const a2 = Number(totalInterest.toFixed(0))

  const a2 = Math.round(principal);
  const a1 = Math.round(totalInterest);

  // const b1 = a1.toFixed(2);
  // const b2 = a2.toFixed(2);

  // console.log()

  const series = [a1, a2]
  const options = {
    labels: ['Total Interest', 'Principal Amount'],
    colors: ["#f1b44c", "#34c38f",],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }



  // const options = {
  //   chart: {
  //     width: 380,
  //     type: 'donut',
  //   },
  //   colors: ['#23bdee', '#a5ed92'],
  //   plotOptions: {
  //     pie: {
  //       startAngle: -90,
  //       endAngle: 270,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   fill: {
  //     type: 'gradient',
  //   },
  //   legend: {
  //     show: false, 
  //   },
  //   labels: ['Total Interest', 'Principal Amount'], 


  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         chart: {
  //           width: 200,
  //         },
  //         legend: {
  //           position: 'bottom',
  //         },
  //       },
  //     },
  //   ],
  // };

  const chartTitleStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
  };

  const slug = form?.name?.toLowerCase().replace(/\s+/g, "-");
  const meta = {
    title: form.seoTitle,
    description: form.seoDescription,
    canonical: 'https://rightagentgroup.com/loans/'+ slug,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: form.seoKeys
      }
    }
  };


  return (
    <DocumentMeta {...meta}>
    <div>
      <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="100"
        className="ct1280"
      >
        <Header />
        <section
          style={{ paddingTop: "80px" }}
          className="hero-section-1"
          id="home"
        >
          <div
            style={{
              background: `url(${imgUrl + banner1.image})`,
              opacity: 0.8,
              backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundRepeat: "no-repeat"
            }}
            className="text-center"
          >
            <div className="headst">
              <div className="text-white ">
                <h1>{form.name}</h1>
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6">
                    {" "}
                    <p style={{ fontSize: "20px", display: "none" }}>{banner1.description}</p>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
                {/* <p style={{fontSize:"20px"}}>A Insurances is a debt incurred by an individual or some entity.</p> */}
              </div>
            </div>
            {/* <Link to="/">Home</Link> / <span>Loans</span> */}
          </div>
        </section>

        {/* start banks */}
        <section className="mb-3 pb-5">
          <div className="container">
            {/* <section>
              <div className="container pt-5">
              <a href={banner.link} target="_blank">
              <img
                style={{ width: "100%" }}
                src={imgUrl + banner.image}
                alt="banner"
              />
            </a>
              </div>
            </section> */}

            {/* Calculater  */}

            <div>
              <div className="row mt-5">
                <div className="col-lg-6">
                  <div className="row divrightbdr mt-3">
                    <div className="col-md-12">
                      <div
                        className="steps-div "
                        data-aos="fade-up"
                        data-aos-delay={100}
                      >
                        <div className="steps-icons-1"></div>
                        <h4 className="mb10 text-center mb-3">
                          Get Eligibility
                        </h4>
                        <div className="container">
                          <form
                            onSubmit={(e) => {
                              getEligibility(e);
                            }}
                          >
                            <div className="row mb-4">
                              <div className="col-md-6 mb-3">
                                <label htmlFor> Name <span className="text-danger">*</span></label>
                                <div className="Name">
                                  <input
                                    type="text"
                                    name="name"
                                    value={forms.name}
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    pattern="^[a-zA-Z ]*$"
                                    placeholder="Name"
                                    required
                                    className="form-control"
                                  />


                                </div>
                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor> Email <span className="text-danger">*</span></label>
                                <div className="">
                                  <input
                                    name="email"
                                    value={forms.email}
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    type="email" placeholder="Email" className="form-control" required />


                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-6 mb-3 ">
                                    <label htmlFor> Mobile Number <span className="text-danger">*</span></label>
                                    <div className="">
                                      <input
                                        // type="number"
                                        type="text" pattern="[0-9]{10}"
                                        maxLength={10}
                                        placeholder="Mobile Number"
                                        name="mobile"
                                        value={forms.mobile}
                                        required
                                        onChange={(e) => {
                                          handlechangephone(e);
                                        }} className="form-control"
                                      />


                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-3 ">
                                    <label htmlFor> OTP <span className="text-danger">*</span></label>
                                    <div className="">
                                      <input

                                        name="otp"
                                        value={forms.otp}
                                        onChange={(e) => {
                                          handlechangeotp(e);
                                        }}
                                        type="number"
                                        placeholder="OTP"
                                        required className="form-control"
                                      />


                                    </div>

                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-6"></div>
                                  <div className="col-6">
                                    <div className="row">
                                      <div className="col-6">
                                        {timeshow == true ? (
                                          <span>{time} seconds</span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-6">
                                        {resends == true ? (
                                          <div
                                            type="button"
                                            onClick={() => {
                                              resendotps();
                                            }}
                                            style={{ float: "right" }}
                                            className="mb-2"
                                          >
                                            Resend Otp
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Date of Birth <span className="text-danger">*</span>
                                </label>
                                <div className="">

                                  <input
                                    type="date"
                                    placeholder="Date of Birth"
                                    max={new Date(moment().subtract(18, "years")).toISOString().slice(0, -14)}
                                    // max={new Date()}
                                    required
                                    name="dateOfBirth"
                                    value={forms.dateOfBirth}
                                    onChange={(e) => {
                                      handlechangedate(e);
                                    }} className="form-control"
                                  />



                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label htmlFor> Employee Type <span className="text-danger">*</span></label>
                                <div className="">
                                  <select
                                    name="emplyementStatus"
                                    value={forms.emplyementStatus}
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    required className="form-control"
                                  ><option value="">Select  Employee Type</option>
                                    <option value="Salaried">Salaried</option>
                                    <option value="Self-Employed">
                                      Self-Employed
                                    </option>
                                    <option value="Business">Business</option>
                                  </select>


                                </div>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label htmlFor>Purpose of loan <span className="text-danger">*</span></label>
                                <div className="">
                                  <input required
                                    name="propertyLocation"
                                    value={form.name} className="form-control"
                                    readOnly
                                    type="text" placeholder="Purpose of loan" />
                                  {" "}

                                </div>

                                {/* <div className="">
                                  <select
                                    name="loanPurpose"
                                    value={forms.loanPurpose}
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    required
                                  >
                                    <option value="">Purpose of loan</option>
                                    <option value="Home Loans">Home Loans </option>
                                    <option value="Plot Loans">Plot Loans</option>
                                    <option value="Home Loans For NRI">Home Loans For NRI</option>
                                    <option value="Home Loans For Pensioners">Home Loans For Pensioners</option>
                                    <option value="Rutal Home Loans">Rutal Home Loans</option>
                                    <option value="Home Loans Takover & Top Up">Home Loans Takover & Top Up</option>
                                    <option value="House Renovation Loan">House Renovation Loan</option>
                                    <option value="Home Extention Loan">Home Extention Loan</option>
                                    <option value="Top Up Loan">Top Up Loan</option>
                                    <option value="Home Constraction Loan">Home Constraction Loan</option>
                                    <option value="Loan Against Property">Loan Against Property</option>
                                    <option value="Commercial Property Loan">Commercial Property Loan</option>
                                    <option value="Commercial Plot Loan">Commercial Plot Loan</option>
                                    <option value="Home Loan Balance Transfer">Home Loan Balance Transfer</option>
                                    <option value="Personal Loan">Personal Loan</option>
                                    <option value="Business Loan">Business Loan</option>
                                    <option value="Four Wheeler Loan">Four Wheeler Loan</option>

                                  </select>
                                 {" "}
                                </div> */}
                              </div>
                              <div className="col-md-6 mb-3">
                                <label htmlFor>Loan Required <span className="text-danger">*</span></label>
                                <div className="">
                                  <select className="form-control"
                                    name="revenue"
                                    value={forms.revenue}
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    required>
                                    <option value=""> Select Loan Required </option>

                                    <option value="Less than 20 Lakhs">
                                      Less than 20 Lakhs
                                    </option>
                                    <option value="20 Lakhs to 50 Lakhs">
                                      20 Lakhs to 50 Lakhs
                                    </option>
                                    <option value="50 lakhs and above">
                                      50 lakhs and above
                                    </option>
                                  </select>
                                  {" "}
                                </div>
                              </div>
                              {forms.emplyementStatus == "Self-Employed" || forms.emplyementStatus == "Business" ? (
                                ""
                              ) : (
                                <div className="col-md-6 mb-3">
                                  <label htmlFor>Salary received as <span className="text-danger">*</span></label>
                                  <div className="">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        id="inlineRadio1"
                                        defaultValue="Accounttransfer"
                                        name="salaryReceivedAs"
                                        value={forms.salaryReceivedAs}
                                        onChange={(e) => {
                                          handlechange(e);
                                        }}
                                        required
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio1"
                                      >
                                        Account Transfer
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="salaryReceivedAs"
                                        value={forms.salaryReceivedAs}
                                        onChange={(e) => {
                                          handlechange(e);
                                        }}
                                        id="inlineRadio2"
                                        defaultValue="cash"
                                        required
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineRadio2"
                                      >
                                        Cash
                                      </label>
                                    </div>
                                    {/* */}
                                  </div>
                                </div>
                              )}

                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Property Location <span className="text-danger">*</span></label>
                                <div className="">
                                  <input required className="form-control"
                                    name="propertyLocation"
                                    pattern="^[a-zA-Z ]*$"
                                    value={forms.propertyLocation}
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    type="text" placeholder="Property Location" />

                                  {" "}

                                </div>
                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>City <span className="text-danger">*</span></label>
                                <div className="">
                                  <input
                                    name="city" className="form-control"
                                    value={forms.city}
                                    pattern="^[a-zA-Z ]*$"
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    type="text" placeholder="City" required />


                                </div>
                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Pincode <span className="text-danger">*</span></label>
                                <div className="">
                                  <input required className="form-control"

                                    name="pincode"
                                    value={forms.pincode}
                                    onChange={(e) => {
                                      handlechange(e);
                                    }}
                                    maxLength={6}
                                    pattern="^\d{6}$"
                                    type="text" placeholder="Pincode " />


                                </div>
                              </div>
                              {/* {forms.salarytype == "Self-Employed" ? (
                                <div className="col-md-6 mb-3 ">
                                  <label htmlFor>
                                    Average Monthly Income <span className="text-danger">*</span>
                                  </label>
                                  <div className="">
                                    <input className="form-control"
                                      type="number"
                                      placeholder="Average Monthly Income"
                                      required
                                      name="avgMontlyIncome"
                                      value={forms.avgMontlyIncome}
                                      onChange={(e) => {
                                        handlechange(e);
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <> */}
                           
                              {forms.emplyementStatus == "Self-Employed" || forms.emplyementStatus == "Business" ? (
                                <>
                                  {/* <div className="col-md-6 mb-3 ">
                                    <label htmlFor>
                                      Average Monthly Income <span className="text-danger">*</span>
                                    </label>
                                    <div className="">
                                      <input className="form-control"
                                        type="number"
                                        placeholder="Average Monthly Income"
                                        required
                                        name="avgMontlyIncome"
                                        value={forms.avgMontlyIncome}
                                        onChange={(e) => {
                                          handlechange(e);
                                        }}
                                      />
                                    </div>
                                  </div> */}
                                  <div className="col-md-6 mb-3 ">
                                    <label htmlFor>Company Name <span className="text-danger">*</span></label>
                                    <div className="">
                                      <input className="form-control"
                                        type="text"
                                        placeholder=" Company Name"
                                        required
                                        name="companyName"
                                        pattern="^[a-zA-Z ]*$"
                                        value={forms.companyName}
                                        onChange={(e) => {
                                          handlechange(e);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-3 ">
                                    <label htmlFor>
                                      Gross fixed monthly income <span className="text-danger">*</span>
                                    </label>
                                    <div className="">
                                      <input className="form-control"
                                        type="text"
                                        placeholder="Gross fixed monthly income"
                                        required
                                        name="salary"
                                        value={forms.salary}
                                        onChange={(e) => {
                                          handlechange(e);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              {forms.emplyementStatus == "Salaried" ? (
                                <>
                                  <div className="col-md-6 mb-3 ">
                                    <label htmlFor>Company Name <span className="text-danger">*</span></label>
                                    <div className="">
                                      <input className="form-control"
                                        type="text"
                                        placeholder=" Company Name"
                                        required
                                        name="companyName"
                                        pattern="^[a-zA-Z ]*$"
                                        value={forms.companyName}
                                        onChange={(e) => {
                                          handlechange(e);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-3 ">
                                    <label htmlFor>
                                      Gross fixed monthly salary <span className="text-danger">*</span>
                                    </label>
                                    <div className="">
                                      <input className="form-control"
                                        type="text"
                                        placeholder="Gross fixed monthly salary"
                                        required
                                        name="salary"
                                        value={forms.salary}
                                        onChange={(e) => {
                                          handlechange(e);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : ("")}

                              {/* </>
                              )} */}

                              {/* {yeardiffer <= 18 ? (
                                <>
                                  <button
                                    className="btn-rd123 mb-3"
                                    style={{ width: "100%" }}
                                    type="button"
                                    // disabled
                                    onClick={toggle2}
                                  >
                                    Check Eligibility{" "}
                                  </button>

                                </>
                              ) : ( */}
                              <button
                                className="btn-rd123"
                                style={{ width: "100%" }}
                                type="submit"
                              >
                                Check Eligibility{" "}
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                              {/* )} */}

                            </div>
                          </form>
                        </div>

                        {/* <form onSubmit={(e)=>{getyourquote(e)}}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Request Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select name="requestType" value={forms.requestType} onChange={(e)=>{handlechange(e)}} className="form-control1">
                                <option value="">Select Request Type</option>
                                <option value="New Loan">New Loan</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Purpose of Loan{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input placeholder="Purpose of Loan"  name="loanPurpose" value={forms.loanPurpose} onChange={(e)=>{handlechange(e)}} className="form-control1" />
                               
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Residential Status{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input placeholder="Residential Status"   name="residentialStatus" value={forms.residentialStatus} onChange={(e)=>{handlechange(e)}} className="form-control1" />

                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Have you taken loan ?{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select  name="loanTaken" value={forms.loanTaken} onChange={(e)=>{handlechange(e)}}  className="form-control1">
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                State <span className="text-danger">*</span>
                              </label>
                              <select  name="state" value={forms.state} onChange={(e)=>{handlechange(e)}} className="form-control1">
                                <option value="">Select</option>
                                {states.map((data)=>(
                                  <option value={data.stateName}>{data.stateName}</option>
                                ))}
                             
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                City <span className="text-danger">*</span>
                              </label>
                              <input placeholder="City" name="city" value={forms.city} onChange={(e)=>{handlechange(e)}} className="form-control1" />

                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Area Office{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input placeholder=" Area Office" name="areaOffice" value={forms.areaOffice} onChange={(e)=>{handlechange(e)}} className="form-control1" />

                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Existing Loan from LIC HFL?{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select  name="existingLoan" value={forms.existingLoan} onChange={(e)=>{handlechange(e)}}  className="form-control1">
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                          <h5 className="mt-3 mb-3">
                            PERSONAL / EMPLOYMENT DETAILS
                          </h5>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Customer Name
                                <span className="text-danger">*</span>
                              </label>
                              <input placeholder="Customer Name" name="name" value={forms.name} onChange={(e)=>{handlechange(e)}} className="form-control1" />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Date of birth{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input type="date" placeholder=" Date of birth" name="dateOfBirth" value={forms.dateOfBirth} onChange={(e)=>{handlechange(e)}} className="form-control1" />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Gender <span className="text-danger">*</span>
                              </label>
                              <select name="gender" value={forms.gender} onChange={(e)=>{handlechange(e)}}  className="form-control1">
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Type of Employment{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select name="employmentType" value={forms.employmentType} onChange={(e)=>{handlechange(e)}} className="form-control1">
                                <option value="">
                                  Select Type of Employment
                                </option>
                                <option value="Salaried">Salaried</option>
                                <option value="Agriculture">Agriculture</option>
                                <option value="Business">Business</option>
                                <option value="Student">Student</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12 text-end">
                            <div className="mb-4">
                              <button type="submit" className="btn btn-primary w-100">
                                Save Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </form> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="row divrightbdr mt-3">
                    <div className="col-md-12">
                      <div
                        className="steps-div p-3"
                        data-aos="fade-up"
                        data-aos-delay={100}
                      >
                        <div className="p-4">
                          <h4 className="text-center">EMI Calculator</h4>
                          <div className="row  mt-4">
                            <div className="col-7">
                              {" "}
                              <label>Principal Amount:</label>
                            </div>
                            <div className="col-lg-5 ">
                              <div>
                                <div className="input-group mb-2">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text ">₹</div>
                                  </div>
                                  <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    max={10000000}
                                    name="principal"
                                    value={principal}
                                    onChange={principalChange}
                                  />
                                </div>
                                {/* <h5 className="border border-dark p-1">
                                  {principal}
                                </h5> */}
                              </div>
                            </div>

                            <input
                              className="w-100"
                              type="range"
                              min="0"
                              max="10000000"
                              name="principal"
                              value={principal}
                              onChange={principalChange}
                            />
                          </div>

                          <div className="row  mt-4">
                            <div className="col-7">
                              {" "}
                              <label>Interest Rate(%):</label>
                            </div>
                            <div className="col-lg-5 text-end ">
                              <div>
                                <div className="input-group mb-2">
                                  <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    max={20}
                                    name="interestRate"
                                    value={interestRate}
                                    onChange={interestRateChange}
                                  />
                                  <div className="input-group-prepend">
                                    <div className="input-group-text ">%</div>
                                  </div>
                                </div>
                              </div>
                              {/* <div style={{ float: "right" }}>
                                <h5 className="border border-dark p-1">
                                  {interestRate}%
                                </h5>
                              </div> */}
                            </div>

                            <input
                              className="w-100 "
                              type="range"
                              min="0"
                              max="20"
                              step="0.1"
                              value={interestRate}
                              onChange={interestRateChange}
                            />
                          </div>
                          <div className="row  mt-4">
                            <div className="col-7">
                              {" "}
                              <label>Tenure (in years):</label>
                            </div>
                            <div className="col-lg-5 text-end bordered">
                              <div>
                                <div className="input-group mb-2">
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="tenure"
                                    min={1}
                                    max={30}
                                    value={tenure}
                                    onChange={tenureChange}
                                  />
                                  <div className="input-group-prepend">
                                    <div className="input-group-text ">
                                      years
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div style={{ float: "right" }}>
                                <h5 className="border border-dark p-1">
                                  {tenure} years
                                </h5>
                              </div> */}
                            </div>

                            <input
                              className="w-100"
                              type="range"
                              min="1"
                              max="30"
                              value={tenure}
                              onChange={tenureChange}
                            />
                          </div>
                        </div>
                        {/* <hr /> */}
                        <div className="card-footer mb-4 mt-5" style={{ background: "#fff" }}>
                          <div >
                            <div className="row mt-4">
                              <div className="col-lg-6">
                                <div id="chart">
                                  <ReactApexChart options={options} series={series} type="pie" height="280" />


                                  {/* <ReactApexChart options={options} series={series} type="donut" style={{ width: "100%" }} /> */}
                                  {/* <div className="chart-title" style={chartTitleStyle}>
                                    <p>Loan EMI</p>
                                    <h5>{emi.toFixed(0)}</h5>
                                  </div> */}
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div style={{ background: "#25bcec2e" }} className="row mt-3">
                                  <div className="col-6">
                                    <label className="pt-2">
                                      <span>Monthly EMI: </span>
                                    </label>
                                  </div>
                                  <div className="col-6">
                                    <label className="pt-2" style={{ float: "right" }}>
                                      <span style={{ fontSize: "17px", fontWeight: 900, direction: "rtl" }}>{emi.toFixed(0)}</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <label>
                                      <span >Principal Amount: </span>
                                    </label>
                                  </div>
                                  <div className="col-6">
                                    <label style={{ float: "right" }}>
                                      <span style={{ fontSize: "17px", fontWeight: 900 }}>{principal}</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6">
                                    <label>
                                      <span>Total Interest : </span>
                                    </label>
                                  </div>
                                  <div className="col-6">
                                    <label style={{ float: "right" }}>
                                      <span style={{ fontSize: "17px", fontWeight: 900 }}>{totalInterest.toFixed(0)}</span>
                                    </label>
                                  </div>
                                </div><hr />
                                <div className="row">

                                  <div className="col-6">
                                    <label>
                                      <span>Total Payment : </span>
                                    </label>
                                  </div>
                                  <div className="col-6">
                                    <label style={{ float: "right" }}>
                                      <span style={{ fontSize: "17px", fontWeight: 900 }}>{totalPayment.toFixed(0)}</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* <div>
                            <button onClick={calculateEmi}>
                              Calculate EMI
                            </button>
                          </div> */}



                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div
            className="cta-section pad-tb bg-fixed-img mt-5"
            data-parallax="scroll"
            data-speed="0.5"
            style={{ backgroundImage: `url("images/common/wideimg.png")` }}
          // data-image-src="images/common/wideimg.png"
          >
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-lg-8">
                  <div className="cta-heading">
                    <h2
                      className="mb20 mt-2 text-w"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      Apply For Get Instant Loan
                    </h2>
                    <p className="text-w" data-aos="fade-up" data-aos-delay={300}>
                      Provides you Get Instant Loans with affordable repayment options. The applicant is not required to submit any physical documents to apply for a loan.
                    </p>
                    <a
                      href="#modal"
                      data-toggle="modal"
                      data-target="#modal_aside1"
                      className="btnpora btn-rd3 mt-2  noshadow mb-2"
                      data-aos="fade-up"
                      data-aos-delay={500}
                    >
                      {" "}
                      Get Instant Loan
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row ">
              <div style={{ padding: "0px" }} className="col-lg-1 displayst">
                {banner3 ?
                  <div>
                    <a href={banner3?.link} target="_blank">
                      <img
                        style={{ width: "100%" }}
                        src={imgUrl + banner3?.image}
                        alt="banner"
                        className="mt-2"
                      />
                    </a>
                  </div> : ""}
              </div>
              <div className="col-lg-10">
                <h3 className="mb-4 mt-4 text-center">Banking Partners</h3>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="mt-3">
                      <div className="row divrightbdr mt-3">
                        {form1.map((data) => (
                          <div className="col-md-3 mb-3">
                            <div
                              className="steps-div p-3"
                              data-aos="fade-up"
                              data-aos-delay={100}
                            >
                              <div className="row">
                                <div className="col-4 ">
                                  <img
                                    style={{ width: "65px", height: "90%" }}
                                    src={imgUrl + data.image}
                                    alt="steps"
                                  />{" "}
                                </div>
                                <div className="col-8 mt-2">
                                  <h6 className="">{data.name}</h6>
                                  <span>{data.percentage}</span>
                                  <br />
                                  <span> {data.year}</span>
                                </div>
                                {/* <div className="col-lg-2 col-4 mt-2 ">
                              <span>{data.percentage}</span>
                            </div>
                            <div className="col-4 mt-2 text-center ">
                              <span> {data.year}</span>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <section>
                        <div className="container pt-5">
                          <a href={banner.link} target="_blank">
                            <img
                              style={{ width: "100%" }}
                              src={imgUrl + banner.image}
                              alt="banner"
                            />
                          </a>
                        </div>
                      </section> */}
                    </div>
                  </div>
                </div>
              </div>


              <div style={{ padding: "0px" }} className="col-lg-1 displayst">
                {banner4 ?
                  <div>
                    <a href={banner4?.link} target="_blank">
                      <img
                        style={{ width: "100%" }}
                        src={imgUrl + banner4?.image}
                        alt="banner"
                        className="mt-2"
                      />
                    </a>
                    {/* <img style={{ width: "100%" }} src={addimg} className="mt-2" /> */}
                  </div> : ""}
              </div>
            </div>
          </div>
          {banner ?
            <section>
              <div className="pt-5">
                <a href={banner?.link} target="_blank">
                  <img
                    style={{ width: "100%" }}
                    src={imgUrl + banner?.image}
                    alt="banner"
                  />
                </a>
              </div>
            </section> : ""}

          <div className="container">
            <h3 className="mt-5 pt-2 text-center">How it works ?</h3>
            <p className="text-center mt-3 mb-5">
              Low-interest rates, No Income Proof, etc. Ensuring a Tension
              Free Loan Experience
              <br />
              With Minimal Documentation & Zero Paper Work. Flexible
              Repayment.
            </p>

            <div className="row divrightbdr mt-3">
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img1} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Online Form </h4>
                  <p>
                    Fill an online form to view the best offers from our
                    partner banks..
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img2} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Our Executive</h4>
                  <p>
                    Our executive helps you choose the best offer for your
                    requirement.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img3} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Bank </h4>
                  <p>
                    We pick up documents at your doorstep and submit to the
                    bank**.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img4} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Bank Reviews</h4>
                  <p>Bank reviews your application and confirms approval.</p>
                </div>
              </div>
            </div>
          </div>

        </section>
        {banner2 ?
          <section>
            <div className="">
              <a href={banner2?.link} target="_blank">
                <img
                  style={{ width: "100%" }}
                  src={imgUrl + banner2?.image}
                  alt="banner"
                />
              </a>
            </div>
          </section> : ""}
        {/* end banks */}
      </body>
      <Toaster />
      <Contents />
      <Footer />


      <div
        id="modal_aside1"
        className="modal fixed-center fade"
        tabIndex={-1}
      // role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Get Instant Loan</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">

              <form onSubmit={(e) => { Instatenloan(e) }}>
                <div className="row mb-4 mt-3">

                  <div className="col-md-6 mb-3">
                    <label htmlFor> Name <span className="text-danger">*</span></label>
                    <div className="">
                      <input
                        type="text"
                        pattern="^[a-zA-Z ]*$"
                        name="name"
                        className="form-control"
                        value={forms1.name}
                        onChange={(e) => { handlechange1(e) }}
                        placeholder="Name"
                        required
                      />

                    </div>
                  </div>
                  <div className="col-md-6 mb-3 ">
                    <div className="">
                      <label htmlFor> Email <span className="text-danger">*</span></label>
                      <input
                        name="email"
                        className="form-control"
                        value={forms1.email}
                        onChange={(e) => { handlechange1(e) }}
                        type="email" placeholder="Email" required />

                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 mb-3 ">
                        <div className="">
                          <label htmlFor> Mobile Number <span className="text-danger">*</span></label>
                          <input
                            type="text" pattern="[0-9]{10}"
                            maxLength={10}
                            placeholder="Mobile Number"
                            name="mobile"
                            value={forms1.mobile}
                            required
                            className="form-control"
                            onChange={(e) => {
                              handlechangephone1(e);
                            }}
                          />

                        </div>
                      </div>
                      <div className="col-md-6 mb-3 ">
                        <div className="">
                          <label htmlFor> OTP <span className="text-danger">*</span></label>
                          <input
                            name="otp"
                            value={forms1.otp}
                            onChange={(e) => {
                              handlechangeotp1(e);
                            }}
                            type="text"
                            placeholder="OTP"
                            required
                            className="form-control"
                          />

                        </div>

                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-6"></div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-6">
                            {timeshow1 == true ? (
                              <span>{time} seconds</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-6">
                            {resends1 == true ? (
                              <div
                                type="button"
                                onClick={() => {
                                  resendotps1();
                                }}
                                style={{ float: "right" }}
                                className="mb-2"
                              >
                                Resend Otp
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor> Purpose of loan <span className="text-danger">*</span></label>
                    <div>
                      <select
                        name="purposeOfLoan"
                        onChange={(e) => {
                          handlechange1(e);
                        }}
                        value={forms1.purposeOfLoan}
                        required
                        className="form-control"
                      >
                        <option value="">Purpose of loan</option>
                        <option value="Business Loan">
                          Business Loan
                        </option>
                        <option value="Personal Loan">
                          Personal Loan
                        </option>
                      </select>

                    </div>
                  </div>

                  <div className="col-md-6  mb-3">
                    <div className="">
                      <label htmlFor> Amount <span className="text-danger">*</span></label>
                      <input
                        type="number"
                        name="amount"
                        value={forms1.amount}
                        onChange={(e) => { handlechange1(e) }}
                        placeholder="Amount"
                        className="form-control"
                        required
                      />

                    </div>
                  </div>

                  <div className="row p-3">
                    {forms1.purposeOfLoan == "Business Loan" ? (
                      <>
                        <div className="col-md-6 mb-3 ">
                          <label htmlFor> IT Returns <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg, pdf)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder="It Returns"
                              required
                              className="form-control"
                              name="itReturn"
                              onChange={(e) => {
                                filechange1(e);
                              }}
                              value={Files1.itReturn}
                            />

                          </div>
                        </div>
                        <div className="col-md-6 mb-3 ">
                          <label htmlFor>Bank Statements <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg, pdf)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder=""
                              required
                              className="form-control"
                              name="bankStatement"
                              onChange={(e) => {
                                filechange10(e);
                              }}
                              value={Files10.bankStatement}
                            />

                          </div>
                        </div>
                        <div className="col-md-6  mb-3 ">
                          <label htmlFor>Aadhar Card <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder=""
                              required
                              className="form-control"
                              name="aadharCard"
                              onChange={(e) => {
                                filechange11(e);
                              }}
                              value={Files11.aadharCard}
                            />

                          </div>
                        </div>
                        <div className="col-md-6  mb-3 ">
                          <label htmlFor>Pan Card <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder=""
                              required
                              className="form-control"
                              name="panCard"
                              onChange={(e) => {
                                filechange12(e);
                              }}
                              value={Files12.panCard}

                            />

                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {forms1.purposeOfLoan == "Personal Loan" ? (
                      <>
                        <div className="col-md-6  mb-3 ">
                          <label htmlFor>Bank Statements <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg, pdf)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder=""
                              required
                              className="form-control"
                              name="bankStatement"
                              onChange={(e) => {
                                filechange10(e);
                              }}
                              value={Files10.bankStatement}
                            />

                          </div>
                        </div>
                        <div className="col-md-6  mb-3 ">
                          <label htmlFor>Aadhar Card <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder=""
                              required
                              className="form-control"
                              name="aadharCard"
                              onChange={(e) => {
                                filechange11(e);
                              }}
                              value={Files11.aadharCard}
                            />

                          </div>
                        </div>
                        <div className="col-md-6 mb-3 ">
                          <label htmlFor>Pan Card <span className="text-danger">*</span></label> <span style={{ fontSize: "11px" }} className="text-danger">(jpg, png, jpeg)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder=""
                              className="form-control"
                              required
                              name="panCard"
                              onChange={(e) => {
                                filechange12(e);
                              }}
                              value={Files12.panCard}

                            />

                          </div>
                        </div>
                        <div className="col-md-6  mb-3">
                          <label htmlFor>Salary Slips <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                          <div className="">
                            <input
                              type="file"
                              placeholder="Salary Slips"
                              className="form-control"
                              required
                              name="salarySlip"
                              onChange={(e) => {
                                filechange13(e);
                              }}
                              value={Files13.salarySlip}
                            />

                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="btn-rd123 m-3"
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    Submit{" "}
                    <i
                      class="fa fa-check-circle-o"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>



      <Modal className="modal-dialog-centered" size="sm" isOpen={open}>
        <ModalBody className="text-center">
          <img src={successimg} style={{ width: "70%" }} />
          <h5>Thank You!</h5>
          <a className="text-secondary">  Our Team Get Back To You</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>
      <Modal className="modal-dialog-centered" size="sm" isOpen={open1}>
        <ModalBody className="text-center">
          <img src={errorimg} style={{ width: "70%" }} />
          <h5 className="text-danger">Someting Went Wrong.</h5>
          <a className="text-secondary"> Please Check & Try Again</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle1}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>
      <Modal className="modal-dialog-centered" size="sm" isOpen={open2}>
        <ModalBody className="text-center">
          <img src={crossimg} style={{ width: "70%" }} />
          <h5 className="text-danger">Access Is 18+ Only</h5>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle2}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>
    </div>
    </DocumentMeta>
  );
}

export default Banks;
