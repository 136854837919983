import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { addData, addDataadmin } from "../Servicecalls";
import { baseurl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast";
import logo from "../assets/images/logo/raglogo.png";
import {Button,Modal,ModalBody} from 'reactstrap';
import successimg from '../assets/images/reviews/success.png'
import errorimg from '../assets/images/reviews/error.png'


function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [navStyles, setNavStyles] = useState({
    backgroundColor: "transparent",
    boxShadow: "none",
  });
  const [form, setform] = useState([]);
  const [form1, setform1] = useState([]);
  
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => setOpen1(!open1);

  // const getAllLoans = async () => {
  //   const bodydata = {
  //     productId: "64720173d0f054719b9b2880", //Loan
  //   };
  //   const resonse = await addData("allproduct/getallinsuranceloan", bodydata);
  //   var _data = resonse;
  //   setform1(_data.data.allProducts);
  // };

  // const getAllInsurance = async () => {
  //   const bodydata = {
  //     productId: "64720193d0f054719b9b2888", //Insurance
  //   };
  //   const resonse = await addData("allproduct/getallinsuranceloan", bodydata);
  //   var _data = resonse;
  //   setform2(_data.data.allProducts);
  // };

  // const getAllrealestate = async () => {
  //   const bodydata = {
  //     productId: "6474557f4e900cde632eea93", //Real Estate
  //   };
  //   const resonse = await addData("allproduct/getallrealestate", bodydata);
  //   var _data = resonse;
  //   setform3(_data.data.allProducts);
  // };

  // const getSubLoans = async (data) => {
  //   const bodydata = {
  //     subProductId: data._id,
  //   };
  //   const resonse = await addData("allproduct/allsubnext", bodydata);
  //   var _data = resonse;
  //   setform4(_data.data.subProduct);
  // };

  // const getSubrealestate = async (data) => {
  //   const bodydata = {
  //     subProductId: data._id, //sub loans
  //   };
  //   const resonse = await addData("allproduct/allrealestate", bodydata);
  //   var _data = resonse;
  //   setform5(_data.data.realestate);
  // };

   
  const [timeshow, settimeshow] = useState(false);
  const [resends, setresends] = useState(false);

  const [forms, setforms] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    type: "",
    opt:"",
    values: "",
    message: "",
  });
  console.log(forms);

  const [time, setTime] = useState(30);
  useEffect(() => {
    const updateTimer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(updateTimer);
  }, []);

  const handlechange = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValues1, setSelectedValues1] = useState('');
  console.log(selectedValues1)

  const handlechange123 = (e) => {
    const myForm = { ...selectedValues };
    myForm[e.target.value] = e.target.checked;
    setSelectedValues(myForm);

    const selectedParkings1 = Object.keys(myForm).filter((key) => myForm[key]);
    const parkingString1 = selectedParkings1.join(", ");
    setSelectedValues1(parkingString1);
  };

  // const sendData = () => {
  //   const formattedData = selectedValues.join(', ');
  //   setSelectedValues1(formattedData)
  //   // Here you can send the formattedData to the desired endpoint or perform further actions
  // };


  const handlechangephone = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputMobile;
    setforms(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends(true)
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
};

  const resendotps = async () => {
    const bodydata = {
      mobile: forms.mobile,
    };
  
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        settimeshow(true)
        setTime(30);
        
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
   
  };

  const handlechangeotp = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputOtp;
    setforms(myForm);

    const bodydata = {
      mobile: forms.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const getyourquote = async (e) => {
    // sendData()
    e.preventDefault();
    const bodydata = {
      name: forms.name,
      email: forms.email,
      otp: forms.otp,
      mobile: forms.mobile,
      service: forms.service,
      type: forms.type,
      values: selectedValues1,
      message: forms.message,
    };
    try {
      const resonse = await addData("expressintrest/add", bodydata);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setOpen(true)
      setSelectedValues1('')
      setSelectedValues({values: ""})
      setforms({
        name: "",
        email: "",
        mobile: "",
        service: "",
        type: "",
        otp: "",
        values: "",
        message: "",
      });
      closeModal();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };


  const closeModal = () => {
    const modalElement = document.getElementById("modal_aside_right");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
  };

  const getDetails = async () => {
    const resonse = await addDataadmin("contactus/getallactive");
    var _data = resonse;
    setform1(_data.data.contactus);
  };

  useEffect(() => {
    getDetails()
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setNavStyles({
          backgroundColor: "#fff",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        });
      } else {
        setNavStyles({ backgroundColor: "transparent", boxShadow: "none" });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const redirectLoan = (data) => {
  //   sessionStorage.setItem("loanid", data._id);
  //   navigate(`/loans/${data.name}`);
  // };

  // const redirectinsurance = (data) => {
  //   sessionStorage.setItem("insuranceid", data._id);
  //   navigate(`/insurance/${data.name}`);
  // };

  // const redirectrealsate = (data) => {
  //   sessionStorage.setItem("realestateid", data._id);
  //   navigate(`/realestatedetils/${data.title}`);
  // };

  // const redirectLoan = (data) => {
  //   sessionStorage.setItem("loanid", data._id);
  //   const slug = data.name.toLowerCase().replace(/\s+/g, '-');
  //   window.location.href = `/loans/${slug}`;
  // };
  // const redirectinsurance = (data) => {
  //   sessionStorage.setItem("insuranceid", data._id);
  //   const slug = data.name.toLowerCase().replace(/\s+/g, '-');
  //   window.location.href = `/insurance/${slug}`;
  // };
  
  // const redirectrealsate = (data) => {
  //   if (data && data.title) {
  //     sessionStorage.setItem("realestateid", data._id);
  //     const slug = data.title.toLowerCase().replace(/\s+/g, '-');
  //     window.location.href = `/realestatedetils/${slug}`;
  //   }
  // };

  return (
    <div>
      <header className="top-header th2">
        <nav
          style={navStyles}
          className="navbar navbar-expand-lg justify-content-between navbar-mobile fixed-top"
        >
          <div className="container">
            <a className="navbar-brand">
              <Link to="/">
                <img
                  src={logo}
                  alt="Logo"
                  // style={{ width: "180px" }}
                  className="white-logo logowid"
                />
              </Link>
            </a>
            <div className="hide-desk">
              <a className="mobile-btn btn-call"
              //  href="tel:8333992233"
               href={`tel:+91${form1.mobileNumber}`}
               >
                <i className="fa fa-phone" />{" "}
                <span>
                  <span className="clltxt" />
                </span>
              </a>
            </div>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbar4"
              aria-controls="navbar4"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar" />
              <span className="icon-bar middle-bar" />
              <span className="icon-bar bottom-bar" />
            </button>

            <div className="navbar-collapse collapse clearfix" id="navbar4">
              <ul className="mr-auto" />
              <ul className="navbar-nav v-center  navigation clearfix">
                <li className="nav-item">
                  {" "}
                  <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to="/about">
                    About{" "}
                  </Link>
                </li>

                {/* Lons  */}

                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === "/loan" ? "active" : ""}`} to="/loan">
                    Loans
                  </Link>
                  {/* <ul>
                    {form1.map((data) => (
                      <>
                        <li class="dropdown">
                          <a  type="button">
                            {data.subProduct}
                          </a>

                          <ul>
                            {data.subNext.map((data) => (
                              <li>
                                <a
                                  type="button"
                                  onClick={() => {
                                    redirectLoan(data);
                                  }}
                                >
                                  {data.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </>
                    ))}
                  </ul> */}
                </li>
                {/* Real Estate */}

                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === "/realestate" ? "active" : ""}`} to="/realestate">
                    Real Estate
                  </Link>

                  {/* <ul>
                    {form3.map((data) => (
                      <li class="dropdown">
                        <a
                          type="button"
                        >
                          {data.subProduct}
                        </a>
                        <ul>
                          {data.subNext.map((data) => (
                            <li>
                              <a
                               type="button"
                               onClick={() => {
                                redirectrealsate(data);
                               }}
                              >
                                {data.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul> */}
                </li>

                {/* Insurances */}

                <li className="nav-item">
                  <Link className={`nav-link ${location.pathname === "/insurances" ? "active" : ""}`} to="/insurances">
                    Insurances
                  </Link>

                  {/* <ul>
                    <>
                      {form2.map((data) => (
                        <li class="dropdown">
                          <a  type="button">
                            {data.subProduct}
                          </a>

                          <ul>
                            {data.subNext.map((data) => (
                              <li>
                                <a
                                  type="button"
                                  onClick={() => {
                                    redirectinsurance(data);
                                  }}
                                >
                                  {data.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </>
                  </ul> */}
                </li>

                {/* <li>
                  <a className="nav-link" href="#">
                    Real Estate
                  </a>
                  <ul>
                    <li class="dropdown">
                      <a href="#">Real Estate Products</a>
                      <ul>
                        <li>
                          <Link to="/realestatedetils">Flats / Apartments</Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">
                            Inpipendent House / Villas
                          </Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">
                            Residental Plots / Land
                          </Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">
                            Commercial Plots / Land
                          </Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">
                            Agriculture / Farm House
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="dropdown">
                      <a href="#">Real Estate Projects</a>
                      <ul>
                        <li>
                          <Link to="/realestatedetils">New Launching </Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">Under Constraction</Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">Ready to Move</Link>
                        </li>
                      </ul>
                    </li>
                    <li class="dropdown">
                      <a href="#">Builder Developments</a>
                      <ul>
                        <li>
                          <Link to="/realestatedetils">Indipendent Houses</Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">Villas</Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">Flats / Apartments</Link>
                        </li>
                        <li>
                          <Link to="/realestatedetils">
                            Commercial Buildings
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <a className="nav-link" href="#">
                    Insurance
                  </a>
                  <ul>
                    <li class="dropdown">
                      <a href="#">Life Insurance</a>
                      <ul>
                        <li>
                          <Link to="/banks">Term Life Insurance</Link>
                        </li>
                        <li>
                          <Link to="/banks">
                            Unit-Linked Insurance Plans(ULIP)
                          </Link>
                        </li>
                        <li>
                          <Link to="/banks">Whole Life Insurance</Link>
                        </li>
                        <li>
                          <Link to="/banks">Endowment Plans</Link>
                        </li>
                        <li>
                          <Link to="/banks">Child Plans for Education</Link>
                        </li>
                        <li>
                          <Link to="/banks">Retirment Plans</Link>
                        </li>
                      </ul>
                    </li>
                    <li class="dropdown">
                      <a href="#">General Insurance</a>
                      <ul>
                        <li>
                          <Link to="/banks">Health Insurance </Link>
                        </li>
                        <li>
                          <Link to="/banks">Automobile Insurance</Link>
                        </li>
                        <li>
                          <Link to="/banks">Homeowner's Insurance</Link>
                        </li>
                        <li>
                          <Link to="/banks">Insurance Against Fire</Link>
                        </li>
                        <li>
                          <Link to="/banks">Insurance for Travel</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li> */}

                {/* <li className="nav-item">
                  {" "}
                  <Link className="nav-link" to="/banks">
                    {" "}
                    Service
                  </Link>
                </li> */}

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#modal"
                    data-toggle="modal"
                    data-target="#modal_aside_right"
                  >
                    Contact Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link btn-call hide-mob"
                    href={`tel:+91${form1.mobileNumber}`}
                  >
                    <i className="fa fa-phone" />
                  </a>
                </li>
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link btn-call hide-mob"
                    href={`tel:+91${form1.mobileNumber}`}
                    // href="tel:8333992233"
                  >
                    <span>
                      <span className="clltxt">Happy to Help you</span> +91
                      {form1.mobileNumber}{" "}
                    </span>{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <div
        id="modal_aside_right"
        className="modal fixed-left fade"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-aside" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Express Your Interest</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="p-2" >
                <form
                  // role="form"
                  // id="contactForm"
                  // data-toggle="validator"
                  // className="shake"
                  onSubmit={(e) => {
                    getyourquote(e);
                  }}
                >
                  <div className="row">
                    <div className="form-group col-sm-12 mb-3 mt-3 ">
                      <label htmlFor> Enter name <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        pattern="^[a-zA-Z ]*$"
                        id="name"
                        name="name"
                        value={forms.name}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter name"
                        required
                        data-error="Please fill Out"
                       
                      />
                     
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                    <div className="form-group col-sm-12 mb-3 ">
                      <label htmlFor> Enter Email <span className="text-danger">*</span></label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={forms.email}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter Email"
                        required
                      />
                     

                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 mb-3 ">
                      <label htmlFor>Enter mobile <span className="text-danger">*</span></label>
                      <input
                        type="text" pattern="[0-9]{10}"
                        className="form-control"
                        maxLength={10}
                        id="mobile"
                        name="mobile"
                        value={forms.mobile}
                        onChange={(e) => {
                          handlechangephone(e);
                        }}
                        placeholder="Enter mobile"
                        required
                        data-error="Please fill Out"
                      />
                     
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                    <div className="form-group col-sm-12   mb-3">
                      <label htmlFor>Enter OTP <span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        id="mobile"
                        name="otp"
                        value={forms.otp}
                        onChange={(e) => {
                          handlechangeotp(e);
                        }}
                        placeholder="Enter OTP"
                        required
                        data-error="Please fill Out"
                      />
                     
                      {/* <div className="help-block with-errors" /> */}
                      <div className="row">
                        <div className="col-6">
                          {timeshow == true ? (
                            <span>{time} seconds</span>
                          ) : ""}
                        </div>
                        <div className="col-6">
                          {resends == true ? (
                            <div type="button" onClick={() => { resendotps() }} style={{ float: "right" }} className="mb-2">Resend Otp</div>
                          ) : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-12  mb-3">
                    <label htmlFor>Services  <span className="text-danger">*</span></label>
                      <select
                        id="Dtype"
                        name="service"
                        value={forms.service}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        className="form-control"
                        required
                      >
                        <option value="">Select Services</option>
                        <option value="Loan">Loan</option>
                        <option value="Insurances">Insurances</option>
                        <option value="Realestate">
                          Realestate & Constraction
                        </option>
                      </select>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-sm-6">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="type"
                          id="inlineRadio127"
                          defaultValue="Business"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio127"
                        >
                          Business
                        </label>
                      </div>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                    <div className=" col-sm-6">
                      <div className="form-check form-check-inline ">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="type"
                          id="inlineRadio1111"
                          defaultValue="Employee"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1111"
                        >
                          Employee
                        </label>
                      </div>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>

                  <div>
                    {forms.type == "Business" ? (
                      <div className="row mt-3">
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="3YearsITR"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio12sk"
                              style={{ width: "20px" }}
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12sk"
                            >
                              3 Years ITR
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>

                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="BusinessProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio12ksdds"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12ksdds"
                            >
                              Business Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="AddressProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio12q223"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12q223"
                            >
                              Address Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {forms.type == "Employee" ? (
                      <div className="row mt-3">
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="6MonthsPayslips"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio123"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio123"
                            >
                              6 Months Payslips
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>

                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="IdentityProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio120"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio120"
                            >
                              Identity Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="AddressProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio121"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio121"
                            >
                              Address Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group mt-1 mb-3">
                  <label htmlFor>Enter your message <span className="text-danger">*</span></label>
                    <textarea
                      id="message"
                      className="form-control"
                      name="message"
                      value={forms.message}
                      rows={3}
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      placeholder="Enter your message"
                      required
                      defaultValue={""}
                    />
                    <div className="help-block with-errors" />
                  </div>

                  <button
                    type="submit"
                    id="form-submit"
                    className="btn-rd w-100"
                  >
                    Submit
                  </button>
                  <p className="trm">
                    <i className="fa fa-lock" />
                    We hate spam, and we respect your privacy.
                  </p>
                  <div id="msgSubmit" className="h3 text-center hidden" />
                  <div className="clearfix" />
                </form>
                <div className="form-btm-set">
                  {/* <h5>We Deliver</h5> */}
                  <div className="icon-setss mt20">
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/money.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Best Price</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/quality.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Quality Service</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/call-agent.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Good Support</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/satisfaction.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Satisfaction</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />


      <Modal className="modal-dialog-centered" size="sm" isOpen={open}>
          <ModalBody className="text-center">
            <img src={successimg} style={{ width: "70%" }} />
            <h5>Thank You!</h5>
            <a className="text-secondary">  Our Team Get Back To You</a>
          </ModalBody>
          <div className="text-center">
            <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
              Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
            </Button>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered" size="sm" isOpen={open1}>
          <ModalBody className="text-center">
            <img src={errorimg} style={{ width: "70%" }} />
            <h5 className="text-danger">Someting Went Wrong.</h5>
            <a className="text-secondary"> Please Check & Try Again</a>
          </ModalBody>
          <div className="text-center">
            <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle1}>
              Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
            </Button>
          </div>
        </Modal>

    </div>
  );
}

export default Header;
