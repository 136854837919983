import React, {useState, useEffect} from 'react'
import { Link} from "react-router-dom";
import { addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import logo from "../assets/images/logo/raglogo.png"

function Footer() {
  const [form, setform] = useState({});
  const [visitorCount, setVisitorCount] = useState(0);


  const getDetails = async () => {
    const resonse = await addDataadmin("contactus/getallactive");
    var _data = resonse;
    setform(_data.data.contactus);
  };


  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return parseInt(cookie[1]);
      }
    }
    return 0;
  }

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + "/";
  }

  const trackVisit = () => {
    const visitCount = getCookie("visitCount") + 1;
    setCookie("visitCount", visitCount, 365);
    setVisitorCount(visitCount)
  }

  useEffect(() => {
    getDetails()
    trackVisit()
  }, []);

  return (
    <div>
          <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-">
                <Link to="/">
                <img src={logo} alt="Logo" style={{width:"200px"}} className="white-logo" />
                </Link>
                {/* <a href="javascript:void(0)"><img src="images/common/white-logo.png" alt="logo" /></a> */}
              </div>
              <p>{form.description}</p>
              {/* <h5 className='text-white mt-3'>Visitors : <span className='text-primary'>{visitorCount}</span></h5> */}
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="mt30 mb30 text-w">Contact Us</h4>
              <ul className="footer-address-list">
                <li><i className="fa fa-map" /> {form.address}</li>
                <li><i className="fa fa-phone" /> <a href={`tel:+91${form.mobileNumber}`}>+91 {form.mobileNumber} </a></li>
                <li><i className="fa fa-envelope" /> <a href="#"><span className="__cf_email__" data-cfemail="e58c8b838aa58790968c8b8096968b848880cb868a88"> {form.eamil}</span></a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="mt30 mb30 text-w">Keep in Touch </h4>
              <div className="footer-social-media-icons">
                <a href={form.facebooklink} target="blank" className="facebook"><i className="fa fa-facebook-f" /></a>
                <a href={form.twitterlink} target="blank" className="twitter"><i className="fa fa-twitter" /></a>
                <a href={form.instagramlink} target="blank" className="instagram"><i className="fa fa-instagram" /></a>
                <a href={form.linkedinlink} target="blank" className="linkedin"><i className="fa fa-linkedin" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div style={{padding:"0px"}} className="col-lg-4">
                <div className="footer-ft">
                  <p className='text-left footp'> Design & Develop By <a href="https://digitalraiz.com/" target="blank">Digitalraiz Creative Solutions Pvt. Ltd.</a></p>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='row'>
                  <div className='col-6'>
                  <div className="footer-ft">
                  <p className='footp'><Link className='text-white' to="/privacypolicies">Privacy Policies</Link></p> 
                    </div>
                  </div>
                  <div className='col-6'>
                  <div className="footer-ft">
                   <p className='footp'><Link className='text-white' to="/termsandconditions">Terms and Conditions</Link></p>
                    </div>

                  </div>
                </div>
              </div>
              <div  style={{padding:"0px"}} className="col-lg-4">
                <div className="footer-ft">
                  <p style={{fontSize:"12px"}} className='text-left footp'>Copyright @ <a href='https://rightagentgroup.com/' target='_blank'>LS Right Agent Services (OPC) Pvt Ltd.</a> All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer