import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Contents from './Contents'
import Header from "./Header";
import { Link } from "react-router-dom";
import { addData, addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast";
import backgroundimg from "../assets/images/agents/backgroundimg.jpg";
import addimg from "../assets/images/banners/5.png";
import Slider from "react-slick";
import DocumentMeta from 'react-document-meta';

function Loans() {
  var settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [banner, setbanner] = useState([]);
  const [banner1, setbanner1] = useState([]);
  const [banner2, setbanner2] = useState([]);
  const [banner3, setbanner3] = useState([]);
  const [banner4, setbanner4] = useState([]);

  const [form1, setform1] = useState([]);
  const [subNexts, setsubNexts] = useState([]);
  console.log(subNexts);

  const getAllbanners = async () => {
    const resonse = await addData("banner/getallloan");
    var _data = resonse;
    setbanner(_data.data.horizontal[0]);
    setbanner2(_data.data.horizontal[1]);
    setbanner3(_data.data.vertical[0]);
    setbanner4(_data.data.vertical[1]);
  };
  const getAllbanners1 = async () => {
    const resonse = await addDataadmin("pagebanners/getactive");
    var _data = resonse;
    setbanner1(_data.data.pageBanners[1]);
  };
  const [forms, setforms] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    type: "",
    values: "",
    message: "",
  });

  const handlechange = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);
  };

  const getyourquote = async (e) => {
    e.preventDefault();
    const bodydata = {
      name: forms.name,
      email: forms.email,
      mobile: forms.mobile,
      service: forms.service,
      type: forms.type,
      values: forms.values,
      message: forms.message,
    };
    try {
      const resonse = await addDataadmin("quotes/add", bodydata);
      var _data = resonse;
      console.log(_data);
      toast.success(_data.data.message);
      setforms({
        name: "",
        email: "",
        mobile: "",
        service: "",
        type: "",
        values: "",
        message: "",
      });
      closeModal();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("modal_aside");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
  };

  const getAllLoans = async () => {
    const bodydata = {
      productId: "64720173d0f054719b9b2880", //Loan
    };
    const resonse = await addData("allproduct/getallinsuranceloan", bodydata);
    var _data = resonse;
    setform1(_data.data.allProducts);
    console.log(_data.data.allProducts.map((data) => data.subNext));
    setsubNexts(_data.data.allProducts.map((data) => data.subNext));
  };

  const redirectLoan = (data) => {
    sessionStorage.setItem("loanid", data._id);
    const slug = data.name.toLowerCase().replace(/\s+/g, "-");
    window.location.href = `/loans/${slug}`;
  };

  const [form12, setform12] = useState([]);

  const getAllBanks = async () => {
    const bodydata = {
      productId: "64720173d0f054719b9b2880", //Loan
    };
    const resonse = await addDataadmin(
      "loanpartner/getloanpartnerbyproductid",
      bodydata
    );
    var _data = resonse;
    setform12(_data.data.loanPartner);
  };

  useEffect(() => {
    getAllbanners();
    getAllbanners1();
    scrollToTop();
    getAllLoans();
    getAllBanks();
  }, []);

  const meta = {
    title: 'Best loan Agency in Hyd | Right Agent Group',
    description: 'Our loan services are designed to help individuals and businesses access the funds they need to achieve their goals',
    canonical: 'https://rightagentgroup.com/loan',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'loan agents in hyderabad, personal loan agents in kompally, home loan agents in hyderabad, loan agencies in hyderabad, loan brokers in Miyapur, Best four wheeler loans Agent, Best four wheeler loans Agent in kukatpally, Best business loan Agent, Best business loan Agent in kompally, Best personal loan Agent, Best personal loan Agent bachupally, Best home loan balance tranfer Agent, Best home loan balance tranfer Agent in Gandi Maisamma, Best commercial plot loan Agent, Best commercial plot loan Agent in Miyapur, Best commerical property loan Agent, Best commerical property loan Agent in kukatpally, Best residental plots land Agent, Best residental plots land Agent in kompally, Best mortgage loan Agent, Best mortgage loan Agent in bachupally, Best loan againts property Agent, Best loan againts property Agent in Gandi Maisamma, Best home extension loan Agent, Best home extension loan Agent in Miyapur, Best top up loan Agent, Best top up loan Agent in kukatpally, Top home loan takeover top up Agent, Top home loan takeover top up Agent in kompally, Top home loan Agent, Top home loan Agent in bachupally, Top home loan for pensioners Agent, Top home loan for pensioners Agent in Gandi Maisamma, Top home loan for NRI Agent, Top home loan for NRI Agent in Miyapur, Top indipendent houses Agent, Top indipendent houses Agent in kukatpally, Best self constraction loans Agent, Best self constraction loans Agent in kompally, Best apartment flats Agent, Best apartment flats Agent in bachupally, Best houses renovation loan Agent, Best houses renovation loan Agent in Gandi Maisamma'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
    <div>
      <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="100"
        className="ct1280"
      >
        <Header />
        <section
          style={{ paddingTop: "80px" }}
          className="hero-section-1"
          id="home"
        >
          <div
            style={{
              background: `url(${imgUrl + banner1.image})`,
              opacity: 0.8,
              backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundRepeat: "no-repeat"
            }}
            className="text-center"
          >
            <div className="headst">
              <div className="text-white ">
                <h1>Loans</h1>
                <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6">
                    {" "}
                    <p style={{ fontSize: "20px", display: "none" }}>{banner1.description}</p>
                  </div>
                  <div className="col-lg-3"></div>
                </div>

                {/* <p style={{fontSize:"20px"}}>A Insurances is a debt incurred by an individual or some entity.</p> */}
              </div>
            </div>
            {/* <Link to="/">Home</Link> / <span>Loans</span> */}
          </div>
        </section>
        {/* Loan */}

        <section className="p-2" style={{ overflow: "hidden" }}>
          <div className="container">
            <div className="row">
            <div style={{ padding: "0px" }} className="col-sm-1 displayst">
                <div style={{marginTop:"25px"}}>
                {banner3?
                  <div className="">
                    <a href={banner3?.link} target="_blank">
                      <img
                         style={{ width: "100%", marginLeft: "-20px" }}
                        src={imgUrl + banner3?.image}
                        alt="banner"
                      />
                    </a>
                  </div>:""}
                  {banner4?
                  <div className="">
                    <a href={banner4?.link} target="_blank">
                      <img
                          style={{ width: "100%", marginLeft: "-20px" }}
                        src={imgUrl + banner4?.image}
                        alt="banner"
                      />
                    </a>
                  </div>:""}
                </div>
              </div>

              {/* <div style={{ padding: "0px" }} className="col-sm-1 displayst">
                <div>
                  <img className="m-4" src={addimg} style={{ width: "100%" }} />
                  <img className="m-4" src={addimg} style={{ width: "100%" }} />
                </div>
              </div> */}
              
              <div className="col-lg-10 col-md-12 planpad">
                <div>
                  <div className="row mt-2">
                    {/* {data.subNext.map((data) => ( */}
                    {form1.map((item) => item.subNext && item.subNext.map((data, key) => (
                      <div key={key} className="col-6 col-sm-4 col-md-3 col-lg-2 mb-4">
                        <div class="">
                          <div className="servicecard up-hor">
                            <a
                              onClick={() => {
                                redirectLoan(data);
                              }}
                              type="button"
                              style={{ height: "130px" }}
                            >
                              <div className="d-flex justify-content-center">
                                <img src={imgUrl + data.image} alt="icon" />
                              </div>
                              <p className="mt-3" style={{ color: "#000000" }}>
                                {data.name}
                              </p>
                              <br />
                            </a>
                          </div>
                        </div>
                      </div>
                    )))}
                    {/* ))} */}
                  </div>
                </div>

              </div>
              <div style={{ padding: "0px" }} className="col-sm-1 displayst">
                <div style={{marginTop:"25px"}}>
                {banner3?
                  <div className="">
                    <a href={banner3?.link} target="_blank">
                      <img
                         style={{ width: "100%", marginLeft: "-20px" }}
                        src={imgUrl + banner3?.image}
                        alt="banner"
                      />
                    </a>
                  </div>:""}
                  {/* <img className="mb-5" src={addimg} style={{width:"100%" , marginLeft:"-20px"}} /> */}
                  {banner4?
                  <div className="">
                    <a href={banner4?.link} target="_blank">
                      <img
                          style={{ width: "100%", marginLeft: "-20px" }}
                        src={imgUrl + banner4?.image}
                        alt="banner"
                      />
                    </a>
                  </div>:""}
                  {/* <img
                  className="mb-5"
                  src={addimg}
                  style={{ width: "100%", marginLeft: "-20px" }}
                />
                <img
                  className="mb-5"
                  src={addimg}
                  style={{ width: "100%", marginLeft: "-20px" }}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {banner?
        <section>
          <div className="">
            <a href={banner?.link} target="_blank">
              <img
                style={{ width: "100%" }}
                src={imgUrl + banner?.image}
                alt="banner"
              />
            </a>
          </div>
        </section>:""}
        <div className=" text-center mb-3 mt-3">
          <h3
            style={{ fontWeight: "900" }}
          // data-aos="fade-up"
          // data-aos-delay={100}
          >
            <a className="text-primary textbot">Banking Partners</a>
          </h3>
        </div>

        <div className="container">
          <Slider {...settings1}>
            {form12.map((data) => (
              <div className="divrightbdr mt-3">
                <div
                  className="steps-div p-3"
                // data-aos="fade-up"
                // data-aos-delay={100}
                >
                  <div className="row">
                    <div className="col-4 ">
                      <img
                        style={{ width: "65px", height: "90%" }}
                        src={imgUrl + data.image}
                        alt="steps"
                      />{" "}
                    </div>
                    <div className="col-8 mt-2">
                      <h6 className="">{data.name}</h6>
                      <span>{data.percentage}</span>
                      <br />
                      <span> {data.year}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {banner2?
        <section>
          <div className="">
            <a href={banner2?.link} target="_blank">
              <img
                style={{ width: "100%" }}
                src={imgUrl + banner2?.image}
                alt="banner"
              />
            </a>
          </div>
        </section>:""}

        <Toaster />
        <Contents />
        <Footer />
      </body>

      <div
        id="modal_aside"
        className="modal fixed-center fade"
        tabIndex={-1}
      // role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Express Your Interest</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-block border0 noshadow mt30">
                <form
                  role="form"
                  id="contactForm"
                  data-toggle="validator"
                  className="shake"
                  onSubmit={(e) => {
                    getyourquote(e);
                  }}
                >
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={forms.name}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter name"
                        required
                        data-error="Please fill Out"
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-sm-12">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={forms.email}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter email"
                        required
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        value={forms.mobile}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter mobile"
                        required
                        data-error="Please fill Out"
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <div className="form-group col-sm-12">
                      <select
                        id="Dtype"
                        name="service"
                        value={forms.service}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        className="form-control"
                        required
                      >
                        <option value>Select Services</option>
                        <option value="Loan">Loan</option>
                        <option value="Insurances">Insurances</option>
                        <option value="Realestate">
                          Realestate & Constraction
                        </option>
                      </select>
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-sm-6">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input mt-2"
                          type="radio"
                          name="type"
                          id="inlineRadio12"
                          defaultValue="Business"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio12"
                        >
                          Business
                        </label>
                      </div>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                    <div className=" col-sm-6">
                      <div className="form-check form-check-inline ">
                        <input
                          className="form-check-input mt-2"
                          type="radio"
                          name="type"
                          id="inlineRadio1"
                          defaultValue="Employee"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          Employee
                        </label>
                      </div>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>

                  <div>
                    {forms.type == "Business" ? (
                      <div className="row mt-3">
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="3YearsITR"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              id="inlineRadio12s"
                              style={{ width: "20px" }}
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12s"
                            >
                              3 Years ITR
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>

                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="BusinessProof"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              id="inlineRadio12k"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12k"
                            >
                              Business Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="AddressProof"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              id="inlineRadio12q"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12q"
                            >
                              Address Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {forms.type == "Employee" ? (
                      <div className="row mt-3">
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="6MonthsPayslips"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              id="inlineRadio123"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio123"
                            >
                              6 Months Payslips
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>

                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="IdentityProof"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              id="inlineRadio120"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio120"
                            >
                              Identity Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="AddressProof"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              id="inlineRadio121"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio121"
                            >
                              Address Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group mt-1">
                    <textarea
                      id="message"
                      className="form-control"
                      name="message"
                      value={forms.message}
                      rows={5}
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      placeholder="Enter your message"
                      required
                      defaultValue={""}
                    />
                    <div className="help-block with-errors" />
                  </div>

                  <button
                    type="submit"
                    id="form-submit"
                    className="btn-rd w-100"
                  >
                    Submit
                  </button>
                  <p className="trm">
                    <i className="fa fa-lock" />
                    We hate spam, and we respect your privacy.
                  </p>
                  <div id="msgSubmit" className="h3 text-center hidden" />
                  <div className="clearfix" />
                </form>
                <div className="form-btm-set">
                  <h5>We Deliver</h5>
                  <div className="icon-setss mt20">
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/money.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Best Price</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/quality.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Quality Service</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/call-agent.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Good Support</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/satisfaction.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Satisfaction</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </DocumentMeta>
  );
}

export default Loans;
