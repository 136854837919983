import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import HomeRealestate from "./Homerealestate";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { addData, addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast";
import successimg from '../assets/images/reviews/success.png'
import errorimg from '../assets/images/reviews/error.png'
import Contents from './Contents'
import insurance from '../assets/images/reviews/insurance.png'
import realestates from '../assets/images/reviews/realestates.png'
import loans from '../assets/images/reviews/loans.png'
import axios from "axios"
import { Button, Modal, ModalBody } from 'reactstrap';
import DocumentMeta from 'react-document-meta';

// import beautiful from "../assets/images/hero/beautiful-curly-girl.png";


function Home() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => setOpen1(!open1);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  var settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [form, setform] = useState([]);
  const [form2, setform2] = useState([]);
  const [form3, setform3] = useState([]);
  const [reviews, setreviews] = useState([]);
  const [faqs, setfaqs] = useState([]);
  const [banner, setbanner] = useState([]);
  const [banner1, setbanner1] = useState([]);
  const [banner2, setbanner2] = useState([]);
  const [banner3, setbanner3] = useState([]);
  const [homebanners, sethomebanners] = useState([]);
  const [ratings, setratings] = useState([]);


  const getAlldata = async () => {
    const resonse = await addData("dashboard");
    var _data = resonse;
    setform(_data.data.loans);
    setform2(_data.data.insurance);
    setform3(_data.data.realestate);
    // sessionStorage.setItem("loanid", _data.data.loans[0]._id);
    // sessionStorage.setItem("insuranceid",_data.data.insurance[0]._id);
    // sessionStorage.setItem("realestateid", _data.data.realestate[0]._id);
  };

  const getAllbanners = async () => {
    const resonse = await addData("banner/getallhome");
    var _data = resonse;
    setbanner(_data.data.horizontal[0]);
    setbanner1(_data.data.horizontal[1]);
    setbanner2(_data.data.vertical[0]);
    setbanner3(_data.data.vertical[1]);
  };

  const getreviewDetails = async () => {
    const resonse = await addDataadmin("review/getallactive");
    var _data = resonse;
    setreviews(_data.data.review);
  };
  const getbanners = async () => {
    const resonse = await addDataadmin("homeBanner/getactive");
    var _data = resonse;
    sethomebanners(_data.data.banner);
  };

  const getfaqDetails = async () => {
    const resonse = await addDataadmin("faq/getallactive");
    var _data = resonse;
    setfaqs(_data.data.faq);
  };

const [visit ,setvisit]=useState([])

  const getallratings = async () => {
    const resonse = await addDataadmin("rating/get");
    var _data = resonse;
    setratings(_data.data.rating);
    setvisit(_data.data.visitorCount);
  };



  useEffect(() => {
    getallratings()
    getAlldata();
    getreviewDetails();
    getfaqDetails();
    getAllbanners();
    scrollToTop();
    getbanners();
  }, []);

  const redirectLoan = (data) => {
    sessionStorage.setItem("loanid", data._id);
    const slug = data.name.toLowerCase().replace(/\s+/g, "-");
    window.location.href = `/loans/${slug}`;
  };
  const redirectinsurance = (data) => {
    sessionStorage.setItem("insuranceid", data._id);
    const slug = data.name.toLowerCase().replace(/\s+/g, "-");
    window.location.href = `/insurance/${slug}`;
  };
  const redirectrealsate = (data) => {
    sessionStorage.setItem("realestateid", data._id);
    const slug = data.title.toLowerCase().replace(/\s+/g, "-");
    window.location.href = `/realestatedetils/${slug}`;
  };


  const [timeshow, settimeshow] = useState(false);
  const [resends, setresends] = useState(false);

  const [forms, setforms] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    type: "",
    opt: "",
    values: "",
    message: "",
  });


  const [time, setTime] = useState(30);
  useEffect(() => {
    const updateTimer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(updateTimer);
  }, []);

  const handlechange = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);
  };

  const [selectedValues, setSelectedValues] = useState({ values: "" });
  const [selectedValues1, setSelectedValues1] = useState('');

  const handlechange123 = (e) => {
    const myForm = { ...selectedValues };
    myForm[e.target.value] = e.target.checked;
    setSelectedValues(myForm);

    const selectedParkings1 = Object.keys(myForm).filter((key) => myForm[key]);
    const parkingString1 = selectedParkings1.join(", ");
    setSelectedValues1(parkingString1);

    // const { value, checked } = e.target;
    // if (checked) {
    //   setSelectedValues((prevValues) => [...prevValues, value]);
    // } else {
    //   setSelectedValues((prevValues) => prevValues.filter((item) => item !== value));
    // }
  };

  // const sendData = () => {
  //   const formattedData = selectedValues.join(', ');
  //   setSelectedValues1(formattedData)
  // };

  // get you qoute 

  const handlechangephone = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputMobile;
    setforms(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends(true)
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps = async () => {
    const bodydata = {
      mobile: forms.mobile,
    };

    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow(true)
      setTime(30);

    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }

  };

  const handlechangeotp = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputOtp;
    setforms(myForm);

    const bodydata = {
      mobile: forms.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const getyourquote = async (e) => {
    // sendData()
    e.preventDefault();
    const bodydata = {
      name: forms.name,
      email: forms.email,
      otp: forms.otp,
      mobile: forms.mobile,
      service: forms.service,
      type: forms.type,
      values: selectedValues1,
      message: forms.message,
    };
    try {
      const resonse = await addData("expressintrest/add", bodydata);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setSelectedValues1('')
      setSelectedValues({ values: "" })
      setforms({
        name: "",
        email: "",
        mobile: "",
        service: "",
        type: "",
        otp: "",
        message: "",
      });
      closeModal();
      setOpen(true)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("modal_aside2");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
  };

  // Become a partner

  const [forms1, setforms1] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    opt: "",
    message: "",
  });

  const handlechange1 = (e) => {
    const myForm = { ...forms1 };
    myForm[e.target.name] = e.target.value;
    setforms1(myForm);
  };

  const handlechangephone1 = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputMobile;
    setforms1(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends(true)
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps1 = async () => {
    const bodydata = {
      mobile: forms1.mobile,
    };

    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow(true)
      setTime(30);

    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }

  };

  const handlechangeotp1 = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputOtp;
    setforms1(myForm);

    const bodydata = {
      mobile: forms1.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const getyourquote1 = async (e) => {
    e.preventDefault();
    const bodydata = {
      name: forms1.name,
      email: forms1.email,
      otp: forms1.otp,
      mobile: forms1.mobile,
      service: forms1.service,
      message: forms1.message,
    };
    try {
      const resonse = await addDataadmin("becomepartner/add", bodydata);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setSelectedValues1('')
      setforms1({
        name: "",
        email: "",
        mobile: "",
        service: "",
        otp: "",
        message: "",
      });
      closeModal1();
      setOpen(true)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const closeModal1 = () => {
    const modalElement = document.getElementById("modal_aside1");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
  };


  const [customers, setCustomers] = useState(0);
  const [loanDisbursed, setLoanDisbursed] = useState(0);
  const [visitors, setVisitors] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);
  const [loanAdvisors, setLoanAdvisors] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the values until they reach the desired values
      if (customers < ratings.happyCustomers) {
        setCustomers(customers + 1);
      }
      if (loanDisbursed < ratings.loanDistribution) {
        setLoanDisbursed(loanDisbursed + 1);
      }
      if (visitors < visit) {
        setVisitors(visitors + 1);
      }
      if (serviceRating < ratings.serviceRating) {
        setServiceRating(serviceRating + 0.1);
      }
      if (loanAdvisors < ratings.loanAdviser) {
        setLoanAdvisors(loanAdvisors + 1);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [customers, loanDisbursed, visitors, serviceRating, loanAdvisors, ratings]);


  //creating IP state
  const [ip, setIP] = useState('');


  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
    setIP(res.data.IPv4)

    const ds = {
      address: res.data.IPv4
    }
    axios
      .post(
        'https://api.rightagentgroup.com/website/addvisitoraddress',
        ds,
        {
          // headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            console.log("ok")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            console.log("Not ok")
          }
        }
      )
  }



  useEffect(() => {
    //passing getData method to the lifecycle method
    getData()
  }, [])

  const meta = {
    title: 'Best loan insurance real estate Agency in Hyd | Right Agent Group',
    description: 'At RightAgentGroup pride ourselves on being a service-oriented company that provides financial services Our core offerings include loans, real estate services, and insurance.',
    canonical: 'https://rightagentgroup.com',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'insurance agents in hyderabad, top 10 health insurance agent in hyderabad, top 10 insurance agent in kompally, life insurance agent in Miyapur, insurance agents in bachupally, loan agents in hyderabad, personal loan agents in kompally, home loan agents in hyderabad, loan agencies in hyderabad, loan brokers in Miyapur, real estate agency in hyderabad, top 10 real estate agents in kompally, best real estate agents in hyderabad, property agents in bachupally, top real estate agents in Miyapur, commercial rental agencies in hyderabad'
      }
    }
  };



  return (
    <DocumentMeta {...meta}>
    <div>
      <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="100"
        className="ct1280"
      >
        <Header />
        {/* home page */}
        <section className="hero-section-1  agency-bg" id="home">
          <div className="blur-bg-blocks">
            <aside className="blur-bg-set">
              <div className="blur-bg blur-bg-a" />
              <div className="blur-bg blur-bg-b" />
              <div className="blur-bg blur-bg-c" />
            </aside>
          </div>
          <div className="container">
            <div
              id="carouselExampleInterval"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                {homebanners.map((data, key) => (
                  <div
                    key={key}
                    className={`carousel-item ${key === 0 ? "active" : ""}`}
                    data-interval={4000}
                  >
                    <div className="row justify-content-between">
                      <div className="col-lg-6 v-center">
                        <div className="header-heading-1 mt-5">
                          <h1 className="mb30" data-aos="zoom-out-up">
                            <span className="fw3">{data.name}</span>

                          </h1>
                          <p style={{fontSize:"15px"}} data-aos="zoom-out-up" data-aos-delay={400}>
                            {data.description}
                          </p>
                          <a
                            href="#modal"
                            data-toggle="modal"
                            data-target="#modal_aside2"
                            className="btnpora btn-rd2 mt30 mb-3"
                            data-aos="zoom-out-up"
                            data-aos-delay={600}
                          >
                            Get your Quote
                          </a>
                        </div>
                        {/* <div
                          className="hero-feature"
                          data-aos="zoom-out-up"
                          data-aos-delay={800}
                        >
                        
                        </div> */}
                      </div>
                      <div className="col-lg-6 displayst">
                        <div
                          className="img-box m-mt60 text-center mt-5"
                          data-aos="fade-In"
                          data-aos-delay={400}
                          data-aos-duration={3000}
                        >
                          <img
                            src={imgUrl + data.image}
                            alt="car"
                            className="img-fluid displayst"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <button
                style={{ width: "5%" }}
                className="carousel-control-prev"
                type="button"
                data-target="#carouselExampleInterval"
                data-slide="prev"
              >
                <i
                  className="fa fa-long-arrow-left carousalbtn"
                  aria-hidden="true"
                />
                <span className="sr-only">Previous</span>
              </button>
              <button
                style={{ width: "5%" }}
                className="carousel-control-next"
                type="button"
                data-target="#carouselExampleInterval"
                data-slide="next"
              >
                <i
                  className="fa fa-long-arrow-right carousalbtn"
                  aria-hidden="true"
                />
                <span className="sr-only">Next</span>
              </button>
            </div>
          </div>
        </section>

        <section className="mt-3" style={{ overflow: "hidden" }}>
          <div className="container">
            <div className="text-center">
              <h3
                className=""
                style={{ fontWeight: "900" }}
              // data-aos="fade-up"
              // data-aos-delay={100}
              >
                <a className="textbot text-dark">
                  {" "}
                  Our <span className="text-primary ">Loans</span>
                </a>
              </h3>
            </div>

            <div className="row mb-5 mt-5">
              <div className="col-12">
                <div className="">
                  <Slider {...settings1}>
                    {form.map((data) => (
                      <div className="servicecard up-hor">
                        <a
                          onClick={() => {
                            redirectLoan(data);
                          }}
                          type="button"
                          style={{ height: "130px" }}
                        >
                          <div className="d-flex justify-content-center">
                            <img src={imgUrl + data.image} alt="icon" />
                          </div>
                          <p className="mt-3" style={{ color: "#000000" }}>
                            {data.name}
                          </p>
                          <br />
                        </a>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          {banner?
          <section>
            <div className="">
              <a href={banner?.link} target="_blank">
                <img
                  style={{ width: "100%" }}
                  src={imgUrl + banner?.image}
                  alt="banner"
                />
              </a>
            </div>
          </section>:""}

          <div className="container">
            <div className=" text-center mt-5 ">
              <h3
                style={{ fontWeight: "900" }}
              // data-aos="fade-up"
              // data-aos-delay={100}
              >
                <a className="textbot text-dark">
                  {" "}
                  Our <span className="text-primary ">Insurances</span>
                </a>
              </h3>
            </div>

            <div className="row  mb-5 mt-5">
              <div className="col-12">
                <div className="">
                  <Slider {...settings1}>
                    {form2.map((data) => (
                      <div className="servicecard up-hor">
                        <a
                          type="button"
                          style={{ height: "130px" }}
                          onClick={() => {
                            redirectinsurance(data);
                          }}
                        >
                          <div className="d-flex justify-content-center">
                            <img src={imgUrl + data.image} alt="icon" />
                          </div>
                          <p style={{ color: "#000000" }}>{data.name}</p>
                        </a>
                      </div>
                    ))}

                    {/* <div className="servicecard text-center  up-hor">
                      <Link to="/banks">
                        <div className="d-flex justify-content-center">
                          <img src="images/icons/family.png" alt="icon" />
                        </div>
                        <p>
                          Family Health
                          <br /> Insurance
                        </p>
                      </Link>
                    </div>
                    <div className="servicecard up-hor">
                      <Link to="/banks">
                        <div className="d-flex justify-content-center">
                          <img src="images/icons/heart.png" alt="icon" />
                        </div>
                        <p>
                          Health
                          <br /> Insurance
                        </p>
                      </Link>
                    </div>
                    <div className="servicecard up-hor">
                      <Link to="/banks">
                        <div className="d-flex justify-content-center">
                          <img src="images/icons/health-care.png" alt="icon" />
                        </div>
                        <p>
                          Life
                          <br /> Insurance{" "}
                        </p>
                      </Link>
                    </div>
                    <div className="servicecard up-hor">
                      <Link to="/banks">
                        <div className="d-flex justify-content-center">
                          <img src="images/icons/bycicle.png" alt="icon" />
                        </div>
                        <p>
                          2 Wheeler
                          <br /> Insurance
                        </p>
                      </Link>
                    </div>
                    <div className="servicecard up-hor">
                      <Link to="/banks">
                        <div className="d-flex justify-content-center">
                          <img src="images/icons/car.png" alt="icon" />
                        </div>
                        <p>
                          Car
                          <br /> Insurance
                        </p>
                      </Link>
                    </div>
                    <div className="servicecard up-hor">
                      <Link to="/banks">
                        <div className="d-flex justify-content-center">
                          <img src="images/icons/invest.png" alt="icon" />
                        </div>
                        <p>
                          General
                          <br /> Insurance
                        </p>
                      </Link>
                    </div>
                    <div className="servicecard up-hor">
                      <Link to="/banks">
                        <div className="d-flex justify-content-center">
                          <img src="images/icons/homeloan.png" alt="icon" />
                        </div>
                        <p>
                          Home
                          <br /> Insurance{" "}
                        </p>
                      </Link>
                    </div> */}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact */}
        {banner1?
        <section >
          <div className="">
            <a href={banner1?.link} target="_blank">
              <img
                style={{ width: "100%" }}
                src={imgUrl + banner1?.image}
                alt="banner"
              />
            </a>
          </div>
        </section>:''}


        <HomeRealestate />

        <div className="enquire-form pad-tb pora-bg1 text-white" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cta-heading text-center">
                  <span
                    className="subhead"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    Why Choose Right Agent Group
                  </span>
                  <h3 className="mb-3" data-aos="fade-up" data-aos-delay={300}>
                  In an industry where trust, expertise, and reliability are paramount, 
                  choosing the right agent group can make all the difference in achieving your goals
                  </h3>
                </div>

                <div className="row">
                  <div className="col col-lg-4">
                    <div
                      className="whyusbox"
                      data-aos="fade-In"
                      data-aos-delay={100}
                    >
                      <Link to="/loan">
                        <div className="imgbdr shadows">

                          <img src={loans} alt="icon" />{" "}
                        </div>
                      </Link>
                      <p>Loans</p>
                    </div>
                  </div>
                  <div className="col col-lg-4">
                    <div
                      className="whyusbox"
                      data-aos="fade-In"
                      data-aos-delay={200}
                    >
                      <Link to="/realestate">
                        <div className="imgbdr shadows">
                          {" "}
                          <img src={realestates} alt="icon" />{" "}
                        </div>
                      </Link>
                      <p>Real Estate</p>
                    </div>
                  </div>
                  <div className="col col-lg-4">
                    <div
                      className="whyusbox"
                      data-aos="fade-In"
                      data-aos-delay={300}
                    >
                      <Link to="/insurances">
                        <div className="imgbdr shadows">
                          {" "}
                          <img src={insurance} alt="icon" />{" "}
                        </div>
                      </Link>
                      <p>Insurance</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* About */}
        {/* <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-6 v-center">
                <div className="about-company">
                  <h2 className="mb20" data-aos="fade-up" data-aos-delay={100}>
                    Save <em>Upto 90%</em> with Best{" "}
                    <span className="fw3">
                      Insurance Plans offered by insurers
                    </span>
                  </h2>
                  <p data-aos="fade-up" data-aos-delay={300}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                  <a
                    href="#"
                    className="btnpora btn-rd2 mt40"
                    data-aos="fade-up"
                    data-aos-delay={600}
                    data-toggle="modal"
                    data-target="#modal_aside"
                  >
                    Get your Quote
                  </a>
                </div>
              </div>
              <div className="col-lg-6 v-center">
                <div
                  className="img-box1 m-mt60"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <img
                    src="images/common/agent.png"
                    alt="feature-image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Realestate and Constraction */}

        {/* <section className="reviews-section pad-tb review-bg" id="review">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6">
                <div className="common-heading">
                  <h2 className="mb20" data-aos="fade-up" data-aos-delay={100}>
                    Real Estate & Constraction
                  </h2>
                  <p data-aos="fade-up" data-aos-delay={300}>
                    The real estate and construction industry is under pressure
                    to change on many fronts: urbanisation and re-urbanisation
                    are constantly changing the rules investment cycles and
                    capital market movements.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt60">
              <div className="col-lg-12">
                <div className="owl-review-rtl owl-carousel mt45">
                  <Slider {...settings2}>
                    {form3.map((data) => (
                      <div className="reviews-card up-hor">
                        <img
                          src={imgUrl + data.flatImages[0]}
                          style={{ width: "100%" }}
                          alt="review"
                        />
                        <div className="p-4">
                          <h5 className="text-left">{data.title} </h5>
                          <p className="text-left text-dark mt-2">
                            {data.description.length > 70
                              ? `${data.description.substring(0, 70)}...`
                              : data.description}
                          </p>
                        </div>
                        <hr className="p-0 m-0" />
                        <div className="row p-0">
                          {data.features.slice(0, 3).map((data) => (
                            <div className="col-4">
                              <div class="form-group form-checkp-0 m-0">
                                <div className="d-flex justify-content-center">
                                  <img
                                    src={imgUrl + data.image}
                                    style={{ width: "30px" }}
                                  />
                                </div>
                                <p
                                  class="realestatefeatures"
                                  for="exampleCheck1"
                                >
                                  <br />
                                  {data.title}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <hr style={{ marginTop: "0p" }} />

                        <button
                          onClick={() => {
                            redirectrealsate(data);
                          }}
                          className="btn-rd123 mb-3"
                          style={{ width: "80%" }}
                        >
                          View More
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section>
          <div className="">
            <a href={banner1?.link} target="_blank">
              <img
                style={{ width: "100%" }}
                src={imgUrl + banner1?.image}
                alt="banner"
              />
            </a>
          </div>
        </section> */}

        {/* Partners */}
        {/* <section className="about-bg pad-tb" id="partners">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6 v-center">
                <div className="partner-company">
                  <h2 className="mb20" data-aos="fade-up" data-aos-delay={100}>
                    Our <em>Partners</em>
                  </h2>
                  <p data-aos="fade-up" data-aos-delay={100}>
                    We collaborate with the best and biggest in the banking
                    &amp; financial Lorem Ipsum has been the industry's standard
                    dummy text.
                  </p>
                </div>
                <div
                  className="partnerlogo mt40"
                  data-aos="fade-In"
                  data-aos-delay={500}
                >
                  <a href="#">
                    <img src="images/logo/uilogos-1.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-2.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-3.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-4.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-5.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-6.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-7.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-8.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-9.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-10.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-11.png" alt="brand logo" />{" "}
                  </a>
                  <a href="#">
                    <img src="images/logo/uilogos-12.png" alt="brand logo" />{" "}
                  </a>
                </div>
              </div>
              <div className="col-lg-5 v-center">
                <div
                  className="img-box1 m-mt60"
                  data-aos="fade-In"
                  data-aos-delay={100}
                >
                  <img
                    src="images/common/parntership.png"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/*  */}
        <div className="enquire-form pad-tb bgbefore pt-5 pb-5" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg text-center">
                <h5 className="archivecount" style={{ color: "#ff7400" }}>{customers}</h5>
                <p className="text-dark"><b style={{ color: "#000" }}>Happy Customers</b></p>
              </div>
              <div className="col-lg text-center">
                <h5 className="archivecount" style={{ color: "#ff7400" }}>{loanDisbursed}</h5>
                <p><b style={{ color: "#000" }}>Crore Loan Disbursed</b></p>
              </div>
              <div className="col-lg text-center">
                <h5 className="archivecount" style={{ color: "#ff7400" }}>{visitors}</h5>
                <p><b style={{ color: "#000" }}>Visitors</b></p>
              </div>
              <div className="col-lg text-center">
                <h5 className="archivecount" style={{ color: "#ff7400" }}>{serviceRating.toFixed(1)} +</h5>
                <p><b style={{ color: "#000" }}>Our Service Rating</b></p>
              </div>
              <div className="col-lg text-center">
                <h5 className="archivecount" style={{ color: "#ff7400" }}>{loanAdvisors} + </h5>
                <p><b style={{ color: "#000" }}>Loan Advisor across India</b></p>
              </div>
            </div>
          </div>
        </div>

        <section className="step-bg pt50 pb80">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 v-center">
                <div className="common-heading m-text-c pr50">
                  <h2 className="mb20" data-aos="fade-up" data-aos-delay={100}>
                  Services Provided by <em>Right Agent Group</em> 
                  {/* <em>personal credit</em> products on{" "}
                    <span className="fw3">Right Agent Group</span> */}
                  </h2>
                  <p data-aos="fade-up" data-aos-delay={100}>
                  At Right Agent Group, we understand the importance of finding
                   the right professionals to guide you through your real estate journey, 
                   secure a loan that suits your needs,
                   and protect your investments with comprehensive insurance coverage.

                
                  </p>
                  <p>   In addition to our real estate services, Right Agent Group also offers
                    an array of other services to cater to all your financial needs</p>
                </div>
              </div>
              <div className="col-lg-7 v-center m-mt60">
                <div className="row divrightbdr">
                  <div className="col-lg-6">
                    <div
                      className="steps-div  mt30"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img src="images/icons/choice.png" alt="steps" />{" "}
                      </div>
                      <h4 className="mb10">Real-Estate</h4>
                      <p>
                      we specialize in connecting sellers with buyers through our real estate services.
                       We understand that buying or selling a property is a significant
                       financial decision, and we believe in making this process as smooth as possible. 
                      </p>
                    </div>
                    <div
                      className="steps-div mt30"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img
                          src="images/icons/credit-card.png"
                          alt="steps"
                        />{" "}
                      </div>
                      <h4 className="mb10">Loans</h4>
                      <p>
                      Our team of loan experts can assist you in securing the right loan for your needs, whether it's a mortgage for purchasing a property or refinancing an existing loan. 
                      We understand that the loan process can be overwhelming, which is why we guide
                       you through every step and provide personalized solutions tailored to your financial situation. 
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mt60 m-m0">
                    <div
                      className="steps-div mt30"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img src="images/icons/security.png" alt="steps" />{" "}
                      </div>
                      <h4 className="mb10">Insurance</h4>
                      <p>
                      Offering Comprehensive Insurance Options Our insurance services are designed 
                      to provide our clients with comprehensive coverage and peace of mind. We offer a 
                      range of insurance options, including home, auto, life, and more. 
                      {/* Our team of insurance specialists will work
                       with you to assess your specific insurance needs and find the best coverage options for you. */}
                      </p>
                    </div>
                    <div
                      className="steps-div mt30"
                      data-aos="fade-up"
                      data-aos-delay={500}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img
                          src="images/icons/customers.png"
                          alt="steps"
                        />{" "}
                      </div>
                      <h4 className="mb10">Our Mission</h4>
                      <p>
                      At Right Agent Group, our mission is to deliver exceptional services that exceed your expectations. We aim to create a seamless and stress-free experience for our clients by providing 
                      them with expert guidance, unparalleled market knowledge, and innovative solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="cta-section pad-tb bg-fixed-img"
          data-parallax="scroll"
          data-speed="0.5"
          style={{ backgroundImage: `url("images/common/wideimg.png")` }}
        // data-image-src="images/common/wideimg.png"
        >
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-8">
                <div className="cta-heading">
                  <h2
                    className="mb20 text-w"
                    data-aos="fade-up"
                    data-aos-delay={100}
                  >
                    Request a Free Consultation!
                  </h2>
                  <p className="text-w" data-aos="fade-up" data-aos-delay={300}>
                  Welcome to Right Agent Group, a service-oriented company that provides exceptional 
                  assistance in the real estate, loans, and insurance fields.
                  </p>
                  <a
                    href="#modal"
                    data-toggle="modal"
                    data-target="#modal_aside1"
                    className="btnpora btn-rd3 mt40 noshadow mb-5"
                    data-aos="fade-up"
                    data-aos-delay={500}
                  >
                    {" "}
                    Become a Partner
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="agent-section pad-tb" id="agent">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6">
                <div className="common-heading">
                  <h2 className="mb20" data-aos="fade-up" data-aos-delay={100}>
                    Meet The <em>Agents</em>
                  </h2>
                  <p data-aos="fade-up" data-aos-delay={300}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt30">
              <div
                className="col-lg-3 col-6 mt30"
                data-aos="fade-In"
                data-aos-delay={100}
              >
                <div className="full-image-card hover-scale">
                  <div className="image-div">
                    <a href="#">
                      <img
                        src="images/agents/team-1.jpg"
                        alt="team"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="info-text-block">
                    <h5>
                      <a href="#">Shakita Daoust</a>
                    </h5>
                    <p>Insurance Agent</p>
                    <div className="social-links-">
                      <a href="#" target="blank">
                        <i className="fab fa-facebook-f" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-twitter" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-linkedin-in" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-6 mt30"
                data-aos="fade-In"
                data-aos-delay={300}
              >
                <div className="full-image-card hover-scale">
                  <div className="image-div">
                    <a href="#">
                      <img
                        src="images/agents/team-2.jpg"
                        alt="team"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="info-text-block">
                    <h5>
                      <a href="#">Gerard Licari</a>
                    </h5>
                    <p>Insurance Agent</p>
                    <div className="social-links-">
                      <a href="#" target="blank">
                        <i className="fab fa-facebook-f" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-twitter" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-linkedin-in" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-6 mt30"
                data-aos="fade-In"
                data-aos-delay={500}
              >
                <div className="full-image-card hover-scale">
                  <div className="image-div">
                    <a href="#">
                      <img
                        src="images/agents/team-3.jpg"
                        alt="team"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="info-text-block">
                    <h5>
                      <a href="#">Cary Montgomery</a>
                    </h5>
                    <p>Insurance Agent</p>
                    <div className="social-links-">
                      <a href="#" target="blank">
                        <i className="fab fa-facebook-f" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-twitter" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-linkedin-in" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-6 mt30"
                data-aos="fade-In"
                data-aos-delay={700}
              >
                <div className="full-image-card hover-scale">
                  <div className="image-div">
                    <a href="#">
                      <img
                        src="images/agents/team-4.jpg"
                        alt="team"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  <div className="info-text-block">
                    <h5>
                      <a href="#">Herman Running</a>
                    </h5>
                    <p>Insurance Agent</p>
                    <div className="social-links-">
                      <a href="#" target="blank">
                        <i className="fab fa-facebook-f" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-twitter" />{" "}
                      </a>
                      <a href="#" target="blank">
                        <i className="fab fa-linkedin-in" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Review */}
        <section className="reviews-section pad-tb review-bg2" id="review">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="comon-heading">
                  <h2 className="mb20">
                    Our <em>Happy</em> Customers
                  </h2>
                  {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p> */}
                </div>
                <h4 className="mt40">
                  Thousands of unbiased reviews. Trusted by 10 Thousand+
                  customers.
                </h4>
                <ul className="overallrating mt20">
                  <li>
                    <a href="#" className="chked">
                      <i className="fa fa-star" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="chked">
                      <i className="fa fa-star" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="chked">
                      <i className="fa fa-star" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="chked">
                      <i className="fa fa-star" aria-hidden="true" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="chked">
                      <i className="fa fa-star-half-o" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="reviews-block owl-carousel m-mt60">
                  <Slider {...settings}>
                    {reviews.map((data) => (
                      <div style={{ backgroundColor: "#fff" }} className="reviews-card p-4">
                        <div className="-client-details-">
                          <div className="-reviewr">
                            <img
                              src={imgUrl + data.image}
                              alt="Good Review"
                              className="img-fluid rounded-circle"
                            />
                          </div>
                          <div className="reviewer-text">
                            <h5>{data.customerName}</h5>
                            <p>{data.designation}</p>
                            <div className="star-rate">
                              <ul>
                                {data.rating === "1" ? (
                                  <li>
                                    {" "}
                                    <a href="#" className="chked">
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      />
                                    </a>{" "}
                                  </li>
                                ) : (
                                  ""
                                )}
                                {data.rating === "1.5" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i className="fa fa-star-half-o" />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                {data.rating === "2" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                {data.rating === "2.5" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i className="fa fa-star-half-o" />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                {data.rating === "3" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                {data.rating === "3.5" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i className="fa fa-star-half-o" />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                {data.rating === "4" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                {data.rating === "4.5" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i className="fa fa-star-half-o" />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                {data.rating === "5" ? (
                                  <>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                    <li>
                                      {" "}
                                      <a href="#" className="chked">
                                        <i
                                          className="fa fa-star"
                                          aria-hidden="true"
                                        />
                                      </a>{" "}
                                    </li>
                                  </>
                                ) : (
                                  ""
                                )}
                                <li>
                                  {" "}
                                  <a href="#">{data.rating}</a>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="review-text pb0 pt30">
                          <p>
                            {data.review}
                            {/* Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. */}
                          </p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="faq-section">
          <div className="container">
            <div className="row ">
              <div className="col-lg-2">
              {banner2?
                <section>
                  <div className="displayst p-3">
                    <a href={banner2?.link} target="_blank">
                      <img
                        style={{ width: "100%" }}
                        src={imgUrl + banner2?.image}
                        alt="banner"
                      />
                    </a>
                  </div>
                </section>:""}
              </div>
              <div className="col-lg-8 justify-content-center mt30  pad-tb ">
                <div className="common-heading text-center mb-4">
                  <h2 data-aos="fade-up " data-aos-delay={100}>
                    Frequently Asked Questions
                  </h2>
                </div>

                <div id="accordion3" className="accordion">
                  {faqs.map((data, index) => (
                    <div key={index} className="card-2 mt10">
                      <div className="card-header" id="acc1">
                        <button
                          className="btn btn-link btn-block text-left acc-icon collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target={`#collapse-${index}`}
                          aria-expanded="false"
                          aria-controls="collapse-1"
                          style={{fontSize:'16px'}}
                        >
                          {data.question}
                        </button>
                      </div>
                      <div
                        id={`collapse-${index}`}
                        className="card-body p0 collapse"
                        aria-labelledby="acc1"
                        data-parent="#accordion3"
                      >
                        <div className="data-reqs">
                          <p>{data.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-2">
              {banner3?
                <section>
                  <div className="displayst p-3">
                    <a href={banner3?.link} target="_blank">
                      <img
                        style={{ width: "100%" }}
                        src={imgUrl + banner3?.image}
                        alt="banner"
                      />
                    </a>
                  </div>
                </section>:""}
              </div>
            </div>
          </div>
        </section>

        <div
          id="modal_aside2"
          className="modal fixed-center fade"
          tabIndex={-1}
        // role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Express Your Interest</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div >
                  <form
                    // role="form"
                    // id="contactForm"
                    // data-toggle="validator"
                    // className="shake"
                    onSubmit={(e) => {
                      getyourquote(e);
                    }}
                  >
                    <div className="row">
                      <div className="form-group col-sm-12 mb-3 mt-3 ">
                        <label htmlFor> Enter name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          pattern="^[a-zA-Z ]*$"
                          id="name"
                          name="name"
                          value={forms.name}
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          placeholder="Enter name"
                          required
                          data-error="Please fill Out"
                        />
                     
                    
                      </div>
                      <div className="form-group col-sm-12 mb-3 ">
                        <label htmlFor> Enter Email <span className="text-danger">*</span></label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={forms.email}
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          placeholder="Enter email"
                          required
                        />
                     

                        {/* <div className="help-block with-errors" /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-sm-12 mb-3">
                        <label htmlFor>Enter mobile <span className="text-danger">*</span></label>
                        <input
                          type="text" pattern="[0-9]{10}"
                          className="form-control"
                          maxLength={10}
                          id="mobile"
                          name="mobile"
                          value={forms.mobile}
                          onChange={(e) => {
                            handlechangephone(e);
                          }}
                          placeholder="Enter mobile"
                          required
                          data-error="Please fill Out"
                        />
                     
                        {/* <div className="help-block with-errors" /> */}
                      </div>
                      <div className="form-group col-sm-12   mb-3">
                        <label htmlFor>Enter OTP <span className="text-danger">*</span></label>
                        <input
                          type="number"
                          className="form-control"
                          id="mobile"
                          name="otp"
                          value={forms.otp}
                          onChange={(e) => {
                            handlechangeotp(e);
                          }}
                          placeholder="Enter OTP"
                          required
                          data-error="Please fill Out"
                        />
                     
                        {/* <div className="help-block with-errors" /> */}
                        <div className="row">
                          <div className="col-6">
                            {timeshow == true ? (
                              <span>{time} seconds</span>
                            ) : ""}
                          </div>
                          <div className="col-6">
                            {resends == true ? (
                              <div type="button" onClick={() => { resendotps() }} style={{ float: "right" }} className="mb-2">Resend Otp</div>
                            ) : ""}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-sm-12  select-data mb-3">
                      <label htmlFor>Services <span className="text-danger">*</span></label>
                        <select
                          id="Dtype"
                          name="service"
                          value={forms.service}
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          className="form-control"
                          required
                        >
                          <option value="">Select Services</option>
                          <option value="Loan">Loan</option>
                          <option value="Insurances">Insurances</option>
                          <option value="Realestate">
                            Realestate & Constraction
                          </option>
                        </select>
                        {/* <div className="help-block with-errors" /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-sm-6">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="type"
                            id="inlineRadio127"
                            defaultValue="Business"
                            onChange={(e) => {
                              handlechange(e);
                            }}
                            required
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio127"
                          >
                            Business
                          </label>
                        </div>
                        {/* <div className="help-block with-errors" /> */}
                      </div>
                      <div className=" col-sm-6">
                        <div className="form-check form-check-inline ">
                          <input
                            className="form-check-input "
                            type="radio"
                            name="type"
                            id="inlineRadio1111"
                            defaultValue="Employee"
                            onChange={(e) => {
                              handlechange(e);
                            }}
                            required
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1111"
                          >
                            Employee
                          </label>
                        </div>
                        {/* <div className="help-block with-errors" /> */}
                      </div>
                    </div>

                    <div>
                      {forms.type == "Business" ? (
                        <div className="row mt-3">
                          <div className=" col-sm-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                name="values"
                                value="3YearsITR"
                                onChange={(e) => {
                                  handlechange123(e);
                                }}
                                id="inlineRadio12sk"
                                style={{ width: "20px" }}
                                defaultValue="option2"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio12sk"
                              >
                                3 Years ITR
                              </label>
                            </div>
                            {/* <div className="help-block with-errors" /> */}
                          </div>

                          <div className=" col-sm-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                name="values"
                                value="BusinessProof"
                                onChange={(e) => {
                                  handlechange123(e);
                                }}
                                id="inlineRadio12ksdds"
                                defaultValue="option2"
                                style={{ width: "20px" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio12ksdds"
                              >
                                Business Proof
                              </label>
                            </div>
                            {/* <div className="help-block with-errors" /> */}
                          </div>
                          <div className=" col-sm-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                name="values"
                                value="AddressProof"
                                onChange={(e) => {
                                  handlechange123(e);
                                }}
                                id="inlineRadio12q223"
                                defaultValue="option2"
                                style={{ width: "20px" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio12q223"
                              >
                                Address Proof
                              </label>
                            </div>
                            {/* <div className="help-block with-errors" /> */}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      {forms.type == "Employee" ? (
                        <div className="row mt-3">
                          <div className=" col-sm-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                name="values"
                                value="6MonthsPayslips"
                                onChange={(e) => {
                                  handlechange123(e);
                                }}
                                id="inlineRadio123"
                                style={{ width: "20px" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio123"
                              >
                                6 Months Payslips
                              </label>
                            </div>
                            {/* <div className="help-block with-errors" /> */}
                          </div>

                          <div className=" col-sm-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                name="values"
                                value="IdentityProof"
                                onChange={(e) => {
                                  handlechange123(e);
                                }}
                                id="inlineRadio120"
                                defaultValue="option2"
                                style={{ width: "20px" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio120"
                              >
                                Identity Proof
                              </label>
                            </div>
                            {/* <div className="help-block with-errors" /> */}
                          </div>
                          <div className=" col-sm-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input mt-2"
                                type="checkbox"
                                name="values"
                                value="AddressProof"
                                onChange={(e) => {
                                  handlechange123(e);
                                }}
                                id="inlineRadio121"
                                defaultValue="option2"
                                style={{ width: "20px" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio121"
                              >
                                Address Proof
                              </label>
                            </div>
                            {/* <div className="help-block with-errors" /> */}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group mt-1 mb-3"> 
                    <label htmlFor>Enter your message <span className="text-danger">*</span></label>
                      <textarea
                        id="message"
                        className="form-control"
                        name="message"
                        value={forms.message}
                        rows={3}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter your message"
                     
                        defaultValue={""}
                      />
                      <div className="help-block with-errors" />
                    </div>

                    <button
                      type="submit"
                      id="form-submit"
                      className="btn-rd w-100"
                    >
                      Submit
                    </button>
                    <p className="trm">
                      <i className="fa fa-lock" />
                      We hate spam, and we respect your privacy.
                    </p>
                    <div id="msgSubmit" className="h3 text-center hidden" />
                    <div className="clearfix" />
                  </form>
                  <div className="form-btm-set">
                    {/* <h5>We Deliver</h5> */}
                    <div className="icon-setss mt20">
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/money.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Best Price</p>
                        </div>
                      </div>
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/quality.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Quality Service</p>
                        </div>
                      </div>
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/call-agent.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Good Support</p>
                        </div>
                      </div>
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/satisfaction.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Satisfaction</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="modal_aside1"
          className="modal fixed-center fade"
          tabIndex={-1}
        // role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Become a Partner</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div >
                  <form
                    // role="form"
                    // id="contactForm"
                    // data-toggle="validator"
                    // className="shake"
                    onSubmit={(e) => {
                      getyourquote1(e);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12 mb-3 mt-3 ">
                        <label htmlFor> Enter name <span className="text-danger">*</span> </label>
                        <input
                          type="text"
                          className="form-control"
                          pattern="^[a-zA-Z ]*$"
                          id="name"
                          name="name"
                          value={forms1.name}
                          onChange={(e) => {
                            handlechange1(e);
                          }}
                          placeholder="Enter name"
                          required
                        
                        />
                      
                        
                      </div>

                      <div className="form-group col-sm-12  mb-3">
                        <label htmlFor>Enter Email <span className="text-danger">*</span></label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          value={forms1.email}
                          onChange={(e) => {
                            handlechange1(e);
                          }}
                          placeholder="Enter email"
                          required
                        />
                       
                      
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-sm-12  mb-3">
                        <label htmlFor>Enter mobile <span className="text-danger">*</span></label>
                        <input
                          type="text" pattern="[0-9]{10}"
                          className="form-control"
                          maxLength={10}
                          id="mobile"
                          name="mobile"
                          value={forms1.mobile}
                          onChange={(e) => {
                            handlechangephone1(e);
                          }}
                          placeholder="Enter mobile"
                          required
                          data-error="Please fill Out"
                        />
                       
                      </div>
                      <div className="form-group col-sm-12  mb-3">
                        <label htmlFor>Enter OTP <span className="text-danger">*</span></label>
                        <input
                          type="number"
                          className="form-control"
                          id="mobile"
                          name="otp"
                          value={forms1.otp}
                          onChange={(e) => {
                            handlechangeotp1(e);
                          }}
                          placeholder="Enter OTP"
                          required
                          data-error="Please fill Out"
                        />
                        
                     
                        <div className="row">
                          <div className="col-6">
                            {timeshow == true ? (
                              <span>{time} seconds</span>
                            ) : ""}
                          </div>
                          <div className="col-6">
                            {resends == true ? (
                              <div type="button" onClick={() => { resendotps1() }} style={{ float: "right" }} className="mb-2">Resend Otp</div>
                            ) : ""}
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-sm-12 select-data mb-3">
                      <label htmlFor>Services <span className="text-danger">*</span></label>
                        <select
                          id="Dtype"
                          name="service"
                          value={forms1.service}
                          onChange={(e) => {
                            handlechange1(e);
                          }}
                          className="form-control"
                          required
                        >
                          <option value="">Select Services</option>
                          <option value="Loan">Loan</option>
                          <option value="Insurances">Insurances</option>
                          <option value="Realestate">
                            Realestate & Constraction
                          </option>
                        </select>
                        
                      </div>
                    </div>
                    <div className="form-group mt-1 mb-3">
                    <label htmlFor>Services <span className="text-danger">*</span></label>
                      <textarea
                        id="message"
                        className="form-control"
                        name="message"
                        value={forms1.message}
                        rows={5}
                        onChange={(e) => {
                          handlechange1(e);
                        }}
                        placeholder="Description"
                        required
                        defaultValue={""}
                      />
                      {/* <div className="underline" /> */}
                      {/* <label htmlFor>Description</label> */}
                      {/* <div className="help-block with-errors" /> */}
                    </div>

                    <button
                      type="submit"
                      id="form-submit"
                      className="btn-rd w-100"
                    >
                      Submit
                    </button>

                    {/* <p className="trm">
                      <i className="fa fa-lock" />
                      We hate spam, and we respect your privacy.
                    </p>
                    <div id="msgSubmit" className="h3 text-center hidden" />
                    <div className="clearfix" /> */}
                  </form>
                  {/* <div className="form-btm-set">
                    <div className="icon-setss mt20">
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/money.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Best Price</p>
                        </div>
                      </div>
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/quality.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Quality Service</p>
                        </div>
                      </div>
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/call-agent.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Good Support</p>
                        </div>
                      </div>
                      <div className="icon-rows">
                        <div className="icon-imgg">
                          <img src="images/icons/satisfaction.svg" alt="#" />
                        </div>
                        <div className="icon-txt">
                          <p>Satisfaction</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal className="modal-dialog-centered" size="sm" isOpen={open}>
          <ModalBody className="text-center">
            <img src={successimg} style={{ width: "70%" }} />
            <h5>Thank You!</h5>
            <a className="text-secondary">  Our Team Get Back To You</a>
          </ModalBody>
          <div className="text-center">
            <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
              Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
            </Button>
          </div>
        </Modal>
        <Modal className="modal-dialog-centered" size="sm" isOpen={open1}>
          <ModalBody className="text-center">
            <img src={errorimg} style={{ width: "70%" }} />
            <h5 className="text-danger">Someting Went Wrong.</h5>
            <a className="text-secondary"> Please Check & Try Again</a>
          </ModalBody>
          <div className="text-center">
            <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle1}>
              Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
            </Button>
          </div>
        </Modal>

        <Toaster />
        <Contents />
        <Footer />
      </body>
    </div>
    </DocumentMeta>
  );
}

export default Home;
