import React, { useEffect, useState, useRef } from "react";
import Footer from "./Footer";
import Contents from './Contents'
import Header from "./Header";
import { Link } from "react-router-dom";
import { addData, addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast";
import backgroundimg from "../assets/images/agents/backgroundimg.jpg";
import addimg from "../assets/images/banners/5.png";
import plot5 from "../assets/images/common/plot5.jpg";
import InputRange from "react-input-range";
import { Button, Modal, ModalBody } from 'reactstrap';
import successimg from '../assets/images/reviews/success.png'
import errorimg from '../assets/images/reviews/error.png'
import moment from 'moment'
import DocumentMeta from 'react-document-meta';

const dataarray = [];
function Realestate() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => setOpen1(!open1);



  const [banner, setbanner] = useState([]);
  const [banner12, setbanner12] = useState([]);
  const [banner13, setbanner13] = useState([]);
  const [states, setstates] = useState([]);
  const [formshow, setformshow] = useState(false);

  const getAllbanners = async () => {
    const resonse = await addData("banner/getallrealestate");
    var _data = resonse;
    setbanner(_data.data.horizontal[0]);
    setbanner12(_data.data.vertical[0]);
    setbanner13(_data.data.vertical[1]);
  };
  const [banner1, setbanner1] = useState([]);
  const getAllbanners1 = async () => {
    const resonse = await addDataadmin("pagebanners/getactive");
    var _data = resonse;
    setbanner1(_data.data.pageBanners[0]);
  };

  const getAllstates = async () => {
    const resonse = await addDataadmin("state/getall");
    var _data = resonse;
    setstates(_data.data.statesResult);
  };

  const [realestate, setrealestate] = useState([]);

  const getAllplots = async () => {
    const resonse = await addData("allproduct/allrealestate");
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  useEffect(() => {
    getAllstates();
    getAllbanners();
    getAllbanners1();
    scrollToTop();
    getAllplots();
  }, []);

  // useEffect(() => {
  //   // Simulating an API call or data fetching
  //   setTimeout(() => {
  //     const fetchedData = [...realestate]; // Your real estate data
  //     setrealestate(fetchedData);
  //   }, 2000); // Delay for demonstration purposes
  // }, []);

  // Eligibility Form

  const [forms, setforms] = useState({
    emplyementStatus: "",
    name: "",
    mobile: "",
    email: "",
    loanPurpose: "",
    revenue: "",
    salaryReceivedAs: "",
    propertyLocation: "",
    city: "",
    pincode: "",
    dateOfBirth: "",
    companyName: "",
    dateOfBirth: "",
    salary: "",
    otp: "",
    avgMontlyIncome: "",
    anyPreviousLoans: ""
  });

  const handlechange = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);
  };

  const [yeardiffer, setyeardiffer] = useState('')

  const handlechangedate = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);
    const givenDate = new Date(e.target.value);
    const today = new Date();
    const yearsDifference = today.getFullYear() - givenDate.getFullYear();
    setyeardiffer(yearsDifference)
  };

  const [yeardiffer1, setyeardiffer1] = useState('')

  const handlechangedate1 = (e) => {
    const myForm = { ...forms1 };
    myForm[e.target.name] = e.target.value;
    setforms1(myForm);
    const givenDate = new Date(e.target.value);
    const today = new Date();
    const yearsDifference1 = today.getFullYear() - givenDate.getFullYear();
    setyeardiffer1(yearsDifference1)
  };

  const [time, setTime] = useState(30);
  useEffect(() => {
    const updateTimer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(updateTimer);
  }, []);

  const [timeshow, settimeshow] = useState(false);
  const [resends, setresends] = useState(false);

  const handlechangephone = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputMobile;
    setforms(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps = async () => {
    const bodydata = {
      mobile: forms.mobile,
    };
    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow(true);
      setTime(30);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }
  };

  const handlechangeotp = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputOtp;
    setforms(myForm);

    const bodydata = {
      mobile: forms.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const getEligibility = async (e) => {
    e.preventDefault();
    const bodydata = {
      realestateId: platname._id,
      anyPreviousLoans: forms.anyPreviousLoans,
      emplyementStatus: forms.emplyementStatus || "Salaried",
      name: forms.name,
      mobile: forms.mobile,
      email: forms.email,
      loanPurpose: forms.loanPurpose,
      revenue: forms.revenue || "Less than 20 Lakhs",
      salaryReceivedAs: forms.salaryReceivedAs,
      propertyLocation: forms.propertyLocation,
      city: forms.city,
      pincode: forms.pincode,
      dateOfBirth: forms.dateOfBirth,
      companyName: forms.companyName,
      dateOfBirth: forms.dateOfBirth,
      salary: forms.salary,
      otp: forms.otp,
      avgMontlyIncome: forms.avgMontlyIncome,
    };
    try {
      const resonse = await addData("eligibility/add", bodydata);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setOpen(true)
      setforms({
        emplyementStatus: "",
        name: "",
        mobile: "",
        email: "",
        loanPurpose: "",
        revenue: "",
        salaryReceivedAs: "",
        propertyLocation: "",
        city: "",
        pincode: "",
        dateOfBirth: "",
        companyName: "",
        dateOfBirth: "",
        salary: "",
        otp: "",
        avgMontlyIncome: "",
      });
      setresends(false);
      settimeshow(false);
      closeModal();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("modal_aside");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click();
  };

  // close Eligibility Form
  // Get Details Form

  const [platname, setplatname] = useState([])
  console.log(platname._id)

  const getformopen = (data) => {
    setplatname(data)
  }

  const getDetails = async (e) => {
    e.preventDefault();
    const bodydata = {
      name: forms.name,
      mobile: forms.mobile,
      otp: forms.otp,
      realestateId: platname._id,
    };
    try {
      const resonse = await addDataadmin("getdetail/add", bodydata);
      var _data = resonse;
      console.log(_data);
      setOpen(true)
      // toast.success(_data.data.message);
      setforms({
        name: "",
        mobile: "",
        otp: "",
      });
      setplatname('')
      setresends(false);
      settimeshow(false);
      closeModal1();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const closeModal1 = () => {
    const modalElement = document.getElementById("staticBackdrop");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click();
  };

  // End Details form
  // Apply for Loan

  const [timeshow1, settimeshow1] = useState(false);
  const [resends1, setresends1] = useState(false);

  const handlechangephone1 = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputMobile;
    setforms1(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends1(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps1 = async () => {
    const bodydata = {
      mobile: forms1.mobile,
    };

    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow1(true);
      setTime(30);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }
  };

  const handlechangeotp1 = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputOtp;
    setforms1(myForm);

    const bodydata = {
      mobile: forms1.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const [forms1, setforms1] = useState({
    employmentType: "",
    name: "",
    mobile: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    requestType: "",
    loanPurpose: "",
    plotCost: "",
    constructionCost: "",
    residentialCost: "",
    takenLoan: "",
    loanTenure: "",
    financialInstitute: "",
    otp: "",
    outStandingBalannce: "",
    state: "",
    city: "",
    officeArea: "",
    construction: "",
    companyName: "",
    takeHomeSalary: "",
    variableMontlySalary: "",
    otherMonthlySalary: "",
    retrirementAge: "",
    existingEMIPay: "",
    latestITRincome: "",
    residentialStatus: "",
    dateOfLoanTaken: "",
    companyType: "",
    country: "",
  });



  const handlechange1 = (e) => {
    const myForm = { ...forms1 };
    myForm[e.target.name] = e.target.value;
    setforms1(myForm);
  };

  const [Files1, setFiles1] = useState({ itReturn: "", })
  console.log(Files1)

  const filechange1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }

  const [Files10, setFiles10] = useState({ bankStatement: "" })
  console.log(Files10)

  const filechange10 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles10(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }
  const [Files11, setFiles11] = useState({ aadharCard: "", })
  console.log(Files11)

  const filechange11 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles11(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }
  const [Files12, setFiles12] = useState({ panCard: "" })
  console.log(Files12)

  const filechange12 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles12(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  }
  const [Files13, setFiles13] = useState({ salarySlip: "" })
  console.log(Files13)
  const filechange13 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles13(e.target.files)
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }
  }

  const handleNext = () => {

    if (forms1.name === '' || forms1.mobile === '' || forms1.otp === '' || forms1.dateOfBirth === '' || forms1.gender === ''
      || forms1.employmentType === '' || forms1.otherMonthlySalary === '' ||
      Files10.bankStatement === '' || Files11.aadharCard === '' || Files12.panCard === '') {
      toast.error("Please fill in the required fields")
    }
    else {
      setformshow(!formshow)
    }


  };

  const handleNext2 = () => {
    if (forms1.name === '' || forms1.mobile === '' || forms1.otp === '' || forms1.dateOfBirth === '' || forms1.gender === '' || forms1.employmentType === '' || forms1.companyName === '' ||
      forms1.companyType === '' || forms1.takeHomeSalary === '' || forms1.variableMontlySalary === '' || forms1.otherMonthlySalary === '' || forms1.retrirementAge === ''
      // || forms1.existingEMIPay === ''
      || Files10.bankStatement === '' || Files11.aadharCard === '' || Files12.panCard === '' || Files13.salarySlip === '') {
      toast.error("Please fill in the required fields")
    } else {
      setformshow(!formshow)
    }
  };

  const applyLoan = async (e) => {
    e.preventDefault();
    const dataArray = new FormData()
    dataArray.append("realestateId", platname._id)
    dataArray.append("employmentType", forms1.employmentType)
    dataArray.append("name", forms1.name)
    dataArray.append("mobile", forms1.mobile)
    dataArray.append("otp", forms1.otp)
    dataArray.append("email", forms1.email)
    dataArray.append("dateOfBirth", forms1.dateOfBirth)
    dataArray.append("gender", forms1.gender)
    dataArray.append("requestType", forms1.requestType)
    dataArray.append("loanPurpose", forms1.loanPurpose)
    dataArray.append("plotCost", forms1.plotCost)
    dataArray.append("constructionCost", forms1.constructionCost)
    dataArray.append("residentialCost", forms1.residentialCost)
    dataArray.append("takenLoan", forms1.takenLoan)
    dataArray.append("loanTenure", forms1.loanTenure)
    dataArray.append("financialInstitute", forms1.financialInstitute)
    dataArray.append("outStandingBalannce", forms1.outStandingBalannce)
    dataArray.append("state", forms1.state)
    dataArray.append("city", forms1.city)
    dataArray.append("officeArea", forms1.officeArea)
    dataArray.append("construction", forms1.construction)
    dataArray.append("companyName", forms1.companyName)
    dataArray.append("takeHomeSalary", forms1.takeHomeSalary)
    dataArray.append("variableMontlySalary", forms1.variableMontlySalary)
    dataArray.append("otherMonthlySalary", forms1.otherMonthlySalary)
    dataArray.append("retrirementAge", forms1.retrirementAge)
    dataArray.append("existingEMIPay", forms1.existingEMIPay)
    dataArray.append("latestITRincome", forms1.latestITRincome)
    dataArray.append("residentialStatus", forms1.residentialStatus)
    dataArray.append("dateOfLoanTaken", forms1.dateOfLoanTaken)
    dataArray.append("companyType", forms1.companyType)
    dataArray.append("typeOfResidence", forms1.typeOfResidence)
    dataArray.append("country", forms1.country)

    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("itReturn", Files1[i])
    }
    for (let i = 0; i < Files10.length; i++) {
      dataArray.append("bankStatement", Files10[i])
    }
    for (let i = 0; i < Files11.length; i++) {
      dataArray.append("aadharCard", Files11[i])
    }
    for (let i = 0; i < Files12.length; i++) {
      dataArray.append("panCard", Files12[i])
    }
    for (let i = 0; i < Files13.length; i++) {
      dataArray.append("salarySlip", Files13[i])
    }


    try {
      const resonse = await addDataadmin("applyloan/add", dataArray);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setOpen(true)

      setforms1({
        employmentType: "",
        name: "",
        mobile: "",
        email: "",
        dateOfBirth: "",
        gender: "",
        requestType: "",
        loanPurpose: "",
        plotCost: "",
        constructionCost: "",
        residentialCost: "",
        takenLoan: "",
        loanTenure: "",
        financialInstitute: "",
        otp: "",
        outStandingBalannce: "",
        state: "",
        city: "",
        officeArea: "",
        construction: "",
        companyName: "",
        takeHomeSalary: "",
        variableMontlySalary: "",
        otherMonthlySalary: "",
        retrirementAge: "",
        existingEMIPay: "",
        latestITRincome: "",
        residentialStatus: "",
        dateOfLoanTaken: "",
        companyType: "",
        typeOfResidence: "",
        country: "",
      });
      setformshow(false)
      setresends1(false);
      settimeshow1(false);
      closeModal2();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  const closeModal2 = () => {
    const modalElement = document.getElementById("staticBackdrop1");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click();
  };

  // End Apply form loan

  const [form, setform] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleFilterClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 760) {
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check on component mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [optshow, setoptshow] = useState({ images: [] });
  console.log(optshow);

  const getimgdata = (data) => {
    setoptshow({ images: data.images });
  };

  // filter
  const [bhk, setbhk] = useState("");
  const [dataas, setDatas] = useState([]);
  // const [bhkvalue, setbhk]=useState("")

  console.log(bhk)


  const appartment = async (event) => {


    console.log(event.target.value);

    const isChecked = event.target.checked;

    // const newElement = event.target.value;

    // if(dataarray.includes(newElement))
    // {
    //   dataarray=[];
    //   const newElement = dataarray.filter((element) => element !== newElement);
    //   dataarray.push(newElement);
    // }
    // else
    // {
    //   dataarray.push(newElement);
    // }
    // console.log(dataarray);

    const newElement = event.target.value;

    if (dataarray.includes(newElement)) {

      var index = dataarray.indexOf(newElement);
      if (index !== -1) {
        dataarray.splice(index, 1);

      }

    } else {
      dataarray.push(newElement);

    }
    setbhk(dataarray)

    const data = {
      furnishing: furnishing.furnishing || "",
      availability: [avila.availability || ""],
      floor: floorss || "",
      parking: parking1.split(",") || "",
      propertyAge: [popertyage.propertyAge || ""],
      apartmentType: dataarray,
      propertyType: ptype1.split(",") || "",
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  const [rangeValue, setRangeValue] = useState({ min: 0, max: 100000000 });
  console.log(rangeValue);

  const handleForm = async (value) => {
    setRangeValue(value);
    const data = {
      furnishing: furnishing.furnishing || "",
      availability: [avila.availability],
      floor: floorss || "",
      parking: parking1.split(",") || "",
      propertyAge: [popertyage.propertyAge || ""],
      apartmentType: bhk || "",
      propertyType: ptype1.split(",") || "",
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };
  const [avila, setavila] = useState("");
  const avilability = async (e) => {
    const myForm = { ...avila };
    myForm[e.target.name] = e.target.value;
    setavila(myForm);
    const data = {
      furnishing: furnishing.furnishing || "",
      availability: [e.target.value],
      floor: floorss || "",
      parking: parking1.split(",") || "",
      propertyAge: [popertyage.propertyAge || ""],
      apartmentType: bhk || "",
      propertyType: ptype1.split(",") || "",
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  const [ptype, setptype] = useState("");
  const [ptype1, setptype1] = useState("");
  const propertype = async (e) => {
    const myForm = { ...ptype };
    myForm[e.target.value] = e.target.checked;
    setptype(myForm);

    const selectedParkings1 = Object.keys(myForm).filter((key) => myForm[key]);
    const parkingString1 = selectedParkings1.join(", ");
    setptype1(parkingString1);

    const data = {
      furnishing: furnishing.furnishing || "",
      availability: [avila.availability || ""],
      floor: floorss || "",
      parking: parking1.split(",") || "",
      propertyAge: [popertyage.propertyAge || ""],
      apartmentType: bhk || "",
      // propertyType: [parkingString1 == "Gated_Community"? ["Gated_Community", "Villas", "Plots"]:parkingString1 || ""],
      propertyType: parkingString1.split(","),
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  const [furnishing, setFurnishing] = useState("");
  const Furnishing = async (e) => {
    const myForm = { ...furnishing };
    myForm[e.target.name] = e.target.value;
    setFurnishing(myForm);
    const data = {
      furnishing: e.target.value,
      availability: [avila.availability || ""],
      floor: floorss || "",
      parking: parking1.split(",") || "",
      propertyAge: [popertyage.propertyAge || ""],
      apartmentType: bhk || "",
      propertyType: ptype.propertyType.split(",") || "",
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  const [parking, setparking] = useState("");
  const [parking1, setparking1] = useState("");
  const parkingchange = async (e) => {
    const myForm = { ...parking };
    myForm[e.target.value] = e.target.checked;
    setparking(myForm);

    const selectedParkings = Object.keys(myForm).filter((key) => myForm[key]);
    const parkingString = selectedParkings.join(", ");
    setparking1(parkingString);

    const data = {
      furnishing: furnishing.furnishing || "",
      availability: [avila.availability || ""],
      floor: floorss || "",
      parking: parkingString.split(","),
      propertyAge: [popertyage.propertyAge || ""],
      apartmentType: bhk || "",
      propertyType: ptype1.split(",") || "",
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  const [popertyage, setpopertyage] = useState("");
  const popertyagechange = async (e) => {
    const myForm = { ...popertyage };
    myForm[e.target.name] = e.target.value;
    setpopertyage(myForm);
    const data = {
      furnishing: furnishing.furnishing,
      availability: [avila.availability || ""],
      floor: floorss || "",
      parking: parking1.split(",") || "",
      propertyAge: e.target.value,
      apartmentType: bhk || "",
      // propertyType: [ptype1 == "Gated_Community"? ["Gated_Community", "Villas", "Plots"]:ptype1 || ""],

      propertyType: ptype1.split(",") || "",
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  const [floorss, setfloorss] = useState("");
  // const [bhkvalue, setbhk]=useState("")

  const floorchange = async (e) => {
    setfloorss(e.target.value);
    const data = {
      furnishing: furnishing.furnishing || "",
      availability: [avila.availability || ""],
      floor: e.target.value,
      parking: [parking1.split(",") || ""],
      propertyAge: [popertyage.propertyAge || ""],
      apartmentType: bhk || "",
      propertyType: [ptype1.split(",") || ""],
      amount: rangeValue,
    };
    const resonse = await addData("allproduct/realestatefilter", data);
    var _data = resonse;
    setrealestate(_data.data.realestate);
  };

  const CleaData = () => {
    setfloorss("");
    setparking1("");
    setpopertyage("");
    setFurnishing("");
    setptype1("");
    setavila("");
    setRangeValue({ min: 0, max: 500000 });
    setbhk("");
    scrollToTop()
    window.location.reload()
    // const resonse = await addData("allproduct/allrealestate");
    // var _data = resonse;
    // setrealestate(_data.data.realestate);

  };

  // const getAllplots = async () => {
  //   const resonse = await addData("allproduct/allrealestate");
  //   var _data = resonse;
  //   setrealestate(_data.data.realestate);
  // };


  const [bhks, setBhks] = useState('');

  console.log(bhks)

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setBhks(isChecked ? "4+BHK" : "");
  };

  const meta = {
    title: 'Best real estate Agency in Hyd | Right Agent Group',
    description: 'Welcome to Right Agent Group, a service-oriented company that provides exceptional assistance in the real estate.',
    canonical: 'https://rightagentgroup.com/realestate',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'real estate agency in hyderabad, top 10 real estate agents in kompally, best real estate agents in hyderabad, property agents in bachupally, top real estate agents in Miyapur, commercial rental agencies in hyderabad'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
    <div>
      <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="100"
        className="ct1280"
      >
        <Header />
        <section
          style={{ paddingTop: "80px" }}
          className="hero-section-1"
          id="home"
        >
          <div
            style={{
              background: `url(${imgUrl + banner1.image})`,
              opacity: 0.8,
              backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundRepeat: "no-repeat"
            }}
            className="text-center"
          >
            <div className="headst">
              <div className="text-white ">
                <h1>Real Estate</h1>
                {/* <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6">
                    {" "}
                    <p style={{ fontSize: "20px" }}>{banner1.description}</p>
                  </div>
                  <div className="col-lg-3"></div>
                </div> */}

                {/* <p style={{fontSize:"20px"}}>A Insurances is a debt incurred by an individual or some entity.</p> */}
              </div>
            </div>
            {/* <Link to="/">Home</Link> / <span>Loans</span> */}
          </div>
        </section>

        <section className="container">
          <div className="row mt-4 mb-5">
            {/* Filters */}
            <div className="col-md-4">
              <div className="card mb-3">
                <div className="card-header text-center text-primary bg-white">
                  <h5
                    style={{ color: "#26bdea" }}
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded={isCollapsed ? "false" : "true"}
                    aria-controls="collapseExample"
                  >
                    Filters
                  </h5>
                </div>

                <div
                  class=""
                  id="collapseExample"
                  className={`card-body collapse realestatefilterst ${isCollapsed ? "" : "show"}`}
                >
                  <span>
                    <b>BHK Type</b>
                  </span>

                  <div className="mt-2">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >



                      {/*                      
                      <li className="nav-item p-2" role="presentation">
                        <div
                          onClick={() => {
                            appartment("4BHK");
                          }}
                          value="4BHK"
                          name="apartmentType"
                          type="button"
                          className={bhk == "4BHK" ? "selected" : "unselected"}
                        >
                          4 BHK
                        </div>
                      </li>
                      <li className="nav-item p-2" role="presentation">
      <label className={bhk === "4+BHK" ? "selected" : ""}>
        <input
          type="checkbox"
          value="4+BHK"
          name="apartmentType"
          checked={bhks === "4+BHK"}
          onChange={handleChange}
        />
        4 +BHK
      </label>
    </li> */}

                      <li className="nav-item " role="presentation">
                        <div className="cat sports">
                          <label>
                            <input type="checkbox" value="1RK" onClick={appartment}
                              name="apartmentType" /><span>1RK</span>
                          </label>
                        </div>
                      </li>

                      <li className="nav-item " role="presentation">
                        <div className="cat sports">
                          <label>
                            <input type="checkbox" value="1BHK" onClick={appartment}
                              name="apartmentType" /><span>1BHK</span>
                          </label>
                        </div>
                      </li>

                      <li className="nav-item " role="presentation">
                        <div className="cat sports">
                          <label>
                            <input type="checkbox" value="2BHK" onClick={appartment}
                              name="apartmentType" /><span>2BHK</span>
                          </label>
                        </div>
                      </li>
                      <li className="nav-item " role="presentation">
                        <div className="cat sports">
                          <label>
                            <input type="checkbox" value="3BHK" onClick={appartment}
                              name="apartmentType" /><span>3BHK</span>
                          </label>
                        </div>
                      </li>
                      <li className="nav-item " role="presentation">
                        <div className="cat sports">
                          <label>
                            <input type="checkbox" value="4BHK" onClick={appartment}
                              name="apartmentType" /><span>4BHK</span>
                          </label>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div className="cat sports">
                          <label>
                            <input type="checkbox" value="4+BHK" onClick={appartment}
                              name="apartmentType" /><span>4+BHK</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <span>
                    <b>
                      Budget Range: ₹ {rangeValue.min} to ₹ {rangeValue.max}{" "}
                    </b>
                  </span>
                  <div className="mt-3 mb-3">
                    <InputRange
                      maxValue={100000000}
                      minValue={0}
                      value={rangeValue}
                      onChange={handleForm}
                      draggableTrack
                      step={1}
                      className="custom-input-range"
                    />
                  </div>

                  <div className="mt-2">
                    <span>
                      <b>Availability </b>
                    </span>
                    <div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="availability"
                              id="Immediate"
                              defaultValue="Immediate"
                              onChange={(e) => {
                                avilability(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Immediate"
                            >
                              Immediate
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="availability"
                              id="Within_15_Days"
                              defaultValue="Within_15_Days"
                              onChange={(e) => {
                                avilability(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Within_15_Days"
                            >
                              Within 15 Days
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="availability"
                              id="Within_30_Days"
                              defaultValue="Within_30_Days"
                              onChange={(e) => {
                                avilability(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Within_30_Days"
                            >
                              Within 30 Days
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="availability"
                              id="After_30_Days"
                              defaultValue="After_30_Days"

                              onChange={(e) => {
                                avilability(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="After_30_Days"
                            >
                              After 30 Days
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <span>
                      <b>Property Type</b>
                    </span>
                    <div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Gated_Community"
                              // value="Gated_Community"
                              value={["Gated_Community", "Villas", "Plots"]}
                              // value={JSON.stringify("Gated_Community", "Villas", "Plots")}
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="Gated_Community"
                            >
                              Gated Community / Villas / Plots
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Villa"
                              name="propertyType"
                              value="Villas"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Villa">
                              Villas
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="propertyType"
                              id="Plots"
                              value="Plots"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Plots">
                              Plots
                            </label>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Residential_Plots"
                              name="propertyType"
                              value="Residential_Plots"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="Residential_Plots"
                            >
                              Residential Plots
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Commercial_Plots"
                              name="propertyType"
                              value="Commercial_Plots"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="Commercial_Plots"
                            >
                              Commercial Plots
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Agricultural_Lands"
                              value="Agricultural_Lands"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="Agricultural_Lands"
                            >
                              Agricultural Lands
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Builder_Development_Land"
                              value="Builder_Development_Land"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="Builder_Development_Land"
                            >
                              Builder Development Land
                            </label>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Material_Contractor"
                              value="Material_Contractor"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="Material_Contractor"
                            >
                              Material Contractor
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Lease_Property"
                              value="Lease_Property"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label
                              class="form-check-label"
                              for="Lease_Property"
                            >
                              Lease Property
                            </label>
                          </div>
                        </div>
                        {/* <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Built_Up_Area"
                              value="Built_Up_Area"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Built_Up_Area">
                              Built Up Area
                            </label>
                          </div>
                        </div> */}

                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Indipendent_House"
                              value="Indipendent_House"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Indipendent_House">
                              Indipendent House
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Builder_Development_Flats"
                              value="Builder_Development_Flats"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Builder_Development_Flats">
                              Builder Development Flats
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Development_Lands"
                              value="Development_Lands"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Development_Lands">
                              Development Lands
                            </label>
                          </div>
                        </div>

                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Appartments"
                              value="Appartments"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Appartments">
                              Appartments
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="New_Launching"
                              value="New_Launching"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="New_Launching">
                              New Launching
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="Under_Constraction"
                              value="Under_Constraction"
                              name="propertyType"
                              onChange={(e) => {
                                propertype(e);
                              }}
                            />
                            <label class="form-check-label" for="Under_Constraction">
                              Under Constraction
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <span>
                      <b>Furnishing</b>
                    </span>
                    <div>
                      <div className="row mt-2">
                        <div className="col-4">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="Full"
                              value="Full"
                              name="furnishing"
                              onChange={(e) => {
                                Furnishing(e);
                              }}
                            />
                            <label class="form-check-label" for="Full">
                              Full
                            </label>
                          </div>
                        </div>
                        <div className="col-4">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="Semi"
                              value="Semi"
                              name="furnishing"
                              onChange={(e) => {
                                Furnishing(e);
                              }}
                            />
                            <label class="form-check-label" for="Semi">
                              Semi
                            </label>
                          </div>
                        </div>
                        <div className="col-4">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="None"
                              value="None"
                              name="furnishing"
                              onChange={(e) => {
                                Furnishing(e);
                              }}
                            />
                            <label class="form-check-label" for="None">
                              None
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <span>
                      <b>Parking</b>
                    </span>
                    <div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="2Wheeler"
                              value="2_Wheeler"
                              name="parking"
                              onChange={(e) => {
                                parkingchange(e);
                              }}
                            />
                            <label class="form-check-label" for="2Wheeler">
                              2 Wheeler
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="4Wheeler"
                              value="4_Wheeler"
                              name="parking"
                              onChange={(e) => {
                                parkingchange(e);
                              }}
                            />
                            <label class="form-check-label" for="4Wheeler">
                              4 Wheeler
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <span>
                      <b>Property Age </b>
                    </span>
                    <div>
                      <div className="row mt-2 p-2">
                        <select name="propertyAge"
                          onChange={(e) => {
                            popertyagechange(e);
                          }}
                          className="form-control">
                          <option value="">Select</option>
                          <option value="1">1 Year</option>
                          <option value="2">2  Years</option>
                          <option value="3">3 Years</option>
                          <option value="4">4 Years</option>
                          <option value="5">5 Years</option>
                          <option value="6">6 Years</option>
                          <option value="7">7 Years</option>
                          <option value="8">8 Years</option>
                          <option value="9">9 Years</option>
                          <option value="10">10 Years</option>
                          <option value="11">11 Years</option>
                          <option value="12">12 Years</option>
                          <option value="13">13 Years</option>
                          <option value="14">14 Years</option>
                          <option value="15">15 Years</option>
                          <option value="16">16 Years</option>
                          <option value="17">17 Years</option>
                          <option value="18">18 Years</option>
                          <option value="19">19 Years</option>
                          <option value="20">20 Years</option>
                        </select>
                        {/* <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="propertyAge"
                              id="1_Year"
                              defaultValue="1_Year"
                              onChange={(e) => {
                                popertyagechange(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="1_Year"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>{" "}
                              1 Year
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="propertyAge"
                              id="3_Year"
                              defaultValue="3_Year"
                              onChange={(e) => {
                                popertyagechange(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="3_Year"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>{" "}
                              3 Year
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="propertyAge"
                              id="5_Year"
                              defaultValue="5_Year"
                              onChange={(e) => {
                                popertyagechange(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="5_Year"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>{" "}
                              5 Year
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="propertyAge"
                              id="10_Year"
                              defaultValue="10_Year"
                              onChange={(e) => {
                                popertyagechange(e);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="10_Year"
                            >
                              <i
                                class="fa fa-angle-left"
                                aria-hidden="true"
                              ></i>{" "}
                              10 Year
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2">
                    <span>
                      <b>Floors</b>
                    </span>

                    <div className="mt-2"> */}
                  {/* <select  name="floors"
                         onChange={(e) => {
                          floorchange(e);
                        }}
                        className="form-control">
                          <option value="">Select</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                        </select> */}
                  {/* <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item p-2" role="presentation">
                          <div
                            onClick={() => {
                              floorchange("0");
                            }}
                            value="0"
                            name="floors"
                            type="button"
                            className={
                              floorss == "0" ? "selected" : "unselected"
                            }
                          >
                            Ground
                          </div>
                        </li>
                        <li className="nav-item p-2" role="presentation">
                          <div
                            onClick={() => {
                              floorchange("1_to_3");
                            }}
                            value="1_to_3"
                            name="floors"
                            type="button"
                            className={
                              floorss == "1_to_3" ? "selected" : "unselected"
                            }
                          >
                            1 to 3
                          </div>
                        </li>
                        <li className="nav-item p-2" role="presentation">
                          <div
                            onClick={() => {
                              floorchange("4_to_6");
                            }}
                            value="4_to_6"
                            name="floors"
                            type="button"
                            className={
                              floorss == "4_to_6" ? "selected" : "unselected"
                            }
                          >
                            4 to 6
                          </div>
                        </li>
                        <li className="nav-item p-2" role="presentation">
                          <div
                            onClick={() => {
                              floorchange("7_to_9");
                            }}
                            value="7_to_9"
                            name="floors"
                            type="button"
                            className={
                              floorss == "7_to_9" ? "selected" : "unselected"
                            }
                          >
                            7 to 9
                          </div>
                        </li>
                        <li className="nav-item p-2" role="presentation">
                          <div
                            onClick={() => {
                              floorchange("10_&_above");
                            }}
                            value="10_&_above"
                            name="floors"
                            type="button"
                            className={
                              floorss == "10_&_above"
                                ? "selected"
                                : "unselected"
                            }
                          >
                            10 & above
                          </div>
                        </li>
                      </ul> */}
                  {/* </div>
                  </div> */}
                  <div>
                    <button
                      onClick={() => {
                        CleaData();
                      }}
                      style={{ width: "100px", float: "right" }}
                      className="btn btn-outline-danger btn-sm mt-4"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Plots */}
            <div className="col-md-8 col-lg-6">
              <div
                style={{
                  maxHeight: "1000px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                className="row"
              >
                <div className="col-md-12">
                  {realestate.length == 0 ? (
                    <div className="card">
                      <div className="card-body text-center">
                        <h5 className="text-center">Data Loading...</h5>
                      </div>
                    </div>
                  ) : (
                    <div>

                      {/* {React.lazy(() => */}
                      {realestate.map((data, key) => (
                        <div key={key} className="card mb-3 ">
                          <div className="card-header">
                            <h6>Project Id: #{data.realestateNo}</h6>
                            <h6>{data.title}</h6>
                            <a>{data.description}</a>
                          </div>
                          <div className="card-header bg-white">
                            <div className="row">
                              <div className="col-4 realestatehead border-right">
                                <div className="text-center">
                                  <h6>₹ {data.amount}</h6>
                                  <a>
                                    {data.discount == "Non_negotiable"
                                      ? "Non Negotiable"
                                      : "Negotiable"}
                                  </a>
                                </div>
                              </div>
                              <div className="col-4 realestatehead border-right">
                                <div className="text-center">
                                  <h6>₹ {data.approxEMI}</h6>
                                  <a>Estimated Emi </a>
                                </div>
                              </div>
                              <div className="col-4 realestatehead">
                                <div className="text-center">
                                  <h6>{data.builtUpArea}</h6>
                                  <a>Builtup Area</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-4">
                                <div
                                  id={`carouselExampleControls-${key}`}
                                  className="carousel slide"
                                  data-ride="carousel"
                                >
                                  <div
                                    data-toggle="modal"
                                    data-target="#staticBackdrop123"
                                    className="carousel-inner"
                                    onClick={() => {
                                      getimgdata(data);
                                    }}
                                  >
                                    {data.images.map((imag, key) => (
                                      <div
                                        // onClick={()=>{getimgdata(imag)}}
                                        key={key}
                                        className={`carousel-item ${key === 0 ? "active" : ""
                                          }`}
                                      >
                                        <img
                                          style={{ height: "160px" }}
                                          src={imgUrl + imag}
                                          className="d-block w-100"
                                          alt="..."
                                        />
                                      </div>
                                    ))}

                                  </div>

                                  <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-target={`#carouselExampleControls-${key}`}
                                    data-slide="prev"
                                  >
                                    <span
                                      className="carousel-control-prev-icon"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">Previous</span>
                                  </button>
                                  <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-target={`#carouselExampleControls-${key}`}
                                    data-slide="next"
                                  >
                                    <span
                                      className="carousel-control-next-icon"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">Next</span>
                                  </button>
                                </div>
                                <div>
                                <a target="_blank" class="a2a_dd btnstyles " href="https://www.addtoany.com/share#url=https%3A%2F%2Frightagentgroup.com%2Frealestate">

                                  <button style={{ color: "#ff6600", border: "1px solid" }} className="btn btn-outline-warning form-control mt-4 mb-1"><i class="fa fa-share" aria-hidden="true"></i> Share</button>
                            </a>
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div>
                                  <table className="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: "6px" }}>
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <i
                                                style={{ fontSize: "25px" }}
                                                class="fa fa-object-group mt-2"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="col-lg-9">
                                              <a style={{ fontWeight: "900" }}>
                                                {data.furnishing}
                                              </a>
                                              <br />
                                              <a>Furnishing</a>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ padding: "6px" }}>
                                          {" "}
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <i
                                                style={{ fontSize: "25px" }}
                                                class="fa fa-home mt-2"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="col-lg-9">
                                              <a style={{ fontWeight: "900" }}>
                                                {data.apartmentType}
                                              </a>
                                              <br />
                                              <a>Property Type</a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "6px" }}>
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <i
                                                style={{ fontSize: "25px" }}
                                                class="fa fa-user-circle-o mt-2"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="col-lg-9">
                                              <a style={{ fontWeight: "900" }}>
                                                {data.floor}/{data.totalFloors}
                                              </a>
                                              <br />
                                              <a>Floor</a>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ padding: "6px" }}>
                                          {" "}
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <i
                                                style={{ fontSize: "25px" }}
                                                class="fa fa-compass mt-2"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="col-lg-9">
                                              <a style={{ fontWeight: "900" }}>
                                                {data.facing}
                                              </a>
                                              <br />
                                              <a>Facing</a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ padding: "6px" }}>
                                          {" "}
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <i
                                                style={{ fontSize: "25px" }}
                                                class="fa fa-calendar-check-o mt-2"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="col-lg-9">
                                              <a style={{ fontWeight: "900" }}>
                                                {data.propertyAge} years
                                                {/* {data.propertyAge == "1" ? <>{data.propertyAge} year</> : <>{data.propertyAge} years</>} */}
                                              </a>
                                              <br />
                                              <a>Age of Building</a>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ padding: "6px" }}>
                                          {" "}
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <i
                                                style={{ fontSize: "25px" }}
                                                class="fa fa-car mt-2"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <div className="col-lg-9">
                                              <a style={{ fontWeight: "900" }}>
                                                {data.parking == "2_Wheeler" ? "2 Wheeler" : ""}
                                                {data.parking == "4_Wheeler" ? "4 Wheeler" : ""}
                                                {data.parking == "2_&_4_Wheeler" ? "2 & 4 Wheeler" : ""}
                                              </a>
                                              <br />
                                              <a>Parking</a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div className="row mt-2">
                                    <div style={{ padding: "6px" }} className="col-md-6 col-lg-4">
                                      <button
                                        onClick={() => { getformopen(data) }}
                                        data-toggle="modal"
                                        data-target="#staticBackdrop"
                                        className="btn-rd123"
                                        style={{ width: "100%" }}
                                      >
                                        Get Details
                                      </button>
                                    </div>
                                    <div style={{ padding: "6px" }} className="col-md-6 col-lg-4">
                                      <button
                                        onClick={() => { getformopen(data) }}
                                        href="#modal"
                                        data-toggle="modal"
                                        data-target="#modal_aside"
                                        className="btn-rd1234"
                                        style={{ width: "100%" }}
                                      >
                                        Get Eligibility
                                      </button>
                                    </div>
                                    <div style={{ padding: "6px" }} className="col-md-6 col-lg-4">
                                      <button
                                        onClick={() => { getformopen(data) }}
                                        data-toggle="modal"
                                        data-target="#staticBackdrop1"
                                        className="btn-rd123"
                                        style={{ width: "100%" }}
                                      >
                                        Apply Loan
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* // )} */}

                    </div>
                  )}
                </div>

              </div>
            </div>
            <div className="col-md-2 displayst">
              <section>
                <div className="">
                  <a href={banner12?.link} target="_blank">
                    <img
                      style={{ width: "100%", height: "500px" }}
                      src={imgUrl + banner12?.image}
                      alt="banner"
                    />
                  </a>
                </div>
                <div className="mt-2">
                  <a href={banner13?.link} target="_blank">
                    <img
                      style={{ width: "100%", height: "500px" }}
                      src={imgUrl + banner13?.image}
                      alt="banner"
                    />
                  </a>
                </div>
              </section>

              {/* <div>
                    <img
                      style={{ width: "100%" }}
                      src={}
                      className="p-2"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={addimg}
                      className="p-2"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={addimg}
                      className="p-2"
                    />
                    <img
                      style={{ width: "100%" }}
                      src={addimg}
                      className="p-2"
                    />
                  </div> */}

            </div>
          </div>
        </section>

        <section>
          <div className="">
            <a href={banner?.link} target="_blank">
              <img
                style={{ width: "100%" }}
                src={imgUrl + banner?.image}
                alt="banner"
              />
            </a>
          </div>
        </section>

        <Toaster />
        <Contents />
        <Footer />
      </body>

      {/* <div
        id="modal_aside"
        className="modal fixed-center fade"
        tabIndex={-1}
   
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">GET ELIGIBILITY</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row  mt-3">
                <div className="col-md-12">
                  <div
                
                  >
                    

                    <div className="container">
                      <form
                        onSubmit={(e) => {
                          getEligibility(e);
                        }}
                      >
                        <div className="row mb-4">

                          <div className="col-md-6 mb-3">
                          
                            <label htmlFor> Name  <span className="text-danger">*</span></label>
                            <input
                              className="form-control"
                              placeholder="Enter Name"
                              type="text"
                              name="name"
                              value={forms.name}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              pattern="^[a-zA-Z ]*$"
                             
                              required
                            />
                           
                          </div>
                          <div className="col-md-6 mb-3 ">
                            
                            <label htmlFor> Email <span className="text-danger">*</span></label>
                            <input
                              className="form-control"
                              placeholder="Enter Email"
                              name="email"
                              value={forms.email}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              type="email" required />
                            
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6 mb-3 ">
                                
                                <label htmlFor> Mobile Number <span className="text-danger">*</span></label>
                                <input  
                                  className="form-control"
                                  placeholder="Enter Mobile Nember"
                                  type="text" pattern="[0-9]{10}"
                                  maxLength={10}
                                  name="mobile"
                                  value={forms.mobile}
                                  required
                                  onChange={(e) => {
                                    handlechangephone(e);
                                  }}
                                />
                               
                              </div>
                              <div className="col-md-6 mb-3 ">
                              
                                <label htmlFor> OTP <span className="text-danger">*</span></label>
                                <input
                                  className="form-control"
                                  placeholder="Enter OTP"
                                  name="otp"
                                  value={forms.otp}
                                  onChange={(e) => {
                                    handlechangeotp(e);
                                  }}
                                  type="number"
                                  required
                                />
                               
                              </div>
                            </div>
                            <div className="row mb-2">
                              <div className="col-6"></div>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-6">
                                    {timeshow == true ? (
                                      <span>{time} seconds</span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-6">
                                    {resends == true ? (
                                      <div
                                        type="button"
                                        onClick={() => {
                                          resendotps();
                                        }}
                                        style={{ float: "right" }}
                                        className="mb-2"
                                      >
                                        Resend Otp
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3 ">
                            <label htmlFor>Date of Birth<span className="text-danger">*</span></label>
                            <input
                              className="form-control"
                              placeholder="Enter DOB"
                              type="date"
                              max={new Date(moment().subtract(18, "years")).toISOString().slice(0, -14)}
                              required
                              name="dateOfBirth"
                              value={forms.dateOfBirth}
                              onChange={(e) => {
                                handlechangedate(e);
                              }}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor> Salaried <span className="text-danger">*</span></label>
                          
                            <select
                              name="emplyementStatus"
                              value={forms.emplyementStatus}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              className="form-control"
                              required
                            >
                              <option value="Salaried">Salaried</option>
                              <option value="Self-Employed"> Self-Employed</option>
                            </select>
                           
                          </div>
                          <div className="col-md-6 mb-3">
                           
                            <label>Purpose of Loan <span className="text-danger">*</span></label>
                            <select
                              className="form-control"
                              name="loanPurpose"
                              value={forms.loanPurpose}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              required
                            >
                              <option value="">Select Purpose of loan</option>
                              <option value="Home Loans">Home Loans </option>
                              <option value="Plot Loans">Plot Loans</option>
                              <option value="Home Loans For NRI">Home Loans For NRI</option>
                              <option value="Home Loans For Pensioners">Home Loans For Pensioners</option>
                              <option value="Rutal Home Loans">Rutal Home Loans</option>
                              <option value="Home Loans Takover & Top Up">Home Loans Takover & Top Up</option>
                              <option value="House Renovation Loan">House Renovation Loan</option>
                              <option value="Home Extention Loan">Home Extention Loan</option>
                              <option value="Top Up Loan">Top Up Loan</option>
                              <option value="Home Constraction Loan">Home Constraction Loan</option>
                              <option value="Loan Against Property">Loan Against Property</option>
                              <option value="Commercial Property Loan">Commercial Property Loan</option>
                              <option value="Commercial Plot Loan">Commercial Plot Loan</option>
                              <option value="Home Loan Balance Transfer">Home Loan Balance Transfer</option>
                              <option value="Personal Loan">Personal Loan</option>
                              <option value="Business Loan">Business Loan</option>
                              <option value="Four Wheeler Loan">Four Wheeler Loan</option>
                             
                            </select>

                          </div>
                          <div className="col-md-6 mb-3">

                            <label>Revenue <span className="text-danger">*</span></label>
                            <select
                              className="form-control"
                              name="revenue"
                              value={forms.revenue}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              required>
                              <option value="Less than 20 Lakhs">
                                Less than 20 Lakhs
                              </option>
                              <option value="20 Lakhs to 50 Lakhs">
                                20 Lakhs to 50 Lakhs
                              </option>
                              <option value="50 lakhs and above">
                                50 lakhs and above
                              </option>
                            </select>

                          </div>
                          {forms.emplyementStatus == "Self-Employed" ? (
                            ""
                          ) : (
                            <div className="col-md-6 mb-3">
                              <label htmlFor>Salary received as <span className="text-danger">*</span></label>
                              <div className="form-check form-check-inline mt-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineRadio1"
                                  defaultValue="Accounttransfer"
                                  name="salaryReceivedAs"
                                  value={forms.salaryReceivedAs}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Account Transfer
                                </label>
                              </div>
                              <div className="form-check form-check-inline mt-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="salaryReceivedAs"
                                  value={forms.salaryReceivedAs}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                  id="inlineRadio2"
                                  defaultValue="cash"
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Cash
                                </label>
                              </div>

                            </div>
                          )}

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor>Property Location <span className="text-danger">*</span></label>
                            <input
                              className="form-control"
                              placeholder="Enter Property Location"
                              required
                              name="propertyLocation"
                              pattern="^[a-zA-Z ]*$"
                              value={forms.propertyLocation}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              type="text" />

                          </div>
                          <div className="col-md-6 mb-3 ">

                            <label htmlFor>City <span className="text-danger">*</span></label>
                            <input
                              className="form-control"
                              placeholder="Enter City"
                              name="city"
                              value={forms.city}
                              pattern="^[a-zA-Z ]*$"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              type="text" required />

                          </div>
                          <div className="col-md-6 mb-3 ">
                            <label htmlFor>Pincode <span className="text-danger">*</span></label>

                            <input
                              className="form-control"
                              placeholder="Enter Pincode"
                              required
                              style={{ marginTop: "5px" }}
                              name="pincode"
                              value={forms.pincode}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              maxLength={6}
                              pattern="^\d{6}$"
                              type="text" />

                          </div>
                          {forms.salarytype == "Self-Employed" ? (
                            <div className="col-md-6 mb-3 ">
                              <label htmlFor>
                                Average Monthly Income <span className="text-danger">*</span>
                              </label>

                              <input
                                className="form-control"
                                placeholder="Enter Average Monthly Income"
                                type="number"
                                required
                                name="avgMontlyIncome"
                                value={forms.avgMontlyIncome}
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                              />

                            </div>
                          ) : (
                            <>

                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Company Name <span className="text-danger">*</span></label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Company Name"
                                  type="text"
                                  required
                                  name="companyName"
                                  pattern="^[a-zA-Z ]*$"
                                  value={forms.companyName}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                />

                              </div>
                              <div className="col-md-6 mb-3 ">

                                <label htmlFor>
                                  Gross fixed monthly salary <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Enter Gross fixed monthly salary"
                                  type="text"

                                  required
                                  name="salary"
                                  value={forms.salary}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                />

                              </div>
                            </>
                          )}

                          
                            <button
                              className="btn-rd123"
                              style={{ width: "100%" }}
                              type="submit"
                            >
                              Check Eligibility{" "}
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                          

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Get Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  getDetails(e);
                }}
              >
                <div>
                  <div className="mb-3">
                    <label>Name <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      pattern="^[a-zA-Z ]*$"
                      name="name"
                      value={forms.name}
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      required
                      className="form-control"
                      placeholder="Enter Your Name"
                    />
                  </div>
                  <div className="mb-3">
                    <label>Mobile Number <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      pattern="[0-9]{10}"
                      maxLength={10}
                      name="mobile"
                      value={forms.mobile}
                      required
                      onChange={(e) => {
                        handlechangephone(e);
                      }}
                      className="form-control"
                      placeholder="Enter Mobile Number"
                    />
                  </div>
                  <div className="mb-3">
                    <label>OTP <span className="text-danger">*</span></label>
                    <input
                      name="otp"
                      required
                      value={forms.otp}
                      onChange={(e) => {
                        handlechangeotp(e);
                      }}
                      type="text"
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                  </div>
                  <div className="row mb-2">
                    <div className="col-6">
                      {timeshow == true ? <span>{time} seconds</span> : ""}
                    </div>
                    <div className="col-6">
                      {resends == true ? (
                        <div
                          type="button"
                          onClick={() => {
                            resendotps();
                          }}
                          style={{ float: "right" }}
                          className="mb-2"
                        >
                          Resend Otp
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    className="btn-rd123 mb-3 mt-2"
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="staticBackdrop1"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <form
            onSubmit={(e) => {
              applyLoan(e);
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel1">
                  Apply For Loan
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body">
                <div>
                  <div className="tab-content" id="pills-tabContent">
                    {formshow == false ? (
                      <div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Customer Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                placeholder="Customer Name"
                                pattern="^[a-zA-Z ]*$"
                                name="name"
                                value={forms1.name}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Date of birth{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                max={new Date(moment().subtract(18, "years")).toISOString().slice(0, -14)}
                                placeholder=" Date of birth"
                                name="dateOfBirth"
                                value={forms1.dateOfBirth}
                                required
                                onChange={(e) => {
                                  handlechangedate1(e);
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>Mobile Number <span className="text-danger">*</span></label>
                              <input
                                type="text"
                                pattern="[0-9]{10}"
                                maxLength={10}
                                name="mobile"
                                value={forms1.mobile}
                                required
                                onChange={(e) => {
                                  handlechangephone1(e);
                                }}
                                className="form-control"
                                placeholder="Enter Mobile Number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>OTP <span className="text-danger">*</span> </label>
                              <input
                                name="otp"
                                value={forms1.otp}
                                onChange={(e) => {
                                  handlechangeotp1(e);
                                }}
                                type="text"
                                required
                                className="form-control"
                                placeholder="Enter OTP"
                              />
                            </div>
                          </div>

                          <div className="col-6">
                            {timeshow1 == true ? (
                              <span>{time} seconds</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-6">
                            {resends1 == true ? (
                              <div
                                type="button"
                                onClick={() => {
                                  resendotps1();
                                }}
                                style={{ float: "right" }}
                                className="mb-2"
                              >
                                Resend Otp
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Gender <span className="text-danger">*</span>
                              </label>
                              <select
                                name="gender"
                                required
                                value={forms1.gender}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control"
                              >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                Type of Employment{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="employmentType"
                                required
                                value={forms1.employmentType}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control"
                              >
                                <option value="">
                                  Select Type of Employment
                                </option>
                                <option value="Salaried">Salaried</option>
                               
                                <option value="Business">Business</option>
                                <option value="Self_Employee">Self Employee</option>
                                
                              </select>
                            </div>
                          </div>

                          {forms1.employmentType == "Salaried" ||
                            forms1.employmentType == "Professional" ? (
                            <>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Company Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="Company Name"
                                    name="companyName"
                                    pattern="^[a-zA-Z ]*$"
                                    value={forms1.companyName}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Company Type
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    name="companyType"
                                    value={forms1.companyType}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  >
                                    <option value=""> Select </option>
                                    <option value="Government">
                                      Government
                                    </option>
                                    <option value="Public Sector">
                                      Public Sector
                                    </option>
                                    <option value="Multinational Company">
                                      Multinational Company
                                    </option>
                                    <option value="Public Ltd. (Listed)">
                                      Public Ltd. (Listed)
                                    </option>
                                    <option value="Private Limited">
                                      Private Limited
                                    </option>
                                    <option value="Educational Institution">
                                      Educational Institution
                                    </option>
                                    <option value="N.G.O.">N.G.O.</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Take Home Salary (Monthly)
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="Take Home Salary (Monthly)"
                                    name="takeHomeSalary"
                                    value={forms1.takeHomeSalary}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                   Gross Monthly Income
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="Variable Monthly Income"
                                    name="variableMontlySalary"
                                    value={forms1.variableMontlySalary}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Other Monthly Income
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="Other Monthly Income"
                                    name="otherMonthlySalary"
                                    value={forms1.otherMonthlySalary}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Age of retirement
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Age of retirement"
                                    name="retrirementAge"
                                    value={forms1.retrirementAge}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    EMI's paid towards existing loans
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="EMI's paid towards existing loans"
                                    name="existingEMIPay"
                                    value={forms1.existingEMIPay}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 mb-4 ">
                                <div className="">
                                  <label htmlFor>Bank Statements</label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                  <input
                                    type="file"
                                    required
                                    name="bankStatement"
                                    className="form-control"
                                    onChange={(e) => {
                                      filechange10(e);
                                    }}
                                    value={Files10.bankStatement}
                                  />

                                </div>
                              </div>
                              <div className="col-md-6 mb-4 ">
                                <label htmlFor>Aadhar Card</label> <span className="text-danger">(jpg, png, jpeg)</span>
                                <input
                                  className="form-control"
                                  type="file"
                                  placeholder=""
                                  required
                                  name="aadharCard"
                                  onChange={(e) => {
                                    filechange11(e);
                                  }}
                                  value={Files11.aadharCard}
                                />

                              </div>
                              <div className="col-md-6 mb-4 ">
                              
                                <label htmlFor>Pan Card</label> <span className="text-danger">(jpg, png, jpeg)</span>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                  required
                                  name="panCard"
                                  onChange={(e) => {
                                    filechange12(e);
                                  }}
                                  value={Files12.panCard}

                                />
                               
                              </div>
                              <div className="col-md-6 mb-4 ">
                                <label htmlFor>Salary Slips</label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>

                                <input
                                  type="file"
                                  placeholder=""
                                  className="form-control"
                                  required
                                  name="salarySlip"
                                  onChange={(e) => {
                                    filechange13(e);
                                  }}
                                  value={Files13.salarySlip}
                                />

                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {forms1.employmentType == "Others" ||
                            forms1.employmentType == "Business" ||
                            forms1.employmentType == "Self_Employee" ? (
                            <>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Other Monthly Income
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="Other Monthly Income"
                                    name="otherMonthlySalary"
                                    value={forms1.otherMonthlySalary}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    EMI's paid towards existing loans
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="EMI's paid towards existing loans"
                                    name="existingEMIPay"
                                    value={forms1.existingEMIPay}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mb-4">
                                <label htmlFor> It Returns</label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                <input
                                  type="file"
                                  placeholder=""
                                  required
                                  className="form-control"
                                  name="itReturn"
                                  onChange={(e) => {
                                    filechange1(e);
                                  }}
                                  value={Files1.itReturn}
                                />

                              </div>
                              <div className="col-md-6 mb-4">
                                <label htmlFor>Bank Statements</label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>

                                <input
                                  type="file"
                                  placeholder=""
                                  className="form-control"
                                  required
                                  name="bankStatement"
                                  onChange={(e) => {
                                    filechange10(e);
                                  }}
                                  value={Files10.bankStatement}
                                />

                              </div>
                              <div className="col-md-6 mb-4">
                                <label htmlFor>Aadhar Card</label> <span className="text-danger">(jpg, png, jpeg)</span>

                                <input
                                  type="file"
                                  placeholder=""
                                  required
                                  className="form-control"
                                  name="aadharCard"
                                  onChange={(e) => {
                                    filechange11(e);
                                  }}
                                  value={Files11.aadharCard}
                                />

                              </div>
                              <div className="col-md-6 mb-4">
                                <label htmlFor>Pan Card</label> <span className="text-danger">(jpg, png, jpeg)</span>

                                <input
                                  type="file"
                                  placeholder=""
                                  required
                                  className="form-control"
                                  name="panCard"
                                  onChange={(e) => {
                                    filechange12(e);
                                  }}
                                  value={Files12.panCard}

                                />

                              </div>
                            </>
                          ) : (
                            ""
                          )}

                         {forms1.employmentType == "Business" || forms1.employmentType == "Self_Employee" ? (
                          <div className="container">
                            <button
                              
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-profile"
                              role="tab"
                              type="button"
                              className="btn-rd123 mb-3 mt-2"
                              style={{ width: "100%" }}
                              onClick={handleNext}
                            >
                              Next{" "}
                              <i
                                class="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                            ):(
                              <div className="container">
                            <button
                             
                              id="pills-profile-tab"
                              data-toggle="pill"
                              href="#pills-profile"
                              role="tab"
                              type="button"
                              className="btn-rd123 mb-3 mt-2"
                              style={{ width: "100%" }}
                              onClick={handleNext2}
                            >
                              Next{" "}
                              <i
                                class="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                            )}
                         
                        </div>
                      </div>

                    ) : (
                      <div>
                        <div className="row">
                         

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Purpose of Loan{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="loanPurpose"
                                value={forms1.loanPurpose}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control"
                              >
                                <option value=""> Select Purpose of Loan</option>
                                <option value="Home Loans">Home Loans </option>
                                <option value="Plot Loans">Plot Loans</option>
                                <option value="Home Loans For NRI">Home Loans For NRI</option>
                                <option value="Home Loans For Pensioners">Home Loans For Pensioners</option>
                                <option value="Rutal Home Loans">Rutal Home Loans</option>
                                <option value="Home Loans Takover & Top Up">Home Loans Takover & Top Up</option>
                                <option value="House Renovation Loan">House Renovation Loan</option>
                                <option value="Home Extention Loan">Home Extention Loan</option>
                                <option value="Top Up Loan">Top Up Loan</option>
                                <option value="Home Constraction Loan">Home Constraction Loan</option>
                                <option value="Loan Against Property">Loan Against Property</option>
                                <option value="Commercial Property Loan">Commercial Property Loan</option>
                                <option value="Commercial Plot Loan">Commercial Plot Loan</option>
                                <option value="Home Loan Balance Transfer">Home Loan Balance Transfer</option>
                                <option value="Personal Loan">Personal Loan</option>
                                <option value="Business Loan">Business Loan</option>
                                <option value="Four Wheeler Loan">Four Wheeler Loan</option>
                              </select>
                            </div>
                          </div>

                          {forms1.loanPurpose == "Construction of House" ||
                            forms1.loanPurpose == "Purchase of Plot" ? (
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label>
                                  {forms1.loanPurpose}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  placeholder={forms1.loanPurpose}
                                  name="construction"
                                  value={forms1.construction}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Cost of Property
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    placeholder="Cost of Property"
                                    name="plotCost"
                                    value={forms1.plotCost}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                             
                            </>
                          )}

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Residential Status{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="residentialStatus"
                                value={forms1.residentialStatus}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control">
                                <option value="">Select Residential</option>
                                <option value="Resident">Resident</option>
                                <option value="Non_resident">Non-resident</option>
                              </select>
                              
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                Any Other Loans ?{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                name="takenLoan"
                                value={forms1.takenLoan}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control"
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>

                          {forms1.takenLoan == "Yes" ? (
                            <>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Date on Loan taken
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="date"
                                    placeholder="Residential Status"
                                    name="dateOfLoanTaken"
                                    value={forms1.dateOfLoanTaken}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Tenure of loan (in months)
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Tenure of loan (in months)"
                                    name="loanTenure"
                                    value={forms1.loanTenure}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    {" "}
                                    Financial Institution
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    name="financialInstitute"
                                    placeholder="Enter Bank Name"
                                    value={forms1.financialInstitute}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                 
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-4">
                                  <label>
                                    Outstanding Balance
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Outstanding Balance"
                                    name="outStandingBalannce"
                                    value={forms1.outStandingBalannce}
                                    onChange={(e) => {
                                      handlechange1(e);
                                    }}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                State <span className="text-danger">*</span>
                              </label>
                              <select
                                name="state"
                                value={forms1.state}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control"
                              >
                                <option value="">Select</option>
                                {states.map((data) => (
                                  <option value={data.stateName}>
                                    {data.stateName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label>
                                {" "}
                                City <span className="text-danger">*</span>
                              </label>
                              <input
                                placeholder="City"
                                name="city"
                                value={forms1.city}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>

                          

                          <div className="container">
                            <div className="mb-3 text-primary">
                              <a
                                type="button"
                                onClick={() => {
                                  setformshow(!formshow);
                                }}
                              >
                                <i
                                  class="fa fa-long-arrow-left"
                                  aria-hidden="true"
                                ></i>{" "}
                                Previous
                              </a>
                            </div>

                          
                              <button
                                type="submit"
                                className="btn-rd123 mb-3 mt-2"
                                style={{ width: "100%" }}
                              >
                                Submit
                              </button>
                           

                          </div>

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> */}



      <div
        id="modal_aside"
        className="modal fixed-center fade"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-lg" role="document" >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">GET ELIGIBILITY</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row  mt-3">
                <div className="col-md-12">
                  <div>
                    <div className="container">
                      <form
                        onSubmit={(e) => {
                          getEligibility(e);
                        }}
                      >
                        <div className="row mb-4">
                          <div className="col-md-6 mb-3 ">
                            <label htmlFor> Name <span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Name"
                              value={forms.name}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              pattern="^[a-zA-Z ]*$"
                              required
                            />

                          </div>

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor> Email <span className="text-danger">*</span></label>
                            <input
                              name="email"
                              placeholder="Email"
                              className="form-control"
                              value={forms.email}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              type="email" required />

                          </div>

                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor> Mobile Number <span className="text-danger">*</span></label>
                                <input
                                  type="text" pattern="[0-9]{10}"
                                  maxLength={10}
                                  name="mobile"
                                  placeholder=" Mobile Number "
                                  className="form-control"
                                  value={forms.mobile}
                                  required
                                  onChange={(e) => {
                                    handlechangephone(e);
                                  }}
                                />

                              </div>


                              <div className="col-md-6 mb-3  ">
                                <label htmlFor> OTP <span className="text-danger">*</span></label>
                                <input
                                  name="otp"
                                  placeholder="OTP"
                                  className="form-control"
                                  value={forms.otp}
                                  onChange={(e) => {
                                    handlechangeotp(e);
                                  }}
                                  type="number"
                                  required
                                />

                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6"></div>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-6">
                                    {timeshow == true ? (
                                      <span>{time} seconds</span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-6">
                                    {resends == true ? (
                                      <div
                                        type="button"
                                        onClick={() => {
                                          resendotps();
                                        }}
                                        style={{ float: "right" }}
                                        className=""
                                      >
                                        Resend Otp
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="col-md-6 mb-3 ">
                            <label htmlFor> Date Of Birth <span className="text-danger">*</span></label>
                            <div className="">
                              <input
                                placeholder="Enter DOB"
                                type="date"
                                className="form-control"
                                max={new Date(moment().subtract(18, "years")).toISOString().slice(0, -14)}
                                required
                                name="dateOfBirth"
                                value={forms.dateOfBirth}
                                onChange={(e) => {
                                  handlechangedate(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor>Type of Employment <span className="text-danger">*</span></label>
                            <select
                              name="emplyementStatus"
                              value={forms.emplyementStatus}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              required
                              className="form-control"
                            ><option value="">Select Type of Employment</option>
                              <option value="Salaried">Salaried</option>
                              <option value="Self-Employed"> Self-Employed</option>
                              <option value="Business">Business</option>
                            </select>
                          </div>

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor> Any Previous Loans <span className="text-danger">*</span></label>
                            <input
                              name="anyPreviousLoans"
                              placeholder="Any Previous Loans"
                              className="form-control"
                              value={forms.anyPreviousLoans}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              type="text"
                              required
                            />

                          </div>

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor> Purpose of loan <span className="text-danger">*</span></label>
                            <select
                              name="loanPurpose"
                              value={forms.loanPurpose}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              required
                              className="form-control"
                            >
                              <option value="">Select Purpose of loan</option>
                              <option value="Home Loans">Home Loans </option>
                              <option value="Plot Loans">Plot Loans</option>
                              <option value="Home Loans For NRI">Home Loans For NRI</option>
                              <option value="Home Loans For Pensioners">Home Loans For Pensioners</option>
                              <option value="Rutal Home Loans">Rutal Home Loans</option>
                              <option value="Home Loans Takover & Top Up">Home Loans Takover & Top Up</option>
                              <option value="House Renovation Loan">House Renovation Loan</option>
                              <option value="Home Extention Loan">Home Extention Loan</option>
                              <option value="Top Up Loan">Top Up Loan</option>
                              <option value="Home Constraction Loan">Home Constraction Loan</option>
                              <option value="Loan Against Property">Loan Against Property</option>
                              <option value="Commercial Property Loan">Commercial Property Loan</option>
                              <option value="Commercial Plot Loan">Commercial Plot Loan</option>
                              <option value="Home Loan Balance Transfer">Home Loan Balance Transfer</option>
                              <option value="Personal Loan">Personal Loan</option>
                              <option value="Business Loan">Business Loan</option>
                              <option value="Four Wheeler Loan">Four Wheeler Loan</option>
                            </select>

                          </div>


                          <div className="col-md-6 mb-3 ">
                            <label htmlFor> Loan Required <span className="text-danger">*</span></label>
                            <select
                              name="revenue"
                              value={forms.revenue}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              className="form-control"
                              required>
                              <option value="">
                                Select Loan Required
                              </option>
                              <option value="Less than 20 Lakhs">
                                Less than 20 Lakhs
                              </option>
                              <option value="20 Lakhs to 50 Lakhs">
                                20 Lakhs to 50 Lakhs
                              </option>
                              <option value="50 lakhs and above">
                                50 lakhs and above
                              </option>
                            </select>

                          </div>
                          {forms.emplyementStatus == "Self-Employed" || forms.emplyementStatus == "Business" ? (
                            ""
                          ) : (
                            <div className="col-md-6 mb-3">
                              <label htmlFor>Salary received as <span className="text-danger">*</span></label>
                              <br></br>
                              <div className="form-check form-check-inline mt-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  id="inlineRadio1"
                                  defaultValue="Accounttransfer"
                                  name="salaryReceivedAs"
                                  value={forms.salaryReceivedAs}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Account Transfer
                                </label>
                              </div>
                              <div className="form-check form-check-inline mt-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="salaryReceivedAs"
                                  value={forms.salaryReceivedAs}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                  id="inlineRadio2"
                                  defaultValue="cash"
                                  required
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Cash
                                </label>
                              </div>

                            </div>
                          )}

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor>Property Location <span className="text-danger">*</span></label>
                            <input
                              required
                              placeholder="Property Location"
                              className="form-control"
                              name="propertyLocation"
                              pattern="^[a-zA-Z ]*$"
                              value={forms.propertyLocation}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              type="text" />

                          </div>

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor>City <span className="text-danger">*</span></label>
                            <input
                              name="city"
                              placeholder="City"
                              className="form-control"
                              value={forms.city}
                              pattern="^[a-zA-Z ]*$"
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              type="text" required />

                          </div>

                          <div className="col-md-6 mb-3 ">
                            <label htmlFor>Enter Pincode <span className="text-danger">*</span></label>
                            <input
                              placeholder="Enter Pincode"
                              required
                              className="form-control"
                              name="pincode"
                              value={forms.pincode}
                              onChange={(e) => {
                                handlechange(e);
                              }}
                              maxLength={6}
                              pattern="^\d{6}$"
                              type="text" />

                          </div>

                          {/* {forms.salarytype == "Self-Employed" ? (
                            <div className="col-md-6 mb-3  ">
                              <label htmlFor>Enter Average Monthly Income <span className="text-danger">*</span></label>
                              <input
                                placeholder="Enter Average Monthly Income"
                                type="number"
                                className="form-control"
                                required
                                name="avgMontlyIncome"
                                value={forms.avgMontlyIncome}
                                onChange={(e) => {
                                  handlechange(e);
                                }}
                              />

                            </div>
                          ) : (
                          ""
                          )} */}

                          {forms.emplyementStatus == "Self-Employed" || forms.emplyementStatus == "Business" ? (
                            <>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Enter Company Name <span className="text-danger">*</span></label>

                                <input

                                  placeholder="Enter Company Name"
                                  type="text"
                                  required
                                  className="form-control"
                                  name="companyName"
                                  pattern="^[a-zA-Z ]*$"
                                  value={forms.companyName}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                />

                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Enter Gross fixed monthly income <span className="text-danger">*</span></label>
                                <input
                                  placeholder="Enter Gross fixed monthly income"
                                  type="text"
                                  required
                                  className="form-control"
                                  name="salary"
                                  value={forms.salary}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                />

                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Enter Company Name <span className="text-danger">*</span></label>

                                <input

                                  placeholder="Enter Company Name"
                                  type="text"
                                  required
                                  className="form-control"
                                  name="companyName"
                                  pattern="^[a-zA-Z ]*$"
                                  value={forms.companyName}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                />

                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Enter Gross fixed monthly salary <span className="text-danger">*</span></label>
                                <input
                                  placeholder="Enter Gross fixed monthly salary"
                                  type="text"
                                  required
                                  className="form-control"
                                  name="salary"
                                  value={forms.salary}
                                  onChange={(e) => {
                                    handlechange(e);
                                  }}
                                />

                              </div>
                            </>

                          )}


                          <button
                            className="btn-rd123"
                            style={{ width: "100%" }}
                            type="submit"
                          >
                            Check Eligibility{" "}
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Get Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={(e) => {
                  getDetails(e);
                }}
              >
                <div>
                  <div className="container">
                    <div className="row mb-4">
                      <div className="col-md-12 mb-3 mt-3 ">


                        <label>Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          pattern="^[a-zA-Z ]*$"
                          name="name"
                          value={forms.name}
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          required

                          className="form-control"
                          placeholder="Name"
                        />

                      </div>


                      <div className=" col-md-12 mb-3 ">

                        <label>Mobile Number <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          pattern="[0-9]{10}"
                          maxLength={10}
                          name="mobile"
                          value={forms.mobile}
                          required
                          onChange={(e) => {
                            handlechangephone(e);
                          }}
                          className="form-control"
                          placeholder="Mobile Number"
                        />

                      </div>


                      <div className=" col-md-12 mb-3 ">
                        <label>OTP <span className="text-danger">*</span></label>

                        <input
                          name="otp"
                          required
                          value={forms.otp}
                          onChange={(e) => {
                            handlechangeotp(e);
                          }}
                          type="text"
                          className="form-control"
                          placeholder="OTP"
                        />

                      </div>


                      <div className=" col-md-12">
                        <div className="row mb-2">
                          <div className="col-6">
                            {timeshow == true ? <span>{time} seconds</span> : ""}
                          </div>
                          <div className="col-6 text-white">
                            {resends == true ? (
                              <div
                                type="button"
                                onClick={() => {
                                  resendotps();
                                }}

                                className="mb-2"
                              >
                                Resend Otp
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn-rd123 mb-3 mt-2"
                        style={{ width: "100%" }}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div></div></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="staticBackdrop1"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <form
            onSubmit={(e) => {
              applyLoan(e);
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel1">
                  Apply For Loan
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body mt-3">
                <div>
                  <div className="tab-content" id="pills-tabContent">
                    {formshow == false ? (
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-6 mb-3 ">
                            <label htmlFor>Customer Name<span className="text-danger">*</span></label>
                            <input
                              className="form-control"
                              pattern="^[a-zA-Z ]*$"
                              name="name"
                              value={forms1.name}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              required
                              placeholder="Customer Name"
                            />

                          </div>

                          <div className="col-lg-6 mb-3">
                            <label htmlFor>Date Of Birth<span className="text-danger">*</span></label>
                            <div className=" ">
                              <input
                                type="date"
                                max={new Date(moment().subtract(18, "years")).toISOString().slice(0, -14)}
                                placeholder=" Date Of Birth"
                                name="dateOfBirth"
                                value={forms1.dateOfBirth}
                                required

                                onChange={(e) => {
                                  handlechangedate1(e);
                                }}
                                className="form-control"
                              />

                            </div>

                          </div>

                          <div className="col-lg-6 mb-3 ">
                            <div className="">
                              <label>Mobile Number <span className="text-danger">*</span></label>

                              <input
                                type="text"
                                placeholder="Mobile Number"
                                pattern="[0-9]{10}"
                                maxLength={10}
                                name="mobile"
                                value={forms1.mobile}
                                required

                                onChange={(e) => {
                                  handlechangephone1(e);
                                }}
                                className="form-control"
                              />

                            </div>
                          </div>
                          <div className="col-lg-6 mb-3 ">
                            <div className="">
                              <label>OTP <span className="text-danger">*</span></label>

                              <input
                                name="otp"
                                value={forms1.otp}
                                onChange={(e) => {
                                  handlechangeotp1(e);
                                }}
                                type="text"
                                required
                                placeholder="OTP"
                                className="form-control"
                              />

                            </div>
                          </div>

                          <div className="col-6">
                            {timeshow1 == true ? (
                              <span>{time} seconds</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-6">
                            {resends1 == true ? (
                              <div
                                type="button"
                                onClick={() => {
                                  resendotps1();
                                }}
                                style={{ float: "right" }}
                                className="mb-2"
                              >
                                Resend Otp
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="col-lg-6 mb-3 ">

                            <label htmlFor> Gender</label> <span className="text-danger">*</span>
                            <select
                              name="gender"
                              required
                              value={forms1.gender}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              className="form-control"
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>

                          </div>
                          {/* <div className="col-lg-6 mb-3 ">

                            <label htmlFor> Type Of Residence</label> <span className="text-danger">*</span>
                            <select
                              name="typeOfResidence"
                              required
                              value={forms1.typeOfResidence}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              className="form-control"
                            >
                              <option value="">Select  Residence</option>
                              <option value="NRI">NRI</option>
                              <option value="INDIA">INDIA</option>
                            </select>

                          </div> */}


                          <div className="col-lg-6 mb-3 ">
                            <label htmlFor> Type of Employment</label> <span className="text-danger">*</span>
                            <select
                              name="employmentType"
                              required
                              value={forms1.employmentType}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              className="form-control"
                            >
                              <option value="">
                                Select Type of Employment
                              </option>
                              <option value="Salaried">Salaried</option>
                              <option value="Business">Business</option>
                              <option value="Self_Employee">Self Employee</option>
                            </select>

                          </div>

                          {forms1.employmentType == "Salaried" ||
                            forms1.employmentType == "Professional" ? (
                            <>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>
                                  Company Name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="companyName"
                                  pattern="^[a-zA-Z ]*$"
                                  value={forms1.companyName}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  placeholder=" Company Name"
                                  required
                                  className="form-control"
                                />

                              </div>

                              <div className="col-lg-6 mb-3 ">
                                <label htmlFor>  Company Type</label> <span className="text-danger">*</span>
                                <select
                                  name="companyType"
                                  value={forms1.companyType}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  className="form-control"
                                >
                                  <option value=""> Select  Company Type</option>
                                  <option value="Government">
                                    Government
                                  </option>
                                  <option value="Public Sector">
                                    Public Sector
                                  </option>
                                  <option value="Multinational Company">
                                    Multinational Company
                                  </option>
                                  <option value="Public Ltd. (Listed)">
                                    Public Ltd. (Listed)
                                  </option>
                                  <option value="Private Limited">
                                    Private Limited
                                  </option>
                                  <option value="Educational Institution">
                                    Educational Institution
                                  </option>
                                  <option value="N.G.O.">N.G.O.</option>
                                </select>

                              </div>

                              <div className="col-lg-6 mb-3 ">
                                <label>
                                  Gross Monthly Income
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Gross Monthly Income"
                                  name="variableMontlySalary"
                                  value={forms1.variableMontlySalary}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required type="number"
                                />

                              </div>

                              <div className="col-lg-6 mb-3 ">
                                <label>
                                  Take Home Salary (Monthly)
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  placeholder="Take Home Salary (Monthly)"
                                  name="takeHomeSalary"
                                  value={forms1.takeHomeSalary}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required type="number"

                                />

                              </div>

                              <div className="col-lg-6 mb-3 ">
                                <label htmlFor> Monthly Income<span className="text-danger">*</span></label>
                                <input
                                  className="form-control"
                                  name="otherMonthlySalary"
                                  value={forms1.otherMonthlySalary}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }} required
                                  placeholder="Monthly Income" type="number"
                                />

                              </div>


                              <div className="col-lg-6 mb-3 ">
                                <label htmlFor> Age of retirement<span className="text-danger">*</span></label>
                                <input
                                  type="number"
                                  required
                                  name="retrirementAge"
                                  value={forms1.retrirementAge}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  className="form-control"
                                  placeholder="Age of retirement"
                                />


                              </div>


                              {/* <div className="col-lg-6 mb-3 ">
                                <label htmlFor> EMI's paid towards existing loans<span className="text-danger">*</span></label>
                                <input
                                  type="text"
                                  name="existingEMIPay"
                                  value={forms1.existingEMIPay}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required
                                  className="form-control"
                                  placeholder=" EMI's paid towards existing loans"
                                />

                              </div> */}


                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Bank Statements <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                <div className=" ">
                                  <input
                                    type="file"
                                    required
                                    name="bankStatement"
                                    className="form-control"
                                    onChange={(e) => {
                                      filechange10(e);
                                    }}
                                    value={Files10.bankStatement}
                                    placeholder="Bank Statements"
                                  />

                                </div>

                              </div>

                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Aadhar Card <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg)</span>
                                <div className="">
                                  <input
                                    type="file"
                                    placeholder="Aadhar Card"
                                    required
                                    name="aadharCard"
                                    onChange={(e) => {
                                      filechange11(e);
                                    }}
                                    value={Files11.aadharCard}
                                    className="form-control"

                                  /></div>

                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Pan Card <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg)</span>
                                <div className=" ">
                                  <input
                                    type="file"
                                    className="form-control"

                                    required
                                    name="panCard"
                                    onChange={(e) => {
                                      filechange12(e);
                                    }}
                                    value={Files12.panCard}
                                    placeholder="Pan Card"

                                  /></div>

                              </div>
                              <div className="col-md-6 mb-3 ">
                                <label htmlFor className="mb-3">Salary Slips <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                <div className=" ">
                                  <input
                                    type="file"
                                    placeholder="Salary Slips"
                                    className="form-control"
                                    required
                                    name="salarySlip"
                                    onChange={(e) => {
                                      filechange13(e);
                                    }}
                                    value={Files13.salarySlip}
                                  /></div>

                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {forms1.employmentType == "Others" ||
                            forms1.employmentType == "Business" ||
                            forms1.employmentType == "Self_Employee" ? (
                            <>
                              <div className="col-lg-6 mb-3 ">
                                <label>
                                  Monthly Income
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="otherMonthlySalary"
                                  value={forms1.otherMonthlySalary}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required type="number"
                                  className="form-control"
                                  placeholder=" Monthly Income"
                                />

                              </div>

                              {/* <div className="col-lg-6 mb-3 ">
                                <label>
                                  EMI's paid towards existing loans
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="existingEMIPay"
                                  value={forms1.existingEMIPay}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required
                                  placeholder="EMI's paid towards existing loans"
                                  className="form-control"
                                />

                              </div> */}


                              <div className="col-md-6 mb-3">
                                <label htmlFor> IT Returns <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                <div className="">
                                  <input
                                    type="file"
                                    required
                                    name="itReturn"
                                    placeholder=" IT Returns"
                                    onChange={(e) => {
                                      filechange1(e);
                                    }}
                                    value={Files1.itReturn}
                                    className="form-control"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Bank Statements <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                <div className="">
                                  <input
                                    type="file"
                                    required
                                    name="bankStatement"
                                    placeholder="Bank Statements"
                                    onChange={(e) => {
                                      filechange10(e);
                                    }}
                                    value={Files10.bankStatement}
                                    className="form-control"
                                  />

                                </div></div>

                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Aadhar Card <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg)</span>
                                <div className="">
                                  <input
                                    type="file"
                                    required
                                    name="aadharCard"
                                    onChange={(e) => {
                                      filechange11(e);
                                    }}
                                    value={Files11.aadharCard}
                                    className="form-control"
                                    placeholder="Aadhar Card"
                                  />

                                </div>
                              </div>

                              <div className="col-md-6 mb-3 ">
                                <label htmlFor>Pan Card <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg)</span>
                                <div className="">
                                  <input
                                    type="file"
                                    required
                                    name="panCard"
                                    onChange={(e) => {
                                      filechange12(e);
                                    }}
                                    value={Files12.panCard}
                                    className="form-control"
                                    placeholder="Pan Card"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {forms1.employmentType == "Business" || forms1.employmentType == "Self_Employee" ? (
                            <div className="container">
                              <button

                                id="pills-profile-tab"
                                data-toggle="pill"
                                href="#pills-profile"
                                role="tab"
                                type="button"
                                className="btn-rd123 mb-3 mt-2"
                                style={{ width: "100%" }}
                                onClick={handleNext}
                              >
                                Next{" "}
                                <i
                                  class="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          ) : (
                            <div className="container">
                              <button

                                id="pills-profile-tab"
                                data-toggle="pill"
                                href="#pills-profile"
                                role="tab"
                                type="button"
                                className="btn-rd123 mb-3 mt-2"
                                style={{ width: "100%" }}
                                onClick={handleNext2}
                              >
                                Next{" "}
                                <i
                                  class="fa fa-long-arrow-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          )}

                        </div>
                      </div>

                    ) : (
                      <div>
                        <div className="row">


                          <div className="col-lg-6 mb-3 ">
                            <label htmlFor> Purpose of Loan</label> <span className="text-danger">*</span>
                            <select
                              name="loanPurpose"
                              value={forms1.loanPurpose}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              className="form-control"
                            >
                              <option value=""> Select Purpose of Loan</option>
                              <option value="Home Loans">Home Loans </option>
                              <option value="Plot Loans">Plot Loans</option>
                              <option value="Home Loans For NRI">Home Loans For NRI</option>
                              <option value="Home Loans For Pensioners">Home Loans For Pensioners</option>
                              <option value="Rutal Home Loans">Rutal Home Loans</option>
                              <option value="Home Loans Takover & Top Up">Home Loans Takover & Top Up</option>
                              <option value="House Renovation Loan">House Renovation Loan</option>
                              <option value="Home Extention Loan">Home Extention Loan</option>
                              <option value="Top Up Loan">Top Up Loan</option>
                              <option value="Home Constraction Loan">Home Constraction Loan</option>
                              <option value="Loan Against Property">Loan Against Property</option>
                              <option value="Commercial Property Loan">Commercial Property Loan</option>
                              <option value="Commercial Plot Loan">Commercial Plot Loan</option>
                              <option value="Home Loan Balance Transfer">Home Loan Balance Transfer</option>
                              <option value="Personal Loan">Personal Loan</option>
                              <option value="Business Loan">Business Loan</option>
                              <option value="Four Wheeler Loan">Four Wheeler Loan</option>
                            </select>

                          </div>

                          {/* {forms1.loanPurpose == "Construction of House" ||
                            forms1.loanPurpose == "Purchase of Plot" ? (
                            <div className="col-lg-6 mb-3 ">

                              <label>
                                Other Monthly Income
                                <span className="text-danger">*</span>
                              </label>

                              <input
                                className="form-control"
                                placeholder="Other Monthly Income"
                                name="construction"
                                value={forms1.construction}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                type="number"
                              />
                            </div>
                          ) : (
                            <>
                              <div className="col-lg-6 mb-3  ">

                                <label>
                                  Other Monthly Income
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  className="form-control"
                                  placeholder="Other Monthly Income"
                                  name="plotCost"
                                  value={forms1.plotCost}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required
                                  type="number"
                                />

                              </div>

                            </>
                          )} */}

                          <div className="col-lg-6 mb-3 ">
                            <label htmlFor>  Residential Status</label> <span className="text-danger">*</span>
                            <select
                              name="residentialStatus"
                              value={forms1.residentialStatus}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              required className="form-control"

                            >
                              <option value="">Select Residential Status</option>
                              <option value="Resident">Resident</option>
                              <option value="Non_resident">Non-resident</option>
                            </select>
                          </div>

                          {forms1.residentialStatus == "Non_resident" ?
                            <div className="col-md-6 mb-3 ">
                              <label htmlFor>
                                Country Name
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="country"
                                pattern="^[a-zA-Z ]*$"
                                value={forms1.country}
                                onChange={(e) => {
                                  handlechange1(e);
                                }}
                                placeholder="Country Name"
                                required
                                className="form-control"
                              />

                            </div> : ''}

                          <div className="col-lg-6 mb-3 ">
                            <label htmlFor>Select Any Other Loans</label> <span className="text-danger">*</span>
                            <select
                              name="takenLoan"
                              value={forms1.takenLoan}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              className="form-control"
                            >
                              <option value="">Select Any Other Loans </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>

                          </div>

                          {forms1.takenLoan == "Yes" ? (
                            <>
                              <div className="col-lg-6 mb-3 ">
                                <label htmlFor>Date on Loan taken<span className="text-danger">*</span></label>


                                <input
                                  type="date"
                                  className="form-control"
                                  name="dateOfLoanTaken"
                                  value={forms1.dateOfLoanTaken}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required
                                  placeholder="date"
                                />

                              </div>


                              <div className="col-lg-6 mb-3 ">
                                <label htmlFor>Tenure of loan (in months)<span className="text-danger">*</span></label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="loanTenure"
                                  value={forms1.loanTenure}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required
                                  placeholder="Tenure of loan (in months)"
                                />


                              </div>

                              <div className="col-lg-6 mb-3 ">

                                <label htmlFor>Financial Institution<span className="text-danger">*</span></label>

                                <input
                                  name="financialInstitute"
                                  className="form-control"
                                  value={forms1.financialInstitute}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required
                                  placeholder="Financial Institution"
                                />


                              </div>
                              <div className="col-lg-6 mb-3  ">
                                <label htmlFor> Outstanding Balance<span className="text-danger">*</span></label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="outStandingBalannce"
                                  value={forms1.outStandingBalannce}
                                  onChange={(e) => {
                                    handlechange1(e);
                                  }}
                                  required
                                  placeholder="Outstanding Balance"
                                />

                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="col-lg-6 mb-3 ">
                            <label htmlFor> Select State</label> <span className="text-danger">*</span>
                            <select
                              name="state"
                              value={forms1.state}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              className="form-control"
                            >
                              <option value="">Select State</option>
                              {states.map((data) => (
                                <option value={data.stateName}>
                                  {data.stateName}
                                </option>
                              ))}
                            </select>

                          </div>
                          <div className="col-lg-6 mb-3 ">
                            <label htmlFor>
                              City
                              <span className="text-danger">*</span>
                            </label>

                            <input
                              className="form-control"
                              name="city"
                              required
                              value={forms1.city}
                              onChange={(e) => {
                                handlechange1(e);
                              }}
                              placeholder="City"
                            />


                          </div>



                          <div className="container">
                            <div className="mb-3 text-primary">
                              <a
                                type="button"
                                onClick={() => {
                                  setformshow(!formshow);
                                }}
                              >
                                <i
                                  class="fa fa-long-arrow-left"
                                  aria-hidden="true"
                                ></i>{" "}
                                Previous
                              </a>
                            </div>


                            <button
                              type="submit"
                              className="btn-rd123 mb-3 mt-2"
                              style={{ width: "100%" }}
                            >
                              Submit
                            </button>

                          </div>

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        className="modal fade bd-example-modal-lg"
        id="staticBackdrop123"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                All Images
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-ride="carousel"
              >
                <div className="carousel-inner">
                  {optshow.images.map((imag, key) => (
                    <div
                      key={key}
                      className={`carousel-item ${key === 0 ? "active" : ""}`}
                    >
                      <img

                        src={imgUrl + imag}
                        className="d-block w-100"
                        alt="..."
                        style={{ height: "300px" }}
                      />
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev text-danger"
                  type="button"
                  data-target="#carouselExampleControls"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon text-danger"
                    aria-hidden="true"
                  />
                  <span className="sr-only ">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-target="#carouselExampleControls"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="modal-dialog-centered" size="sm" isOpen={open}>
        <ModalBody className="text-center">
          <img src={successimg} style={{ width: "70%" }} />
          <h5>Thank You!</h5>
          <a className="text-secondary">  Our Team Get Back To You</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>
      <Modal className="modal-dialog-centered" size="sm" isOpen={open1}>
        <ModalBody className="text-center">
          <img src={errorimg} style={{ width: "70%" }} />
          <h5 className="text-danger">Someting Went Wrong.</h5>
          <a className="text-secondary"> Please Check & Try Again</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle1}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>

    </div>
    </DocumentMeta>
  );
}

export default Realestate;

