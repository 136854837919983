import React, { useEffect, useState } from "react";
import Footer from './Footer'
import Contents from './Contents'
import Header from './Header'
import { Link } from 'react-router-dom'
import { addData, addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast"
import backgroundimg from "../assets/images/agents/backgroundimg.jpg"
import { Button, Modal, ModalBody } from 'reactstrap';
import successimg from '../assets/images/reviews/success.png'
import errorimg from '../assets/images/reviews/error.png'
import DocumentMeta from 'react-document-meta';

function About() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => setOpen1(!open1);


  const [banner, setbanner] = useState([]);
  const [banner1, setbanner1] = useState([]);
  const [banner2, setbanner2] = useState([]);

  const getAllbanners = async () => {
    const resonse = await addData("banner/getallabout");
    var _data = resonse;
    setbanner(_data.data.horizontal[0]);
    setbanner2(_data.data.horizontal[1]);
  };
  const getAllbanners1 = async () => {
    const resonse = await addDataadmin("pagebanners/getactive");
    var _data = resonse;
    setbanner1(_data.data.pageBanners[0]);
  };

  useEffect(() => {
    getAllbanners()
    getAllbanners1()
    scrollToTop();
  }, []);


  const [timeshow, settimeshow] = useState(false);
  const [resends, setresends] = useState(false);

  const [forms, setforms] = useState({
    name: "",
    email: "",
    mobile: "",
    service: "",
    type: "",
    opt: "",
    values: "",
    message: "",
  });
  console.log(forms);

  const [time, setTime] = useState(30);
  useEffect(() => {
    const updateTimer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(updateTimer);
  }, []);

  const handlechange = (e) => {
    const myForm = { ...forms };
    myForm[e.target.name] = e.target.value;
    setforms(myForm);
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValues1, setSelectedValues1] = useState('');
  console.log(selectedValues1)

  const handlechange123 = (e) => {
    const myForm = { ...selectedValues };
    myForm[e.target.value] = e.target.checked;
    setSelectedValues(myForm);

    const selectedParkings1 = Object.keys(myForm).filter((key) => myForm[key]);
    const parkingString1 = selectedParkings1.join(", ");
    setSelectedValues1(parkingString1);
  };

  // const sendData = () => {
  //   const formattedData = selectedValues.join(', ');
  //   setSelectedValues1(formattedData)
  //   // Here you can send the formattedData to the desired endpoint or perform further actions
  // };


  const handlechangephone = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputMobile;
    setforms(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends(true)
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps = async () => {
    const bodydata = {
      mobile: forms.mobile,
    };

    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow(true)
      setTime(30);

    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }

  };

  const handlechangeotp = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms };
    myForm[e.target.name] = inputOtp;
    setforms(myForm);

    const bodydata = {
      mobile: forms.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const getyourquote = async (e) => {
    // sendData()
    e.preventDefault();
    const bodydata = {
      name: forms.name,
      email: forms.email,
      otp: forms.otp,
      mobile: forms.mobile,
      service: forms.service,
      type: forms.type,
      values: selectedValues1,
      message: forms.message,
    };
    try {
      const resonse = await addData("expressintrest/add", bodydata);
      var _data = resonse;
      console.log(_data);
      // toast.success(_data.data.message);
      setOpen(true)
      setSelectedValues1('')
      setSelectedValues({ values: "" })
      setforms({
        name: "",
        email: "",
        mobile: "",
        service: "",
        type: "",
        otp: "",
        values: "",
        message: "",
      });
      closeModal();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setOpen1(true)
        // toast.error(error.response.data.message);
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };



  const closeModal = () => {
    const modalElement = document.getElementById("modal_aside");
    const closeButton = modalElement.querySelector(".close");
    modalElement.classList.remove("show");
    modalElement.setAttribute("aria-modal", "false");
    modalElement.setAttribute("style", "display: none");
    document.body.classList.remove("modal-open");
    closeButton.click(); // Simulate a click event on the close button to remove the modal from the DOM
  };

  const meta = {
    title: 'Top loan insurance real estate Agency in Hyd | Right Agent Group',
    description: 'Welcome to Right Agent Group, a service-oriented company that provides exceptional assistance in the real estate, loans, and insurance fields.',
    canonical: 'https://rightagentgroup.com/about',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'insurance agents in hyderabad, top 10 health insurance agent in hyderabad, top 10 insurance agent in kompally, life insurance agent in Miyapur, insurance agents in bachupally, loan agents in hyderabad, personal loan agents in kompally, home loan agents in hyderabad, loan agencies in hyderabad, loan brokers in Miyapur, real estate agency in hyderabad, top 10 real estate agents in kompally, best real estate agents in hyderabad, property agents in bachupally, top real estate agents in Miyapur, commercial rental agencies in hyderabad'
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
    <div>
      <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="100"
        className="ct1280"
      >
        <Header />
        <section
          style={{ paddingTop: "80px" }}
          className="hero-section-1"
          id="home"
        >
          <div
            style={{ background: `url(${imgUrl + banner1.image})`, opacity: 0.8, backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundRepeat: "no-repeat" }}
            className="text-center"
          >
            <div className="headst">
              <div className="text-white ">
                <h1 >About Us</h1>
                {/* <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6"> <p style={{ fontSize: "20px" }}>{banner1.description}</p></div>
                  <div className="col-lg-3"></div>
                </div> */}

                {/* <p style={{fontSize:"20px"}}>A Insurances is a debt incurred by an individual or some entity.</p> */}
              </div>
            </div>
            {/* <Link to="/">Home</Link> / <span>Loans</span> */}
          </div>
        </section>
        {/* About */}
        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-6 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                  About of <em>Right Agent Group</em> Overview
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>
                  <b style={{textAlign:"justify"}}>Welcome to Right Agent Group, a service-oriented company that provides exceptional assistance in the real estate, loans, and insurance fields</b>

                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    At Right Agent Group, we understand the importance of finding the right professionals to guide you through your real estate journey, secure a loan that suits your needs, and protect your investments with comprehensive insurance coverage.
                  </p>
                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    Our commitment to excellence and client satisfaction sets us apart in the industry, as we strive to provide personalized and high-quality services tailored to meet your specific requirements.                   </p>
                  <a
                    href="#"
                    className="btnpora btn-rd2 mt40"
                    data-aos="fade-up"
                    data-aos-delay={600}
                    data-toggle="modal"
                    data-target="#modal_aside"
                  >
                    Get your Quote
                  </a>
                </div>
              </div>
              <div className="col-lg-6 v-center displayst">
                <div
                  className="img-box1 m-mt60"
                  data-aos="fade-up"
                  data-aos-delay={500}
                >
                  <img
                    src="images/common/agent.png"
                    alt="feature-image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {banner?
          <section>
            <div className="">
              <a href={banner?.link} target="_blank">
                <img
                  style={{ width: "100%" }}
                  src={imgUrl + banner?.image}
                  alt="banner"
                />
              </a>
            </div>
          </section> : ""}


        {/* <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h3 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                    <em className="text-danger">Our Mission: </em>
                  </h3>

                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    At Right Agent Group, our mission is to deliver exceptional services that exceed your expectations. We aim to create a seamless and stress-free experience for our clients by providing them with expert guidance, unparalleled market knowledge, and innovative solutions.                   </p>

                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h3 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                    <em >Services Provided by Right Agent Group </em>
                  </h3>

                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    In addition to our real estate services, Right Agent Group also offers an array of other services to cater to all your financial needs
                    These services include</p>

                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <div className="row mb-3">
                    <div className="col-lg-1"><h5 style={{ fontSize: "13px" }} className="text-danger">Real-Estate:</h5> </div>
                    <div className="col-lg-10">
                      <p data-aos="fade-up" data-aos-delay={300}>
                        we specialize in connecting sellers with buyers through our real estate
                        services. We understand that buying or selling a property is a significant financial
                        decision, and we believe in making this process as smooth as possible.</p>
                      <p data-aos="fade-up" data-aos-delay={300}>
                        Our team of experienced and highly skilled real estate agents is dedicated to helping you navigate the complex market with ease. </p>
                      <p data-aos="fade-up" data-aos-delay={300}>
                        With our extensive network, we are able to match sellers and buyers effectively, ensuring a high-quality match that meets both parties' needs. We treat the selling of properties as a matching process between sellers and buyers moderated by agents. </p>
                      <p data-aos="fade-up" data-aos-delay={300}>
                        The quality of the match depends on agents’ multifaceted actions and efforts at various stages. </p>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-1"><h5 style={{ fontSize: "13px" }} className="text-danger">Loans: </h5> </div>
                    <div className="col-lg-10">
                      <p data-aos="fade-up" data-aos-delay={300}>
                        Our team of loan experts can assist you in securing the right loan for your needs, whether it's a mortgage for purchasing a property or refinancing an existing loan. We understand that the loan process can be overwhelming, which is why we guide you through every step and provide personalized solutions tailored to your financial situation. </p>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-1"><h5 style={{ fontSize: "13px" }} className="text-danger">Insurance: </h5> </div>
                    <div className="col-lg-10">
                      <p data-aos="fade-up" data-aos-delay={300}>
                        At Right Agent Group, we recognize the importance of insurance in protecting oneself and one's assets.
                      </p>
                      <p data-aos="fade-up" data-aos-delay={300}>
                        Offering Comprehensive Insurance Options Our insurance services are designed to provide our clients with comprehensive coverage and peace of mind. We offer a range of insurance options, including home, auto, life, and more. Our team of insurance specialists will work with you to assess your specific insurance needs and find the best coverage options for you.                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section> */}

<section className="step-bg pt50 pb80">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 v-center">
                <div className="common-heading m-text-c pr50">
                  <h2 className="mb20" data-aos="fade-up" data-aos-delay={100}>
                  Services Provided by <em>Right Agent Group</em> 
                  {/* <em>personal credit</em> products on{" "}
                    <span className="fw3">Right Agent Group</span> */}
                  </h2>
                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={100}>
                  At Right Agent Group, we understand the importance of finding
                   the right professionals to guide you through your real estate journey, 
                   secure a loan that suits your needs,
                   and protect your investments with comprehensive insurance coverage.

                  </p>
                  <p style={{textAlign:"justify"}}>In addition to our real estate services, Right Agent Group also offers
                    an array of other services to cater to all your financial needs</p>
                </div>
              </div>
              <div className="col-lg-7 v-center m-mt60">
                <div className="row divrightbdr">
                  <div className="col-lg-6">
                    <div
                      className="steps-div  mt30"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img src="images/icons/choice.png" alt="steps" />{" "}
                      </div>
                      <h4 className="mb10">Real-Estate</h4>
                      <p>
                      we specialize in connecting sellers with buyers through our real estate services.
                       We understand that buying or selling a property is a significant
                       financial decision, and we believe in making this process as smooth as possible. 
                      </p>
                    </div>
                    <div
                      className="steps-div mt30"
                      data-aos="fade-up"
                      data-aos-delay={300}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img
                          src="images/icons/credit-card.png"
                          alt="steps"
                        />{" "}
                      </div>
                      <h4 className="mb10">Loans</h4>
                      <p>
                      Our team of loan experts can assist you in securing the right loan for your needs, whether it's a mortgage for purchasing a property or refinancing an existing loan. 
                      We understand that the loan process can be overwhelming, which is why we guide
                       you through every step and provide personalized solutions tailored to your financial situation. 
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mt60 m-m0">
                    <div
                      className="steps-div mt30"
                      data-aos="fade-up"
                      data-aos-delay={200}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img src="images/icons/security.png" alt="steps" />{" "}
                      </div>
                      <h4 className="mb10">Insurance</h4>
                      <p>
                      Offering Comprehensive Insurance Options Our insurance services are designed 
                      to provide our clients with comprehensive coverage and peace of mind. We offer a 
                      range of insurance options, including home, auto, life, and more. 
                      {/* Our team of insurance specialists will work
                       with you to assess your specific insurance needs and find the best coverage options for you. */}
                      </p>
                    </div>
                    <div
                      className="steps-div mt30"
                      data-aos="fade-up"
                      data-aos-delay={500}
                    >
                      <div className="steps-icons-1">
                        {" "}
                        <img
                          src="images/icons/customers.png"
                          alt="steps"
                        />{" "}
                      </div>
                      <h4 className="mb10">Our Mission</h4>
                      <p>
                      At Right Agent Group, our mission is to deliver exceptional services that exceed your expectations. We aim to create a seamless and stress-free experience for our clients by providing 
                      them with expert guidance, unparalleled market knowledge, and innovative solutions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                    Exploring Real Estate Services
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>

                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    In our real estate services, we cover a wide range of activities related to investment,
                    development, and management.</p>
                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    We understand that buying or selling a property can be a complex and overwhelming process.
                    Our experienced real estate agents work diligently to make the process as smooth and stress-
                    free as possible for our clients. We assist sellers in effectively marketing their properties and
                    connecting with potential buyers. For buyers, we conduct thorough market research to
                    identify suitable properties that meet their criteria. We then guide them through the
                    negotiation and transactional processes, ensuring that their interests are protected at all times.</p>

                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                   Understanding Loan Services
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>
                  <li style={{textAlign:"justify"}} className="mb-2">In our loan services, we aim to simplify the financing process for our clients</li>
                  <li style={{textAlign:"justify"}} className="mb-2">Our team of loan experts will assess your financial situation and guide you through
                    the various loan options available to you.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">We understand that securing financing for a real estate purchase or refinancing an
                    existing loan can be overwhelming.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">That&#39;s why we provide personalized guidance and support throughout the entire loan
                    process. We will help you gather the necessary documentation, navigate the
                    application process, and negotiate favourable terms and rates.</li>


                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                    Why Choose <em >Right Agent Group?</em>
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>

                  <li style={{textAlign:"justify"}} className="mb-2">In today&#39;s busy and complex world, finding the right agent to assist you with your real
                    estate, insurance, and loan needs can be a daunting task. That&#39;s where Right Agent
                    Group comes in.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">We are a service-oriented company that specializes in providing top-notch real estate,
                    insurance, and loan services. With a team of highly experienced and knowledgeable
                    professionals, we are dedicated to helping our clients navigate the intricate world of
                    buying or selling properties, obtaining insurance coverage, and securing loans.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">When it comes to real estate services, we understand that buying or selling a property
                    is not just a transaction, but a life-changing decision. Our real estate agents are skilled
                    at matching sellers with the right buyers, ensuring a successful outcome for both
                    parties.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">We believe that the quality of this match depends on our agents&#39; multifaceted actions
                    and efforts at various stages. When it comes to insurance services, we offer a wide
                    range of options to meet the unique needs of our clients.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">Our experts have a deep understanding of the insurance industry and can help you
                    find the right coverage for your home, car, business, or any other personal assets.
                    When it comes to loan services, we understand that securing financing can be
                    overwhelming. That&#39;s why our loan specialists are here to guide you through the
                    process and help you find the best loan options that fit your individual needs.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">At Right Agent Group, we believe in providing exceptional service to our clients. We
                    understand that when choosing a service provider, consumers are looking to
                    accomplish their goals with the help of knowledgeable and skilled professionals.</li>



                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                   The Right Agent Advantage
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>
                  <li style={{textAlign:"justify"}} className="mb-2">When you choose Right Agent Group for your real estate needs, you gain access to
                    the Right Agent Advantage. The Right Agent Advantage is our unique approach to
                    providing exceptional service and unmatched expertise in the real estate industry.
                    Through our Right Agent Advantage, we offer the following services:</li>
                  <li style={{textAlign:"justify"}} className="mb-2">Setting the Right List Price: Our real estate agents have a deep understanding of the
                    market and utilize their expertise to determine the most accurate and competitive list
                    price for your property.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">Strategic Marketing: We implement a comprehensive marketing plan to ensure
                    maximum exposure for your property. Our team utilizes various channels, including
                    online platforms, social media, and traditional marketing methods, to reach potential
                    buyers and generate interest in your property.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">Expert Negotiations: Our skilled negotiators will represent your best interests during
                    the negotiation process..</li>


                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                    Testimonials from Satisfied Clients
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>

                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    &quot;The real estate agent from Right Agent Group was incredibly knowledgeable and helped us
                    sell our house quickly and at a great price. We were impressed with their attention to detail
                    and ability to negotiate on our behalf. They made the entire process smooth and stress-free&quot;.</p>
                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    &quot;The insurance specialists at Right Agent Group were able to find us the perfect coverage for
                    our home and vehicles. They took the time to understand our needs and provided us with
                    multiple options to choose from. We felt confident in our choices and are now protected with
                    the right policies’’.</p>
                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    &quot;We were initially overwhelmed by the loan process, but the loan specialists at Right Agent
                    Group made it easy to understand and guided us through every step. They helped us find the
                    best loan options that fit our individual needs and explained all the details to ensure we made
                    informed decisions. We are grateful for their expertise and support”.</p>

                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                    Our Commitment to Service Excellence
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>
                  <li style={{textAlign:"justify"}} className="mb-2">At Right Agent Group, we are committed to providing service excellence in all
                    aspects of our business.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">Our team of professionals understand the importance of achieving your goals and are
                    dedicated to helping you succeed.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">We understand that making important decisions in real estate, insurance, and loans
                    can be complex and overwhelming.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">That&#39;s why we strive to make the process as smooth and stress-free as possible for our
                    clients.</li>
                  <li style={{textAlign:"justify"}} className="mb-2">With our extensive knowledge and expertise in the real estate market, we can assist
                    you in finding the perfect property that meets your needs and preferences. In addition,
                    our insurance specialists will work closely with you to analyse your coverage needs
                    and recommend the right policies that provide optimal protection for your assets.</li>


                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                   Contact Information
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>

                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    If you are ready to experience the benefits of working with Right Agent Group, we encourage
                    you to contact us today. You can reach us at +91 8333997227 or Support@rightagentgroup.com</p>


                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="about-bg-2 pad-tb" id="about">
          <div className="container">
            <div className="row m-text-c">
              <div className="col-lg-12 v-center">
                <div className="about-company">
                  <h4 className="mb-3" data-aos="fade-up" data-aos-delay={100}>
                    The Benefits of Choosing <em >Right Agent Group</em>
                    {/* <span className="fw3">
                      Insurance Plans offered by insurers
                    </span> */}
                  </h4>

                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    Choosing Right Agent Group for your real estate, insurance, and loan needs comes with a
                    multitude of benefits. First and foremost, we are highly skilled and experienced in our
                    respective fields.</p>
                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    Our team of real estate agents, insurance specialists, and loan experts have a deep
                    understanding of the industries they work in. This expertise allows us to provide you with
                    accurate information, personalized advice, and tailored solutions that meet your specific
                    needs. Secondly, we prioritize our clients&#39; satisfaction and success Secondly, at Right Agent
                    Group, we prioritize your satisfaction and strive to exceed your expectations.</p>
                  <p style={{textAlign:"justify"}} data-aos="fade-up" data-aos-delay={300}>
                    We are committed to providing exceptional customer service and ensuring that your
                    experience with us is positive and seamless. Additionally, by choosing Right Agent Group,
                    you gain access to a vast network of resources and connections.</p>


                </div>
              </div>

            </div>
          </div>
        </section>

        {banner2 ?
          <section>
            <div className="">
              <a href={banner2?.link} target="_blank">
                <img
                  style={{ width: "100%" }}
                  src={imgUrl + banner2?.image}
                  alt="banner"
                />
              </a>
            </div>
          </section> : ""}
        <Toaster />
        <Contents />
        <Footer />
      </body>

      <div
        id="modal_aside"
        className="modal fixed-center fade"
        tabIndex={-1}
      // role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Express Your Interest</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div >
                <form
                  // role="form"
                  // id="contactForm"
                  // data-toggle="validator"
                  // className="shake"
                  onSubmit={(e) => {
                    getyourquote(e);
                  }}
                >
                  <div className="row">
                    <div className="form-group col-sm-12 mb-3 mt-3 ">
                      <label htmlFor> Enter name <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        pattern="^[a-zA-Z ]*$"
                        id="name"
                        name="name"
                        value={forms.name}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter name"
                        required
                        data-error="Please fill Out"
                      />


                    </div>
                    <div className="form-group col-sm-12 mb-3 ">
                      <label htmlFor> Enter Email <span className="text-danger">*</span></label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={forms.email}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        placeholder="Enter email"
                        required
                      />


                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 mb-3">
                      <label htmlFor>Enter mobile <span className="text-danger">*</span></label>
                      <input
                        type="text" pattern="[0-9]{10}"
                        className="form-control"
                        maxLength={10}
                        id="mobile"
                        name="mobile"
                        value={forms.mobile}
                        onChange={(e) => {
                          handlechangephone(e);
                        }}
                        placeholder="Enter mobile"
                        required
                        data-error="Please fill Out"
                      />

                      {/* <div className="help-block with-errors" /> */}
                    </div>
                    <div className="form-group col-sm-12   mb-3">
                      <label htmlFor>Enter OTP <span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        id="mobile"
                        name="otp"
                        value={forms.otp}
                        onChange={(e) => {
                          handlechangeotp(e);
                        }}
                        placeholder="Enter OTP"
                        required
                        data-error="Please fill Out"
                      />

                      {/* <div className="help-block with-errors" /> */}
                      <div className="row">
                        <div className="col-6">
                          {timeshow == true ? (
                            <span>{time} seconds</span>
                          ) : ""}
                        </div>
                        <div className="col-6">
                          {resends == true ? (
                            <div type="button" onClick={() => { resendotps() }} style={{ float: "right" }} className="mb-2">Resend Otp</div>
                          ) : ""}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-12  select-data mb-3">
                      <label htmlFor>Services <span className="text-danger">*</span></label>
                      <select
                        id="Dtype"
                        name="service"
                        value={forms.service}
                        onChange={(e) => {
                          handlechange(e);
                        }}
                        className="form-control"
                        required
                      >
                        <option value="">Select Services</option>
                        <option value="Loan">Loan</option>
                        <option value="Insurances">Insurances</option>
                        <option value="Realestate">
                          Realestate & Constraction
                        </option>
                      </select>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-sm-6">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="type"
                          id="inlineRadio127"
                          defaultValue="Business"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio127"
                        >
                          Business
                        </label>
                      </div>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                    <div className=" col-sm-6">
                      <div className="form-check form-check-inline ">
                        <input
                          className="form-check-input "
                          type="radio"
                          name="type"
                          id="inlineRadio1111"
                          defaultValue="Employee"
                          onChange={(e) => {
                            handlechange(e);
                          }}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1111"
                        >
                          Employee
                        </label>
                      </div>
                      {/* <div className="help-block with-errors" /> */}
                    </div>
                  </div>

                  <div>
                    {forms.type == "Business" ? (
                      <div className="row mt-3">
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="3YearsITR"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio12sk"
                              style={{ width: "20px" }}
                              defaultValue="option2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12sk"
                            >
                              3 Years ITR
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>

                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="BusinessProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio12ksdds"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12ksdds"
                            >
                              Business Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="AddressProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio12q223"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio12q223"
                            >
                              Address Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    {forms.type == "Employee" ? (
                      <div className="row mt-3">
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="6MonthsPayslips"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio123"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio123"
                            >
                              6 Months Payslips
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>

                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="IdentityProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio120"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio120"
                            >
                              Identity Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                        <div className=" col-sm-12">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input mt-2"
                              type="checkbox"
                              name="values"
                              value="AddressProof"
                              onChange={(e) => {
                                handlechange123(e);
                              }}
                              id="inlineRadio121"
                              defaultValue="option2"
                              style={{ width: "20px" }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio121"
                            >
                              Address Proof
                            </label>
                          </div>
                          {/* <div className="help-block with-errors" /> */}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group mt-1 mb-3">
                    <label htmlFor>Enter your message <span className="text-danger">*</span></label>
                    <textarea
                      id="message"
                      className="form-control"
                      name="message"
                      value={forms.message}
                      rows={3}
                      onChange={(e) => {
                        handlechange(e);
                      }}
                      placeholder="Enter your message"

                      defaultValue={""}
                    />
                    <div className="help-block with-errors" />
                  </div>

                  <button
                    type="submit"
                    id="form-submit"
                    className="btn-rd w-100"
                  >
                    Submit
                  </button>
                  <p className="trm">
                    <i className="fa fa-lock" />
                    We hate spam, and we respect your privacy.
                  </p>
                  <div id="msgSubmit" className="h3 text-center hidden" />
                  <div className="clearfix" />
                </form>
                <div className="form-btm-set">
                  {/* <h5>We Deliver</h5> */}
                  <div className="icon-setss mt20">
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/money.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Best Price</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/quality.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Quality Service</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/call-agent.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Good Support</p>
                      </div>
                    </div>
                    <div className="icon-rows">
                      <div className="icon-imgg">
                        <img src="images/icons/satisfaction.svg" alt="#" />
                      </div>
                      <div className="icon-txt">
                        <p>Satisfaction</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="modal-dialog-centered" size="sm" isOpen={open}>
        <ModalBody className="text-center">
          <img src={successimg} style={{ width: "70%" }} />
          <h5>Thank You!</h5>
          <a className="text-secondary">  Our Team Get Back To You</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>
      <Modal className="modal-dialog-centered" size="sm" isOpen={open1}>
        <ModalBody className="text-center">
          <img src={errorimg} style={{ width: "70%" }} />
          <h5 className="text-danger">Someting Went Wrong.</h5>
          <a className="text-secondary"> Please Check & Try Again</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle1}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>


    </div>
    </DocumentMeta>
  )
}

export default About