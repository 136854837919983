import React from 'react'

function Testing() {
  return (
    <div>
       <div className='container'>
       <div className='row mt-5 pt-5'>
            <div className='col-lg-3 text-end'>
               <div style={{float:"right"}}>
               <img style={{width:"50px"}} src="images/banks/sbi.png"/>
               </div>

            </div>
            <div className=' col-lg-9'>
                <h5>Testing pupose img elements must have an alt prop, either with meaningful text,</h5>
            </div>
        </div>
        <div>
            <h5 className='text-center'>What is Future ....... </h5>
            <p className='text-center'>Education refers to the discipline that is concerned with methods of teaching and learning in schools or school-like environments, as opposed to various nonformal and informal means of socialization. Education refers to the discipline that is concerned with methods of teaching and learning in schools or school-like environments, as opposed to various nonformal and informal means of socialization.</p>
        </div>
        <div className='row'>

        </div>
       </div>
    </div>
  )
}

export default Testing