import React, {useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import Contents from './Contents'

function Termsandconditions() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
      useEffect(() => {
        scrollToTop();
      }, []);
    return (
        <div>
             <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="100"
        className="ct1280"
      >
            <Header />
            <div>

                <section
                    style={{ paddingTop: "80px" }}
                    className="hero-section-1"
                    id="home"
                >
                    <div
                        style={{ opacity: 0.8, backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundRepeat: "no-repeat" }}
                        className="text-center"
                    >
                        <div className="headst">
                            <div className="text-white ">
                                <h1>Terms and Conditions </h1>

                            </div>
                        </div>

                    </div>
                </section>
                <div className='container pt-5 pb-5'>
                    <div style={{ fontSize: "18px" }}>
                        <div className='mb-3'> <b>1. General Terms and Conditions:</b>
                            <span> By using the services provided by Right Agent Group, you agree to comply with all applicable laws and regulations.</span> </div>
                        <div className='mb-3'> <b>2. Disclaimer of Liability:</b>
                            <span> Right Agent Group is not responsible for any losses, damages, or liabilities incurred as a result of using our services.</span>
                        </div>
                        <div className='mb-3'> <b>3. Accuracy of Information: </b>
                            <span> While we strive to provide accurate and up-to-date information, Right Agent Group does not guarantee the accuracy or completeness of any information provided.</span>
                        </div>
                        <div className='mb-3'> <b>4. Use of Information: </b>
                            <span> The information provided by Right Agent Group is for general informational purposes only and should not be considered professional advice.</span>
                        </div>
                        <div className='mb-3'> <b>5. Confidentiality: </b>
                            <span>Right Agent Group respects the confidentiality of its clients and will protect their personal and financial information by applicable privacy laws. </span>
                        </div>
                        <div className='mb-3'> <b>6. Third-Party Services:  </b>
                            <span>Right Agent Group may use third-party services or websites to provide certain services, and by using our services, you agree to the terms and conditions set forth by those third parties. </span>
                        </div>
                        <div className='mb-3'> <b>7. Real Estate Services: </b>
                            <span>Right Agent Group provides real estate services, including but not limited to property listings, property market analysis, and assistance with buying and selling properties. These services are aimed at making the real estate market transparent and reducing risks for investors, buyers, and sellers.  </span>
                        </div>
                        <div className='mb-3'> <b>8. Insurance Services: </b>
                            <span>Right Agent Group offers insurance services, which include providing information and assistance with obtaining insurance policies.  </span>
                        </div>
                        <div className='mb-3'> <b>9. Loan Services: </b>
                            <span>Right Agent Group also provides loan services, assisting clients with the process of obtaining loans for various purposes, such as purchasing a property or refinancing existing loans.  </span>
                        </div>
                        <div className='mb-3'> <b>10. Commission and Fees:  </b>
                            <span>Right Agent Group may charge commissions or fees for certain services, such as real estate transactions or loan applications.  </span>
                        </div>
                        <div className='mb-3'> <b>11. User Responsibilities: </b>
                            <span>By using our services, you agree to provide accurate and truthful information, comply with all applicable laws and regulations, and act in good faith in all interactions with Right Agent Group and its representatives.  </span>
                        </div>
                        <div className='mb-3'> <b>12. Intellectual Property: </b>
                            <span>The content and materials provided by Right Agent Group, including but not limited to logos, designs, text, images, and software are the intellectual property of Right Agent Group and are protected by copyright laws.   </span>
                        </div>
                        <div className='mb-3'> <b>13. Limitation of Liability: </b>
                            <span>Right Agent Group shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or relating to the use of our services, including but not limited to any errors or omissions in the information provided, loss of data, or financial loss. Right Agent Group shall not be liable for any direct, indirect, consequential, or incidental damages arising out of or in connection with the use of our services and website.   </span>
                        </div>
                        <div className='mb-3'> <b>14. Disclaimer of Warranties: </b>
                            <span>Right Agent Group makes no warranties or representations, express or implied, regarding the accuracy, reliability, completeness, suitability, or availability of our services.   </span>
                        </div>
                        <div className='mb-3'> <b>15. Third-Party Websites and Services: </b>
                            <span>Right Agent Group may provide links or references to third-party websites or services for the convenience of our users. However, we do not endorse or have control over the content or practices of these third-party sites. </span>
                        </div>
                        <div className='mb-3'> <b>16. Confidentiality and Privacy: </b>
                            <span>Right Agent Group is committed to protecting the confidentiality and privacy of our client's information. We will handle all personal information provided to us by our privacy policy, which can be found on our website.</span>
                        </div>
                        <div className='mb-3'> <b>17. Termination of Services: </b>
                            <span>Right Agent Group reserves the right to terminate or suspend services to any client at our discretion, without prior notice, if the client violates any terms and conditions stated herein or engages in unethical or unlawful behaviour. </span>
                        </div>
                        <div className='mb-3'> <b>18. Governing Law and Dispute Resolution:  </b>
                            <span>These terms and conditions shall be governed by and construed by the laws. of the jurisdiction in which Right Agent Group is based.  </span>
                        </div>
                        <div className='mb-3'> <b>19. Modification of Terms and Conditions:  </b>
                            <span>Right Agent Group reserves the right to modify or update these terms and conditions at any time without prior notice.  </span>
                        </div>
                        <div className='mb-3'> <b>20. Non-Disclosure Agreement: </b>
                            <span>As a service-oriented company, Right Agent Group understands the importance of protecting intellectual property and confidential information. Therefore, we require all clients and users to agree to a non-disclosure agreement that prohibits the unauthorized disclosure or use of any confidential information shared with or obtained from Right Agent Group.  </span>
                        </div>
                        <div className='mb-3'> <b>21. Indemnification: </b>
                            <span>By using our services, clients agree to indemnify and hold Right Agent Group harmless from any claims, losses, damages, liabilities, and expenses (including legal fees) arising out of or related to their use of our services or any breach of these terms and conditions. Therefore, we require all clients and users to agree to a non-disclosure agreement that prohibits the unauthorized disclosure or use of any confidential information shared with or obtained from Right Agent Group.  </span>
                        </div>
                        <div className='mb-3'> <b>22. Confidentiality of Personal Information: </b>
                            <span>Right Agent Group is committed to protecting the privacy of our clients and users. We take all necessary measures to ensure the confidentiality and security of personal information shared with us. Given the nature of our services and the importance of protecting intellectual property and confidential information, Right Agent Group requires all clients and users to agree to a nondisclosure agreement. This agreement ensures that any confidential information shared or obtained from Right Agent Group will be kept strictly confidential and not disclosed or used without authorization. Right Agent Group prioritizes the confidentiality and security of personal information shared with or obtained from our clients and users.  </span>
                        </div>
                        <div className='mb-3'> <b>23. Indemnification: </b>
                            <span>Clients and users agree to indemnify and hold Right Agent Group harmless from any claims, losses, damages, liabilities and expenses, including but not limited to reasonable attorney's fees, arising out of or in connection with their use of our services or any breach of these terms and conditions.   </span>
                        </div>
                        {/* <div className='mb-3'> <b>24. Indemnification: </b>
                            <span>Clients and users agree to indemnify and hold Right Agent Group harmless from any claims, losses, damages, liabilities and expenses, including but not limited to reasonable attorney's fees, arising out of or in connection with their use of our services or any breach of these terms and conditions.  </span>
                        </div> */}
                        <div className='mb-3'> <b>24. Modifications to Terms and Conditions:  </b>
                            <span>Right Agent Group reserves the right to modify or update these terms and conditions at any time, without prior notice. </span>
                        </div>
                        <div className='mb-3'> <b>25. Severability:  </b>
                            <span>If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</span>
                        </div>
                        <div className='mb-3'> <b>26. User Responsibility:  </b>
                            <span>Users of Right Agent Group's services and website are responsible for their actions and compliance with applicable laws and regulations. Additional conditions may apply depending on the specific services provided by Right Agent Group.</span>
                        </div>
                        <div className='mb-3'> <b>27. Termination of Services:  </b>
                            <span>Right Agent Group reserves the right to terminate its services to any client or user at any time and for any reason.</span>
                        </div>
                    </div>
                </div>
            </div>
            </body>
            <Contents/>
            <Footer />
            
        </div>
    )
}

export default Termsandconditions