import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Contents from './Contents'
import { Link } from "react-router-dom";
import { addData, addDataadmin } from "../Servicecalls";
import { imgUrl } from "../Baceurl";
import toast, { Toaster } from "react-hot-toast";
import moment from 'moment'
import img1 from "../assets/images/icons/choice.png";
import img2 from "../assets/images/icons/customer-services.png";
import img3 from "../assets/images/icons/piggy-bank.png";
import img4 from "../assets/images/icons/credit-card.png";
import img5 from "../assets/images/common/loan.jpg";
import backgroundimg from "../assets/images/agents/backgroundimg.jpg";
import addimg from "../assets/images/banners/insuranceimg.png";
import { Button, Modal, ModalBody } from 'reactstrap';
import successimg from '../assets/images/reviews/success.png'
import errorimg from '../assets/images/reviews/error.png'
import crossimg from '../assets/images/reviews/crossimg.jpg'
import DocumentMeta from 'react-document-meta';

function Banks() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => setOpen1(!open1);
  const [open2, setOpen2] = useState(false);
  const toggle2 = () => setOpen2(!open2);



  const [form, setform] = useState({});
  const [form1, setform1] = useState([]);

  console.log(form1)

  const [veiwmore, setveiwmore] = useState(false);

  const [time, setTime] = useState(30);
  useEffect(() => {
    const updateTimer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(updateTimer);
  }, []);

  const getOnedata = async () => {
    const bodydata = {
      id: sessionStorage.getItem("insuranceid"),
    };
    const resonse = await addData("allproduct/subnextbyid", bodydata);
    var _data = resonse;
    setform(_data.data.subProduct);
  };
  const getAllBanks = async () => {
    const bodydata = {
      productId: "64720193d0f054719b9b2888", //Insurance
    };
    const resonse = await addDataadmin(
      "loanpartner/getloanpartnerbyproductid",
      bodydata
    );
    var _data = resonse;
    setform1(_data.data.loanPartner);
  };

  const [banner, setbanner] = useState([]);
  const [banner2, setbanner2] = useState([]);
  const [banner3, setbanner3] = useState([]);
  const [banner4, setbanner4] = useState([]);
  const [states, setstates] = useState([]);

  const getAllbanners = async () => {
    const resonse = await addData("banner/getallinsurance");
    var _data = resonse;
    setbanner(_data.data.horizontal[0]);
    setbanner2(_data.data.horizontal[1]);
    setbanner3(_data.data.vertical[0]);
    // setbanner4(_data.data.vertical[1]);
  };
  const [banner1, setbanner1] = useState([]);
  const getAllbanners1 = async () => {
    const resonse = await addDataadmin("pagebanners/getactive");
    var _data = resonse;
    setbanner1(_data.data.pageBanners[2]);
  };
  const getAllstates = async () => {
    const resonse = await addDataadmin("state/getall");
    var _data = resonse;
    setstates(_data.data.statesResult);
  };

  useEffect(() => {
    getOnedata();
    getAllstates();
    getAllBanks();
    getAllbanners();
    getAllbanners1();
    scrollToTop();
  }, []);

  // Insurance form

  const [timeshow1, settimeshow1] = useState(false);
  const [resends1, setresends1] = useState(false);

  const handlechangephone1 = async (e) => {
    const inputMobile = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputMobile;
    setforms1(myForm);
    const bodydata = {
      mobile: inputMobile,
    };
    if (inputMobile.length === 10) {
      try {
        const response = await addData("expressintrest/sentotp", bodydata);
        const data = response.data;
        toast.success(data.message);
        setresends1(true);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const resendotps1 = async () => {
    const bodydata = {
      mobile: forms1.mobile,
    };

    try {
      const response = await addData("expressintrest/sentotp", bodydata);
      const data = response.data;
      toast.success(data.message);
      settimeshow1(true);
      setTime(30);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Somting Went Wrong Please Try Again");
      }
    }
  };

  const handlechangeotp1 = async (e) => {
    const inputOtp = e.target.value;
    const myForm = { ...forms1 };
    myForm[e.target.name] = inputOtp;
    setforms1(myForm);

    const bodydata = {
      mobile: forms1.mobile,
      otp: inputOtp,
    };

    if (inputOtp.length === 6) {
      try {
        const response = await addData("expressintrest/compareotp", bodydata);
        const data = response.data;
        toast.success(data.message);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Somting Went Wrong Please Try Again");
        }
      }
    }
  };

  const [forms1, setforms1] = useState({
    name: "",
    mobile: "",
    eamil: "",
    otp: "",
    qualification: "",
    birthPlace: "",
    workingCompany: "",
    designation: "",
    budgetRange: "",
    serviceLength: "",
    annualIncome: "",
    communicationAddress: "",
    state: "",
    city: "",
    pinCode: "",
    spouseName: "",
    spouseDoB: "",
    childName: "",
    childDoB: "",
    fatherName: "",
    motherName: "",
    dateOfBirth: "",
    typeOfIncome: "",
    nomineeName: "",
    nomineeRelation: "",


  });


  console.log(forms1)
  // const isNextButtonDisabled = 

  const handleNext = () => {
    if (forms1.name === '' || forms1.eamil === '' || forms1.mobile === '' || forms1.otp === '' || forms1.birthPlace === '' || forms1.qualification === '' || form1.dateOfBirth === ''
      || forms1.workingCompany === '' || forms1.designation === '' || forms1.serviceLength === '' || forms1.annualIncome === '' || forms1.communicationAddress === '' || form1.typeOfIncome === '' ||
      forms1.state === '' || forms1.city === '' || forms1.pinCode === '') {
      toast.error("Please fill in the required fields")
    } else {
      setveiwmore(!veiwmore)
    }
  };


  const handlechange1 = (e) => {
    const myForm = { ...forms1 };
    myForm[e.target.name] = e.target.value;
    setforms1(myForm);
  };

  const [yeardiffer1, setyeardiffer1] = useState('')
  const handlechangedate1 = (e) => {
    const myForm = { ...forms1 };
    myForm[e.target.name] = e.target.value;
    setforms1(myForm);
    const givenDate = new Date(e.target.value);
    const today = new Date();
    const yearsDifference1 = today.getFullYear() - givenDate.getFullYear();
    setyeardiffer1(yearsDifference1)
  };

  const [Files1, setFiles1] = useState({ photo: "" });

  const filechange1 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files);
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  };
  const [Files2, setFiles2] = useState({ pan: "", });
  const filechange2 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles2(e.target.files);
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  };
  const [Files3, setFiles3] = useState({ aadhar: "" });
  const filechange3 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles3(e.target.files);
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  };
  const [Files4, setFiles4] = useState({ cancelledCheque: "" });
  const filechange4 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles4(e.target.files);
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }

  };
  const [Files5, setFiles5] = useState({ payslip: "" });
  const filechange5 = (e) => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png" || type == "pdf") {
      setFiles5(e.target.files);
    } else {
      e.target.value = null
      toast.error("File format is not accepted.Please pick the right one")
    }
  };

  const Insuranceform = async (e) => {
    e.preventDefault();
    const dataArray = new FormData();
    dataArray.append("insuranceName", form.name);
    dataArray.append("name", forms1.name);
    dataArray.append("mobile", forms1.mobile);
    dataArray.append("eamil", forms1.eamil);
    dataArray.append("otp", forms1.otp);
    dataArray.append("qualification", forms1.qualification);
    dataArray.append("birthPlace", forms1.birthPlace);
    dataArray.append("workingCompany", forms1.workingCompany);
    dataArray.append("communicationAddress", forms1.communicationAddress);
    dataArray.append("designation", forms1.designation);
    dataArray.append("budgetRange", forms1.budgetRange);
    dataArray.append("serviceLength", forms1.serviceLength);
    dataArray.append("annualIncome", forms1.annualIncome);
    dataArray.append("spouseName", forms1.spouseName);
    dataArray.append("spouseDoB", forms1.spouseDoB);
    dataArray.append("childName", forms1.childName);
    dataArray.append("childDoB", forms1.childDoB);
    dataArray.append("fatherName", forms1.fatherName);
    dataArray.append("motherName", forms1.motherName);
    dataArray.append("maritalStatus", forms1.maritalStatus);
    dataArray.append("occupation", forms1.occupation);
    dataArray.append("state", forms1.state);
    dataArray.append("city", forms1.city);
    dataArray.append("pinCode", forms1.pinCode);
    dataArray.append("nomineeName", forms1.nomineeName);
    dataArray.append("nomineeRelation", forms1.nomineeRelation);
    dataArray.append("dateOfBirth", forms1.dateOfBirth);
    dataArray.append("typeOfIncome", forms1.typeOfIncome);

    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("photo", Files1[i]);
    }
    for (let i = 0; i < Files2.length; i++) {
      dataArray.append("pan", Files2[i]);
    }
    for (let i = 0; i < Files3.length; i++) {
      dataArray.append("aadhar", Files3[i]);
    }
    for (let i = 0; i < Files4.length; i++) {
      dataArray.append("cancelledCheque", Files4[i]);
    }
    for (let i = 0; i < Files5.length; i++) {
      dataArray.append("payslip", Files5[i]);
    }

    try {
      const resonse = await addDataadmin("insuranceform/add", dataArray);
      var _data = resonse;
      // toast.success(_data.data.message);
      setOpen(true)
      setforms1({
        name: "",
        mobile: "",
        eamil: "",
        otp: "",
        qualification: "",
        birthPlace: "",
        workingCompany: "",
        communicationAddress: "",
        designation: "",
        budgetRange: "",
        serviceLength: "",
        annualIncome: "",
        address: "",
        spouseName: "",
        spouseDoB: "",
        childName: "",
        childDoB: "",
        fatherName: "",
        maritalStatus: "",
        state: "",
        city: "",
        pinCode: "",
        nomineeName: "",
        nomineeRelation: "",
        dateOfBirth: "",
        motherName: '',
        childpolicy: "",
        typeOfIncome: ""
      });
      setFiles1({ photo: "" });
      setFiles2({ pan: "" });
      setFiles3({ aadhar: "" });
      setFiles4({ cancelledCheque: "" });
      setFiles5({ payslip: "" });
      setresends1(false);
      settimeshow1(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // toast.error(error.response.data.message);
        setOpen1(true)
      } else {
        // toast.error("An error occurred. Please try again.")
      }
    }
  };

  // if (form && form.name && form.seoTitle && form.seoKeys) {
  const slug = form?.name?.toLowerCase().replace(/\s+/g, "-");
  const meta = {
    title: form.seoTitle,
    description: form.seoDescription,
    canonical: 'https://rightagentgroup.com/insurance/'+ slug,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: form.seoKeys
      }
    }
  };
// }else{

// }


  return (
    <DocumentMeta {...meta}>
    <div>
      <body
        data-spy="scroll"
        data-target=".navbar"
        data-offset="100"
        className="ct1280"
      >
        <Header />
        <section
          style={{ paddingTop: "80px" }}
          className="hero-section-1"
          id="home"
        >
          <div
            style={{
              background: `url(${imgUrl + banner1.image})`,
              opacity: 0.8,
              backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundRepeat: "no-repeat"
            }}
            className="text-center"
          >
            <div className="headst">
              <div className="text-white ">
                <h1>{form.name}</h1>
                {/* <div className="row">
                  <div className="col-lg-3"></div>
                  <div className="col-lg-6">
                    {" "}
                    <p style={{ fontSize: "20px" }}>{banner1.description}</p>
                  </div>
                  <div className="col-lg-3"></div>
                </div> */}

                {/* <p style={{fontSize:"20px"}}>A Insurances is a debt incurred by an individual or some entity.</p> */}
              </div>
            </div>
            {/* <Link to="/">Home</Link> / <span>Loans</span> */}
          </div>
        </section>

        {/* start banks */}
        <section className="mb-5 pb-5">
          <div className="container">
            {/* Calculater  */}

            <div>
              <div className="row mt-5 mb-4">
                <div className="col-lg-6">
                  <div className="row divrightbdr mt-3">
                    <div className="col-md-12">
                      <div
                        className="steps-div "
                        data-aos="fade-up"
                        data-aos-delay={100}
                      >
                        <div className="steps-icons-1"></div>
                        {/* <h3 className="mb10 text-center mb-4">
                          Personal Details
                        </h3> */}

                        <form
                          onSubmit={(e) => {
                            Insuranceform(e);
                          }}
                        >
                          <div>
                            <div>
                              {veiwmore == false ? (
                                <div>
                                  <div className="">
                                    {/* <form action="#"> */}
                                    <h4 className="text-center mb-5">
                                      {" "}
                                      Personal Details
                                    </h4>
                                    <div className="row ">
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor> Name <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            name="name"
                                            className="form-control"
                                            placeholder="Name"
                                            autocomplete="off"
                                            value={forms1.name}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }}
                                            required
                                          />

                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Email <span className="text-danger">*</span> </label>
                                        <div className="">
                                          <input
                                            name="eamil"
                                            value={forms1.eamil}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }}
                                            type="email"
                                            placeholder="Email"
                                            required
                                            autocomplete="off" className="form-control"
                                          />


                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-6 mb-3">
                                            <label htmlFor>
                                              {" "}
                                              Mobile Number <span className="text-danger">*</span>
                                            </label>
                                            <div className="">
                                              <input
                                                type="text"
                                                pattern="[0-9]{10}"
                                                maxLength={10}
                                                placeholder="Mobile Number "
                                                name="mobile"
                                                value={forms1.mobile}
                                                required
                                                autocomplete="off"
                                                onChange={(e) => {
                                                  handlechangephone1(e);
                                                }} className="form-control"
                                              />


                                            </div>
                                          </div>
                                          <div className="col-md-6 mb-3 ">
                                            <label htmlFor> OTP <span className="text-danger">*</span></label>
                                            <div className="">
                                              <input
                                                name="otp"
                                                value={forms1.otp}
                                                onChange={(e) => {
                                                  handlechangeotp1(e);
                                                }}
                                                type="text"
                                                placeholder="OTP"
                                                autocomplete="off"
                                                required className="form-control"
                                              />


                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mb-2">
                                          <div className="col-6"></div>
                                          <div className="col-6">
                                            <div className="row">
                                              <div className="col-6">
                                                {timeshow1 == true ? (
                                                  <span>{time} seconds</span>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <div className="col-6">
                                                {resends1 == true ? (
                                                  <div
                                                    type="button"
                                                    onClick={() => {
                                                      resendotps1();
                                                    }}
                                                    style={{ float: "right" }}
                                                    className="mb-2"
                                                  >
                                                    Resend Otp
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3">
                                        <label htmlFor> Date Of Birth <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="date"
                                            placeholder="Date Of Birth "
                                            max={new Date(moment().subtract(18, "years")).toISOString().slice(0, -14)}
                                            required
                                            autocomplete="off"
                                            name="dateOfBirth"
                                            value={forms1.dateOfBirth}
                                            onChange={(e) => {
                                              handlechangedate1(e);
                                            }} className="form-control"
                                          />

                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>Birth Place <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            name="birthPlace"
                                            autocomplete="off"
                                            placeholder="Birth Place"
                                            value={forms1.birthPlace}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }}
                                            required className="form-control"
                                          />

                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>Qualification <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="text"
                                            name="qualification"
                                            pattern="^[a-zA-Z ]*$"
                                            autocomplete="off"
                                            placeholder="Qualification"
                                            value={forms1.qualification}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                            required
                                          />

                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>Company Name  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="text"
                                            name="workingCompany"
                                            pattern="^[a-zA-Z ]*$"
                                            autocomplete="off"
                                            placeholder="Company Name"
                                            value={forms1.workingCompany}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                            required
                                          />

                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>Designation  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            name="designation"
                                            autocomplete="off"
                                            placeholder="Designation"
                                            value={forms1.designation}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                            required
                                          />

                                        </div>
                                      </div>
                                      {form.subProductId === "6475cc987f002a54039b7c4f" ? (
                                        <div className="col-md-6 mb-3">
                                          <label htmlFor>Budget Range  <span className="text-danger">*</span></label>
                                          <div className="">
                                            <input
                                              type="number"
                                              name="budgetRange"
                                              autocomplete="off"
                                              placeholder="Budget Range"
                                              value={forms1.budgetRange}
                                              onChange={(e) => {
                                                handlechange1(e);
                                              }} className="form-control"
                                              required
                                            />

                                          </div>
                                        </div>
                                      ) : ""}


                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>

                                          Length of Service <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                          <input
                                            type="text"
                                            name="serviceLength"
                                            autocomplete="off"
                                            placeholder="Length of Service"
                                            value={forms1.serviceLength}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                            required
                                          />


                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor> Annual Income  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="number"
                                            placeholder="Annual Income"
                                            required
                                            name="annualIncome"

                                            autocomplete="off"
                                            value={forms1.annualIncome}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />

                                        </div>
                                      </div>
                                      {/* <div className="col-md-6  ">
                                        <div className="row">
                                          <div className="col-6">
                                            {" "}
                                            <label htmlFor> Budget</label>{" "}
                                          </div>
                                          <div className="col-6">
                                            <span className="text-danger">
                                              {" "}
                                              ₹ {form12.ranges}
                                            </span>
                                          </div>
                                        </div>

                                        <div className="">
                                          <input
                                            type="range"
                                            name="ranges"
                                            min="0"
                                            onChange={(e) => {
                                              handleform(e);
                                            }}
                                            max="10000000"
                                            class="form-control-range"
                                            id="formControlRange"
                                          />
                                       
                                        </div>
                                      </div> */}

                                      {/* <div className="col-md-6 mb-5">
                                        <div className="">
                                          <select
                                            name="salarytype"
                                            onChange={(e) => {
                                              handlechange(e);
                                            }}
                                            required
                                          >
                                            <option value="">
                                              Employement
                                            </option>
                                            <option value="Self"> Self </option>
                                            <option value="Business">
                                              {" "}
                                              Business{" "}
                                            </option>
                                            <option value="Salaried">
                                              {" "}
                                              Salaried{" "}
                                            </option>
                                          </select>
                                          
                                        </div>
                                      </div> */}

                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>
                                          Communication Address  <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                          <input
                                            type="text"
                                            placeholder="Communication Address"
                                            required
                                            name="communicationAddress"
                                            value={forms1.communicationAddress}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />

                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>Type of Income <span className="text-danger">*</span></label>
                                        <div className="">
                                          <select
                                            name="typeOfIncome"
                                            value={forms1.typeOfIncome}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }}
                                            required className="form-control"
                                          >
                                            <option value="">
                                              Select
                                            </option>
                                            <option value="Salaried">Salaried</option>
                                            <option value="Self">Self Employee</option>
                                          </select>

                                        </div>
                                      </div>
                                      {/* <div className="col-md-6 mb-3">
                                        <label htmlFor>
                                          Occupation <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                          <input
                                            type="text"
                                            placeholder=""
                                            required
                                            autocomplete="off"
                                            name="occupation"
                                            value={forms1.occupation}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }}
                                          />
                                          

                                        </div>
                                      </div> */}


                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>
                                          State <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                          <input
                                            type="text"
                                            placeholder="State"
                                            required
                                            name="state"
                                            autocomplete="off"
                                            value={forms1.state}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />


                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>
                                          City <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                          <input
                                            type="text"
                                            placeholder="City"
                                            required
                                            name="city"
                                            value={forms1.city}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />


                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor>
                                          Pin Code  <span className="text-danger">*</span>
                                        </label>
                                        <div className="">
                                          <input
                                            type="text"
                                            pattern="^\d{6}$"
                                            maxLength={6}
                                            placeholder="Pin Code"
                                            required
                                            autocomplete="off"
                                            name="pinCode"
                                            value={forms1.pinCode}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />


                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="text-end">
                                          <button
                                            style={{ float: "right" }}
                                            // id="pills-profile-tab"
                                            // data-toggle="pill"
                                            // href="#pills-profile"
                                            // onClick={() => {
                                            //   setveiwmore(!veiwmore);
                                            // }}
                                            onClick={handleNext}
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                            className="btn btn-outline-info"
                                          >
                                            Next{" "}
                                            <i
                                              class="fa fa-long-arrow-right"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>



                                    </div>

                                    {/* </form> */}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div>
                                    <h4 className="text-center mb-5">
                                      {" "}
                                      Documents{" "}
                                    </h4>

                                    <div className="row">
                                      <div className="col-md-6 mb-3">
                                        <label htmlFor> Marital Status  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <select
                                            name="maritalStatus"
                                            value={forms1.maritalStatus}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }}
                                            required className="form-control"
                                          >
                                            <option value="">
                                              Select
                                            </option>
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                            <option value="Divorced">Divorced</option>
                                            <option value="Widowed">Widowed</option>
                                          </select>
                                        </div>
                                      </div>
                                      {forms1.maritalStatus == "Married" || forms1.maritalStatus == "Widowed" ? <>
                                        <div className="col-md-6 mb-3 ">
                                          <label htmlFor> Spouse Name  <span className="text-danger">*</span></label>
                                          <div className="">
                                            <input
                                              style={{ marginTop: "5px" }}
                                              type="text"
                                              pattern="^[a-zA-Z ]*$"
                                              placeholder="Spouse Name"
                                              required
                                              autocomplete="off"
                                              name="spouseName"
                                              value={forms1.spouseName}
                                              onChange={(e) => {
                                                handlechange1(e);
                                              }} className="form-control"
                                            />

                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                          <label htmlFor>Spouse  DOB <span className="text-danger">*</span></label>
                                          <div className="">
                                            <input
                                              type="date"
                                              placeholder="Spouse  DOB "
                                              max={new Date(moment().subtract(18, "years")).toISOString().slice(0, -14)}
                                              required
                                              autocomplete="off"
                                              name="spouseDoB"
                                              value={forms1.spouseDoB}
                                              onChange={(e) => {
                                                handlechangedate1(e);
                                              }} className="form-control"
                                            />

                                          </div>
                                        </div>

                                      </> : ""}

                                      <div className="col-md-6 mb-3">
                                        <label htmlFor> Child Policy <span className="text-danger">*</span></label>
                                        <div className="">
                                          <select
                                            name="childpolicy"
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                            required
                                            value={forms1.childpolicy}
                                          >
                                            <option value="">
                                              Select
                                            </option>
                                            <option value="Yes"> Yes </option>
                                            <option value="No">No</option>
                                          </select>

                                        </div>
                                      </div>

                                      {forms1.childpolicy == "Yes" ? (
                                        <>
                                          <div className="col-md-6 mb-3 ">
                                            <label htmlFor> Child Name <span className="text-danger">*</span></label>
                                            <div className="">
                                              <input
                                                type="text"
                                                pattern="^[a-zA-Z ]*$"
                                                placeholder="Child Name"
                                                required
                                                name="childName"
                                                autocomplete="off"
                                                value={forms1.childName}
                                                onChange={(e) => {
                                                  handlechange1(e);
                                                }} className="form-control"
                                              />


                                            </div>
                                          </div>

                                          <div className="col-md-6 mb-3 ">
                                            <label htmlFor> Child DOB <span className="text-danger">*</span></label>
                                            <div className="">
                                              <input
                                                type="date"
                                                max={new Date().toISOString().slice(0, -14)}
                                                placeholder="Child DOB "
                                                required
                                                name="childDoB"
                                                autocomplete="off"
                                                value={forms1.childDoB}
                                                onChange={(e) => {
                                                  handlechange1(e);
                                                }} className="form-control"
                                              />

                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Father Name  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            placeholder="Father Name"
                                            required
                                            name="fatherName"
                                            autocomplete="off"
                                            value={forms1.fatherName}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />


                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Mother Name  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input style={{ marginTop: "8px" }}
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            placeholder="Mother Name"
                                            required
                                            name="motherName"
                                            autocomplete="off"
                                            value={forms1.motherName}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />


                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor>Nominee Name  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input style={{ marginTop: "8px" }}
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            placeholder="Nominee Name "
                                            required
                                            name="nomineeName"
                                            autocomplete="off"
                                            value={forms1.nomineeName}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />


                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor>Nominee Relation  <span className="text-danger">*</span></label>
                                        <div className="">
                                          <input style={{ marginTop: "8px" }}
                                            type="text"
                                            pattern="^[a-zA-Z ]*$"
                                            placeholder="Nominee Relation "
                                            required
                                            autocomplete="off"
                                            name="nomineeRelation"
                                            value={forms1.nomineeRelation}
                                            onChange={(e) => {
                                              handlechange1(e);
                                            }} className="form-control"
                                          />


                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Photo <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg)</span>
                                        <div className="">
                                          <input
                                            type="file"
                                            placeholder="Photo"
                                            required
                                            name="photo"
                                            onChange={(e) => {
                                              filechange1(e);
                                            }} className="form-control"
                                            value={Files1.photo}
                                          />

                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Pan Card <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg)</span>
                                        <div className="">
                                          <input
                                            type="file"
                                            placeholder=""
                                            required
                                            name="pan"

                                            onChange={(e) => {
                                              filechange2(e);
                                            }} className="form-control"
                                            value={Files2.pan}
                                          />

                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Aadhar Card <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg)</span>
                                        <div className="">
                                          <input
                                            type="file"
                                            placeholder=""
                                            required
                                            name="aadhar"

                                            onChange={(e) => {
                                              filechange3(e);
                                            }} className="form-control"
                                            value={Files3.aadhar}
                                          />

                                        </div>
                                      </div>

                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Cancelled Cheque <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                        <div className="">
                                          <input
                                            type="file"
                                            placeholder=""
                                            required

                                            name="cancelledCheque"
                                            onChange={(e) => {
                                              filechange4(e);
                                            }} className="form-control"
                                            value={Files4.cancelledCheque}
                                          />

                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3 ">
                                        <label htmlFor> Payslips <span className="text-danger">*</span></label> <span className="text-danger">(jpg, png, jpeg, pdf)</span>
                                        <div className="">
                                          <input
                                            type="file"
                                            placeholder=""
                                            required

                                            name="payslip"
                                            onChange={(e) => {
                                              filechange5(e);
                                            }} className="form-control"
                                            value={Files5.payslip}
                                          />

                                        </div>
                                      </div><div className="col-md-6 mb-3 "></div>

                                      <div className="mb-3 text-primary">
                                        <a
                                          type="button"
                                          onClick={() => {
                                            setveiwmore(!veiwmore);
                                          }}
                                        >
                                          <i
                                            class="fa fa-long-arrow-left"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Previous
                                        </a>
                                      </div>


                                      {/* {yeardiffer1 <= 18 ?(
                                <>
                               <button
                                        className="btn-rd123"
                                        style={{ width: "100%" }}
                                        type="button"
                                        onClick={toggle2}
                                      >
                                        Submit
                                      </button>
                           
                              </>
                              ) : ( */}
                                      <button
                                        className="btn-rd123"
                                        style={{ width: "100%" }}
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                      {/* )} */}


                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  {/* <h3 className="pt-5 mb-5 text-center">
              Top Loan Bank Partners
            </h3> */}

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mt-4">
                        <h4 className="text-center mb-4">
                          {" "}
                          Insurance Partners
                        </h4>


                        <div className="row divrightbdr mt-3">
                          {form1.map((data) => (
                            <div className="col-md-6 mb-3">
                              <div
                                className="steps-div p-3"
                                data-aos="fade-up"
                                data-aos-delay={100}
                              >
                                <div className="row">
                                  <div className="col-4 ">
                                    <img
                                      style={{ width: "65px", height: "90%" }}
                                      src={imgUrl + data.image}
                                      alt="steps"
                                    />{" "}
                                  </div>
                                  <div className="col-8 mt-2">
                                    <h6 className="">{data.name}</h6>
                                    <span>{data.percentage}</span>
                                    <br />
                                    <span> {data.year}</span>
                                  </div>
                                  {/* <div className="col-lg-2 col-4 mt-2 ">
                              <span>{data.percentage}</span>
                            </div>
                            <div className="col-4 mt-2 text-center ">
                              <span> {data.year}</span>
                            </div> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {banner3 ?
                          <section className="displayst">
                            <div className="">
                              <a href={banner3?.link} target="_blank">
                                <img
                                  style={{ width: "100%", height: "350px" }}
                                  src={addimg}
                                  alt="banner"
                                />
                              </a>
                            </div>
                          </section> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {banner ?
            <section>
              <div className="">
                <a href={banner?.link} target="_blank">
                  <img
                    style={{ width: "100%" }}
                    src={imgUrl + banner?.image}
                    alt="banner"
                  />
                </a>
              </div>
            </section> : ""}

          <div className="container">
            <h3 className="mt-5 pt-2 text-center">How it works ?</h3>
            <p className="text-center mt-3 mb-5">
              Low-interest rates, No Income Proof, etc. Ensuring a Tension
              Free Loan Experience
              <br />
              With Minimal Documentation & Zero Paper Work. Flexible
              Repayment.
            </p>

            <div className="row divrightbdr mt-3">
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img1} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Online Form </h4>
                  <p>
                    Fill an online form to view the best offers from our
                    partner banks..
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img2} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Our Executive</h4>
                  <p>
                    Our executive helps you choose the best offer for your
                    requirement.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img3} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Bank </h4>
                  <p>
                    We pick up documents at your doorstep and submit to the
                    bank**.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div
                  className="steps-div  mt30"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="steps-icons-1">
                    {" "}
                    <img src={img4} alt="steps" />{" "}
                  </div>
                  <h4 className="mb10">Bank Reviews</h4>
                  <p>Bank reviews your application and confirms approval.</p>
                </div>
              </div>
            </div>
          </div>


        </section>
        {/* end banks */}
        {banner2 ?
          <section>
            <div className="">
              <a href={banner2?.link} target="_blank">
                <img
                  style={{ width: "100%" }}
                  src={imgUrl + banner2?.image}
                  alt="banner"
                />
              </a>
            </div>
          </section> : ""}
      </body>
      <Toaster />
      <Contents />

      <Footer />


      <Modal className="modal-dialog-centered" size="sm" isOpen={open}>
        <ModalBody className="text-center">
          <img src={successimg} style={{ width: "70%" }} />
          <h5>Thank You!</h5>
          <a className="text-secondary">  Our Team Get Back To You</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="success" outline onClick={toggle}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>
      <Modal className="modal-dialog-centered" size="sm" isOpen={open1}>
        <ModalBody className="text-center">
          <img src={errorimg} style={{ width: "70%" }} />
          <h5 className="text-danger">Someting Went Wrong.</h5>
          <a className="text-secondary"> Please Check & Try Again</a>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle1}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>

      <Modal className="modal-dialog-centered" size="sm" isOpen={open2}>
        <ModalBody className="text-center">
          <img src={crossimg} style={{ width: "70%" }} />
          <h5 className="text-danger">Access Is 18+ Only</h5>
        </ModalBody>
        <div className="text-center">
          <Button style={{ width: "100px", }} className="mt-1 mb-4 rounded-pill" color="danger" outline onClick={toggle2}>
            Ok <i class="fa fa-check-square-o" aria-hidden="true"></i>
          </Button>
        </div>
      </Modal>

    </div>
    </DocumentMeta>
  );
}

export default Banks;
